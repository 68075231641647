import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/placement/placement-banner2.png"
import Icon1Image from "../../assets/images/placement/icon21.png"
import Icon2Image from "../../assets/images/placement/icon22.png"
import Icon3Image from "../../assets/images/placement/icon23.png"
import MaleImage from "../../assets/images/placement/male.png"
import FemaleImage from "../../assets/images/placement/female.png"
import Graph1Image from "../../assets/images/placement/graph7.png"
import Graph2Image from "../../assets/images/placement/graph2.png"
import Graph3Image from "../../assets/images/placement/graph6.png"
import Graph3MImage from "../../assets/images/placement/listm.png"
import Graph4Image from "../../assets/images/placement/graph3.png"
import Graph5Image from "../../assets/images/placement/graph4.png"
import Graph6Image from "../../assets/images/placement/graph5.png"
import Graph7Image from "../../assets/images/placement/graph1.png"
import Graph71mImage from "../../assets/images/placement/1m.png"
import Graph72mImage from "../../assets/images/placement/2m.png"
import Graph73mImage from "../../assets/images/placement/3m.png"
import Graph74mImage from "../../assets/images/placement/4m.png"

import Graph2023Package from "../../assets/images/placement/package2023.png"
import Graph2023Employment from "../../assets/images/placement/employment2023.png"
import Graph2024Package from "../../assets/images/placement/package2024.png"
import GraphPackage from "../../assets/images/CTC_Package.png"

import Graph2024Profile from "../../assets/images/placement/Profile-2024.png"
import Graph2024 from "../../assets/images/Graph2024.png"

import Graph2024Employment from "../../assets/images/placement/employment2024.png"
import GraphEmployment from "../../assets/images/Placement_graph.png"


import Graph2021Package from "../../assets/images/placement/package2021.png"
import Graph2021Employment from "../../assets/images/placement/employment2021.png"

import Graph2020Package from "../../assets/images/placement/Graph2020Package.png"
import Graph2020Employment from "../../assets/images/placement/Graph2020Employment.png"
import Graph2020Profile from "../../assets/images/placement/Profile-2020.png"

import Graph2017Image from "../../assets/images/placement/academicbackground-17-19 new.png"
import Graph2017Employment from "../../assets/images/placement/employment 17-19.png"
import Graph2017Package from "../../assets/images/placement/package-17-19.png"
import Graph2017Stats from "../../assets/images/placement/stats-17-19.png"
import Graph2018Image from "../../assets/images/placement/academicbackground-18-19 new.png"
import Graph2018Employment from "../../assets/images/placement/employment-2018.png"
import Graph2018Package from "../../assets/images/placement/package2018.png"
import Graph2019Stats from "../../assets/images/placement/stats-18.png"
import Graph18m1Image from "../../assets/images/placement/2018-1m.png"
import Graph18m2Image from "../../assets/images/placement/2018-2m.png"
import Graph18m3Image from "../../assets/images/placement/2018-3m.png"
import Graph18m4Image from "../../assets/images/placement/2018-4m.png"
import Graph17m1Image from "../../assets/images/placement/2017-1m.png"
import Graph17m2Image from "../../assets/images/placement/2017-2m.png"
import Graph17m3Image from "../../assets/images/placement/2017-3m.png"
import Graph17m4Image from "../../assets/images/placement/2017-4m.png"
import student22_24_Img1 from "../../assets/images/placed-student/2022-24/1.jpg"
import student22_24_Img2 from "../../assets/images/placed-student/2022-24/2.jpg"
import student22_24_Img3 from "../../assets/images/placed-student/2022-24/3.jpg"
import student22_24_Img4 from "../../assets/images/placed-student/2022-24/4.jpg"
import student22_24_Img5 from "../../assets/images/placed-student/2022-24/5.jpg"
import student22_24_Img6 from "../../assets/images/placed-student/2022-24/6.png"
import student22_24_Img7 from "../../assets/images/placed-student/2022-24/7.jpg"
import student22_24_Img8 from "../../assets/images/placed-student/2022-24/8.jpg"
import student22_24_Img9 from "../../assets/images/placed-student/2022-24/9.jpg"
import student22_24_Img10 from "../../assets/images/placed-student/2022-24/10.jpg"
import student22_24_Img11 from "../../assets/images/placed-student/2022-24/11.png"
import student22_24_Img12 from "../../assets/images/placed-student/2022-24/12.png"
import student22_24_Img13 from "../../assets/images/placed-student/2022-24/13.jpg"
import student22_24_Img14 from "../../assets/images/placed-student/2022-24/14.png"
import student22_24_Img15 from "../../assets/images/placed-student/2022-24/15.png"



import student_1 from "../../assets/images/placed_students/Ashish Popat Mote.png"
import student_2 from "../../assets/images/placed_students/Bhakti Ganesh Dahale.jpg"
import student_3 from "../../assets/images/placed_students/Aditya Kumar Mishra.png"
import student_4 from "../../assets/images/placed_students/Anjali Sanjay Borse.jpg"
import student_5 from "../../assets/images/placed_students/Bhargavi Dilip Wadighare.png"
import student_6 from "../../assets/images/placed_students/Abhishek Jamuwant Kurmelwar.jpg"
import student_7 from "../../assets/images/placed_students/Bhavesh Sunil Khadka.jpg"
import student_8 from "../../assets/images/placed_students/Poorvi Sanjay Sahu.jpg"
import student_9 from "../../assets/images/placed_students/Rushikesh Ulhas Medhshikhar.jpg"
import student_10 from "../../assets/images/placed_students/Yuganshu Dilip Khatke.jpg"
import student_11 from "../../assets/images/placed_students/Ajinkya Anantrao Sable.jpg"
import student_12 from "../../assets/images/placed_students/Bhavesh Ajit Pande.jpg"
import student_13 from "../../assets/images/placed_students/Mansi Dattatray Nagre.jpg"
import student_14 from "../../assets/images/placed_students/Vishal Mansing Pawar.jpg"
import student_15 from "../../assets/images/placed_students/Rupesh Bharat Agale.jpg"


import student21_23_Img1 from "../../assets/images/placed-student/2021-23/1.jpg"
import student21_23_Img2 from "../../assets/images/placed-student/2021-23/2.jpg"
import student21_23_Img3 from "../../assets/images/placed-student/2021-23/3.jpg"
import student21_23_Img4 from "../../assets/images/placed-student/2021-23/4.jpg"
import student21_23_Img5 from "../../assets/images/placed-student/2021-23/5.jpg"
import student21_23_Img6 from "../../assets/images/placed-student/2021-23/6.jpg"
import student21_23_Img7 from "../../assets/images/placed-student/2021-23/7.jpg"
import student21_23_Img8 from "../../assets/images/placed-student/2021-23/8.jpg"
import student21_23_Img9 from "../../assets/images/placed-student/2021-23/9.jpg"
import student21_23_Img10 from "../../assets/images/placed-student/2021-23/10.jpg"
import student21_23_Img11 from "../../assets/images/placed-student/2021-23/11.png"
import student21_23_Img12 from "../../assets/images/placed-student/2021-23/12.jpg"
import student21_23_Img13 from "../../assets/images/placed-student/2021-23/13.jpg"
import student21_23_Img14 from "../../assets/images/placed-student/2021-23/14.jpg"
import student21_23_Img15 from "../../assets/images/placed-student/2021-23/15.jpg"

import student20_22_Img1 from "../../assets/images/placed-student/2020-22/1.jpg"
import student20_22_Img2 from "../../assets/images/placed-student/2020-22/2.png"
import student20_22_Img3 from "../../assets/images/placed-student/2020-22/3.png"
import student20_22_Img4 from "../../assets/images/placed-student/2020-22/4.jpg"
import student20_22_Img5 from "../../assets/images/placed-student/2020-22/5.jpg"
import student20_22_Img6 from "../../assets/images/placed-student/2020-22/6.jpg"
import student20_22_Img7 from "../../assets/images/placed-student/2020-22/7.jpg"
import student20_22_Img8 from "../../assets/images/placed-student/2020-22/8.png"
import student20_22_Img9 from "../../assets/images/placed-student/2020-22/9.jpg"
import student20_22_Img10 from "../../assets/images/placed-student/2020-22/10.jpg"
import student20_22_Img11 from "../../assets/images/placed-student/2020-22/11.png"
import student20_22_Img12 from "../../assets/images/placed-student/2020-22/12.png"
import student20_22_Img13 from "../../assets/images/placed-student/2020-22/13.jpg"
import student20_22_Img14 from "../../assets/images/placed-student/2020-22/14.png"
import student20_22_Img15 from "../../assets/images/placed-student/2020-22/15.jpg"


import { useState } from "react";
import './style.css';

import placementData from "./placement.json"
import Table from 'react-bootstrap/Table';
const PlacementAtISBS = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	const [Placement2023, setPlacement2023] = useState(true)
	const [Placement2022, setPlacement2022] = useState(false)
	const [Placement2021, setPlacement2021] = useState(false)
	const [Placement2020, setPlacement2020] = useState(false)
	const [Placement2019, setPlacement2019] = useState(false)
	const [Placement2018, setPlacement2018] = useState(false)
	const [Placement2017, setPlacement2017] = useState(false)
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	const Placement2023Handler = () => {
		setPlacement2023(true)
		setPlacement2022(false)
		setPlacement2021(false)
		setPlacement2020(false)
		setPlacement2019(false)
		setPlacement2018(false)
		setPlacement2017(false)
	}
	const Placement2022Handler = () => {
		setPlacement2022(true)
		setPlacement2023(false)
		setPlacement2021(false)
		setPlacement2020(false)
		setPlacement2019(false)
		setPlacement2018(false)
		setPlacement2017(false)
	}
	const Placement2021Handler = () => {
		setPlacement2021(true)
		setPlacement2023(false)
		setPlacement2022(false)
		setPlacement2020(false)
		setPlacement2019(false)
		setPlacement2018(false)
		setPlacement2017(false)
	}
	const Placement2020Handler = () => {
		setPlacement2021(false)
		setPlacement2022(false)
		setPlacement2020(true)
		setPlacement2019(false)
		setPlacement2018(false)
		setPlacement2017(false)
	}
	const Placement2019Handler = () => {
		setPlacement2022(false)
		setPlacement2023(false)
		setPlacement2021(false)
		setPlacement2020(false)
		setPlacement2019(true)
		setPlacement2018(false)
		setPlacement2017(false)
	}
	const Placement2018Handler = () => {
		setPlacement2023(false)
		setPlacement2022(false)
		setPlacement2021(false)
		setPlacement2020(false)
		setPlacement2019(false)
		setPlacement2018(true)
		setPlacement2017(false)
	}
	const Placement2017Handler = () => {
		setPlacement2023(false)
		setPlacement2022(false)
		setPlacement2021(false)
		setPlacement2020(false)
		setPlacement2019(false)
		setPlacement2018(false)
		setPlacement2017(true)
	}


	return (
		<Fragment>
			<Header></Header>
			<div className="col bannerCont">
				<img src={BannerImage} alt="" className="imgRes" />
				<h2 className="bannerTitleAbout leftAlignBannerTxt whiteText">PLACEMENT AT<br />
					ISBS</h2>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h3 className="blockTitle">Placement &#62; <span> Placement At ISBS</span></h3>
					</div>
				</div>
			</div>
			<div className="backgroundGreyPlain">
				<div className="container">
					<div className="row">


					<div className="col-md-2 cursorPointer placemetBox" onClick={Placement2023Handler}>
							<div className={Placement2022 ? "colClickBox minHeight190" : "colClickBox"}>
								<div className="clickIconBOx">
									<img src={Icon1Image} alt="" className="clickBoxIcon" />
								</div>
								<h3 className="blockTitle">Placement Stats<br />
									2024-25</h3>
							</div>
						</div>





						<div className="col-md-2 cursorPointer placemetBox" onClick={Placement2022Handler}>
							<div className={Placement2022 ? "colClickBox minHeight190" : "colClickBox"}>
								<div className="clickIconBOx">
									<img src={Icon2Image} alt="" className="clickBoxIcon" />
								</div>
								<h3 className="blockTitle">Placement Stats<br />
									2022-24</h3>
							</div>
						</div>


						<div className="col-md-2 cursorPointer placemetBox" onClick={Placement2021Handler}>
							<div className={Placement2021 ? "colClickBox minHeight190" : "colClickBox"}>
								<div className="clickIconBOx">
									<img src={Icon3Image} alt="" className="clickBoxIcon" />
								</div>
								<h3 className="blockTitle">Placement Stats<br />
									2021-23</h3>
							</div>
						</div>
						<div className="col-md-2 cursorPointer placemetBox" onClick={Placement2020Handler}>
							<div className={Placement2020 ? "colClickBox minHeight190" : "colClickBox"}>
								<div className="clickIconBOx">
									<img src={Icon1Image} alt="" className="clickBoxIcon" />
								</div>
								<h3 className="blockTitle">Placement Stats<br />
									2020-2022 </h3>
							</div>

						</div>
						<div className="col-md-2 cursorPointer placemetBox" onClick={Placement2019Handler}>
							<div className={Placement2019 ? "colClickBox minHeight190" : "colClickBox"}>
								<div className="clickIconBOx">
									<img src={Icon2Image} alt="" className="clickBoxIcon" />
								</div>
								<h3 className="blockTitle">Placement Stats<br />
									2019-21</h3>
							</div>
						</div>
						<div className="col-md-2 cursorPointer placemetBox" onClick={Placement2018Handler}>
							<div className={Placement2018 ? "colClickBox minHeight190" : "colClickBox"}>
								<div className="clickIconBOx">
									<img src={Icon3Image} alt="" className="clickBoxIcon" />
								</div>
								<h3 className="blockTitle">Placement Stats<br />
									2018-20</h3>
							</div>
						</div>
					


						{Placement2023 &&
							<div className="paddingLeft MobPlaceMarginTop">
								<div className="col-md-12 padding15px whiteBackground">
									<h3 className="blockTitle">HOW INDIRA SCHOOL OF BUSINESS STUDIES (ISBS) RANKS
										HIGH ON MBA 2022-2024 PLACEMENT</h3>
									<p>MBA courses at ISBS are nationally recognised and offer aspiring business professionals the right career platform to grow even
										in challenging times.</p>
									<div className="row">
										<div className="col-md-3 marginBottom3em">
											<div className="col-md-12  boxShadow padding15px borderRadius15  text-center minHeight335">
												
												<img src={Graph2024} className="imgRes" />
											</div>
										</div>
										<div className="col-md-9 marginBottom3em">
											<div className="col-md-12 boxShadow padding15px borderRadius15 text-center minHeight335 mobHeightAuto">

												<img src={GraphPackage} className="width80" />
											</div>
										</div>

										<div className="col-md-2"></div>
										<div className="col-md-8 boxShadow padding15px borderRadius15 text-center minHeight335 mobHeightAuto">
											<img src={GraphEmployment} className="imgRes" />
										</div>
										<div className="col-md-2">

										</div>
									</div>
									<div className="col-md-12 marginBottom3em">
										<h3 className="blockTitle text-center">Top Placed Students Batch 2023-25 </h3>
										<div className="row">
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_1} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Ashish Popat Mote</b></h4>
														<h5><b>Company: </b>Federal Bank</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">16.42 Lakh</span></p>
													</div>
												</div>



											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_2} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Bhakti Ganesh Dahale</b></h4>
														<h5><b>Company: </b>Federal Bank </h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">16.42 Lakh</span></p>
													</div>
												</div>


											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_3} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Aditya Kumar Mishra</b></h4>
														<h5><b>Company: </b>Federal Bank</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">16.42 Lakh</span></p>
													</div>
												</div>


											</div>

										</div>

										<div className="row">
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_4} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Anjali Sanjay Borse</b></h4>
														<h5><b>Company: </b>Federal Bank</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">16.42 Lakh</span></p>
													</div>
												</div>



											</div>
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_5} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Bhargavi Dilip Wadighare</b></h4>
														<h5><b>Company: </b>Federal Bank</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">16.42 Lakh</span></p>
													</div>
												</div>


											</div>

											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_6} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Abhishek Jamuwant Kurmelwar  </b></h4>
														<h5><b>Company: </b> Pattern Technology </h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">10.5 Lakh</span></p>
													</div>
												</div>


											</div>
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_7} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b> Bhavesh Sunil Khadka</b></h4>
														<h5><b>Company: </b>Pattern Technology</h5>
														<p><b>Stream: </b> MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">10.5 Lakh</span></p>
													</div>
												</div>



											</div>
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_8} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Poorvi Sanjay Sahu</b></h4>
														<h5><b>Company: </b>Pattern Technology </h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">10.5 Lakh</span></p>
													</div>
												</div>


											</div>

											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_9} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>	Rushikesh Ulhas Medshikar</b></h4>
														<h5><b>Company: </b>Pattern Technology</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">10.5  Lakh</span></p>
													</div>
												</div>


											</div>


										</div>

										<div className="row">



											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_10} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Yuganshu Dilip Khatke</b></h4>
														<h5><b>Company: </b>Pattern Technology</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">10.5 Lakh</span></p>
													</div>
												</div>



											</div>
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_11} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>	Ajinkya Anantrao Sable</b></h4>
														<h5><b>Company: </b>Pattern Technology</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew"> 10.5 Lakh</span></p>
													</div>
												</div>


											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_12} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Bhavesh Ajit Pande </b></h4>
														<h5><b>Company: </b> Philips</h5>
														<p><b>Stream: </b>MBA(Operation)</p>
														<p><b>Package: </b><span className="packageNew">10.5  Lakh</span></p>
													</div>
												</div>


											</div>
										</div>
										<div className="row">
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_13} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Mansi Dattatray Nagre </b></h4>
														<h5><b>Company: </b> Philips</h5>
														<p><b>Stream: </b>MBA(Operation)</p>
														<p><b>Package: </b><span className="packageNew">10.5 Lakh</span></p>
													</div>
												</div>



											</div>


											
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_14} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Vishal Mansing Pawar</b></h4>
														<h5><b>Company: </b>Mahindra Finance</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">10 Lakh</span></p>
													</div>
												</div>


											</div>
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student_15} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Rupesh Bharat Agale</b></h4>
														<h5><b>Company: </b>Mahindra Finance</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">10 Lakh</span></p>
													</div>
												</div>


											</div>


										</div>

									</div>

									<div className="col-md-12 marginBottom3em">
										<h3 className="blockTitle text-center">Placed Students of Batch 2023-25 updated Till 26.12.2024 </h3>
										<Table class="table table-responsive table-bordered" responsive>
											<tbody>
												<tr>
													<td><b>Sr No.</b></td>
													<td><b>On/Self/PPO Campus</b></td>
													<td><b>Students Name</b></td>
													<td><b>Campus</b></td>
													<td><b>Gender</b></td>
													<td><b>Name of the Company</b></td>
													<td><b>Sector</b></td>
													<td><b>Package in LPA INR</b></td>
													<td><b>Specialization(Major)</b></td>
												</tr>
												<tr>
        <td>1</td>
        <td>On Campus</td>
        <td>Ashish Popat Mote</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Federal Bank</td>
        <td>BFSI</td>
        <td>16.42</td>
        <td>Finance</td>
    </tr>
	<tr>
        <td>2</td>
        <td>On Campus</td>
        <td>Bhakti Ganesh Dahale</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Federal Bank</td>
        <td>BFSI</td>
        <td>16.42</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>3</td>
        <td>On Campus</td>
        <td>Aditya Kumar Mishra</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Federal Bank</td>
        <td>BFSI</td>
        <td>16.42</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>4</td>
        <td>On Campus</td>
        <td>Anjali Sanjay Borse</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Federal Bank</td>
        <td>BFSI</td>
        <td>16.42</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>5</td>
        <td>On Campus</td>
        <td>Bhargavi Dilip Wadighare</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Federal Bank</td>
        <td>BFSI</td>
        <td>16.42</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>6</td>
        <td>On Campus</td>
        <td>Abhishek Jamuwant Kurmelwar</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Pattern Technology</td>
        <td>IT</td>
        <td>10.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>7</td>
        <td>On Campus</td>
        <td>Bhavesh Sunil Khadka</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Pattern Technology</td>
        <td>IT</td>
        <td>10.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>8</td>
        <td>On Campus</td>
        <td>Poorvi Sanjay Sahu</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Pattern Technology</td>
        <td>IT</td>
        <td>10.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>9</td>
        <td>On Campus</td>
        <td>Rushikesh Ulhas Medshikar</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Pattern Technology</td>
        <td>IT</td>
        <td>10.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>10</td>
        <td>On Campus</td>
        <td>Yuganshu Dilip Khatke</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Pattern Technology</td>
        <td>IT</td>
        <td>10.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>11</td>
        <td>On Campus</td>
        <td>Ajinkya Anantrao Sable</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Pattern Technology</td>
        <td>IT</td>
        <td>10.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>12</td>
        <td>PPO</td>
        <td>Bhavesh Ajit Pande</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Philips</td>
        <td>Electronics</td>
        <td>10.5</td>
        <td>Operation</td>
    </tr>
    <tr>
        <td>13</td>
        <td>PPO</td>
        <td>Mansi Dattatray Nagre</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Philips</td>
        <td>Electronics</td>
        <td>10.5</td>
        <td>Operation</td>
    </tr>
    <tr>
        <td>14</td>
        <td>On Campus</td>
        <td>Vishal Mansing Pawar</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Mahindra Finance</td>
        <td>BFSI</td>
        <td>10</td>
        <td>Finance</td>
    </tr>
	<tr>
        <td>15</td>
        <td>On Campus</td>
        <td>Rupesh Bharat Agale</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Mahindra Finance</td>
        <td>BFSI</td>
        <td>10</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>16</td>
        <td>Self Placed</td>
        <td>Viraj Bharat Dandwate</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Icestesy Projects Pvt. Ltd.</td>
        <td>FMCG</td>
        <td>10</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>17</td>
        <td>On Campus</td>
        <td>Harshal Ramchandra Dabhade</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Trinamix</td>
        <td>IT</td>
        <td>9.5</td>
        <td>Operation</td>
    </tr>
    <tr>
        <td>18</td>
        <td>On Campus</td>
        <td>Pratiksha Tandon</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Trinamix</td>
        <td>IT</td>
        <td>9.5</td>
        <td>Operation</td>
    </tr>
    <tr>
        <td>19</td>
        <td>PPO</td>
        <td>Rohan Ravi Katkar</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>BAGIC</td>
        <td>BFSI</td>
        <td>9.42</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>20</td>
        <td>PPO</td>
        <td>Vaishnavi Madhukarrao Joshi</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>BAGIC</td>
        <td>BFSI</td>
        <td>9.42</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>21</td>
        <td>On Campus</td>
        <td>Chaitanya Anil Sadafale</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Hafele</td>
        <td>Manufacturing</td>
        <td>8.54</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>22</td>
        <td>On Campus</td>
        <td>Jayesh Kailas Gund</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Adani Wilmar</td>
        <td>FMCG</td>
        <td>8.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>23</td>
        <td>PPO</td>
        <td>Sumit  Joshi</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Nestle</td>
        <td>FMCG</td>
        <td>8.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>24</td>
        <td>On Campus</td>
        <td>Abhishek Ganeshrao More</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>ICICI Bank</td>
        <td>BFSI</td>
        <td>8.29</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>25</td>
        <td>On Campus</td>
        <td>Pranay Prakash Dheringe</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Forvis Mazars</td>
        <td>BFSI</td>
        <td>7.95</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>26</td>
        <td>On Campus</td>
        <td>Aashish Dnyaneshwar Saravade</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Deloitte (Tax)</td>
        <td>Consulting</td>
        <td>7.6</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>27</td>
        <td>On Campus</td>
        <td>Akash Sayaji Pandilwar</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Deloitte (Tax)</td>
        <td>Consulting</td>
        <td>7.6</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>28</td>
        <td>On Campus</td>
        <td>Apoorv Mohan Deshmukh</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Deloitte (Tax)</td>
        <td>Consulting</td>
        <td>7.6</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>29</td>
        <td>On Campus</td>
        <td>Sakshi Surendra More</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Deloitte (Tax)</td>
        <td>Consulting</td>
        <td>7.6</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>30</td>
        <td>On Campus</td>
        <td>Vyankatesh Bibhishanrao Jadhav</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Deloitte (Tax)</td>
        <td>Consulting</td>
        <td>7.6</td>
        <td>Finance</td>
    </tr>
	<tr>
        <td>31</td>
        <td>On Campus</td>
        <td>Yashraj Umesh Joshi</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Deloitte (Tax)</td>
        <td>Consulting</td>
        <td>7.6</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>32</td>
        <td>On Campus</td>
        <td>Sanya Agrawal</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Deloitte (Tax)</td>
        <td>Consulting</td>
        <td>7.6</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>33</td>
        <td>On Campus</td>
        <td>Sumedh Digvijay Mahanur</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Deloitte TPMS</td>
        <td>Consulting</td>
        <td>7.6</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>34</td>
        <td>On Campus</td>
        <td>Janhavi Ravindra Paraskar</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Birla Opus Aditya Birla Group</td>
        <td>FMCD</td>
        <td>7.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>35</td>
        <td>On Campus</td>
        <td>Soniya Giridhar Waghmare</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Birla Opus Aditya Birla Group</td>
        <td>FMCD</td>
        <td>7.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>36</td>
        <td>On Campus</td>
        <td>Pavan Chandrakant Patil</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>KD Logistics</td>
        <td>Logistics</td>
        <td>7.5</td>
        <td>Operation</td>
    </tr>
    <tr>
        <td>37</td>
        <td>On Campus</td>
        <td>Vedant Satish Bhambere</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>The Orchids International</td>
        <td>Education</td>
        <td>7</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>38</td>
        <td>On Campus</td>
        <td>Kshitija Raju Shinde</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Factsheet</td>
        <td>BFSI</td>
        <td>6.6</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>39</td>
        <td>On Campus</td>
        <td>Rohit Kale</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Factsheet</td>
        <td>BFSI</td>
        <td>6.6</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>40</td>
        <td>On Campus</td>
        <td>Hitesh Suresh Ruke</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Factsheet</td>
        <td>BFSI</td>
        <td>6.6</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>41</td>
        <td>PPO</td>
        <td>Ramola Praveen Shete</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>UBS </td>
        <td>BFSI</td>
        <td>6.5</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>42</td>
        <td>On Campus</td>
        <td>Shubham Rajendra Deore</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Aditya Birla Sun Life</td>
        <td>BFSI</td>
        <td>6.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>43</td>
        <td>On Campus</td>
        <td>Mansi Udaysingh Rajput</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Faurecia Talent Hub</td>
        <td>Automotive</td>
        <td>6.5</td>
        <td>Operation</td>
    </tr>
    <tr>
        <td>44</td>
        <td>On Campus</td>
        <td>Priya Kumari</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>ANZ Bank</td>
        <td>BFSI</td>
        <td>6.3</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>45</td>
        <td>On Campus</td>
        <td>Priyanka Arjun Nale </td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>ANZ Bank</td>
        <td>BFSI</td>
        <td>6.3</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>46</td>
        <td>On Campus</td>
        <td>Tejas Anil Bhome</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>ANZ Bank</td>
        <td>BFSI</td>
        <td>6.3</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>47</td>
        <td>On Campus</td>
        <td>Ritisha Manoj Kukreja</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>ANZ Bank</td>
        <td>BFSI</td>
        <td>6.3</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>48</td>
        <td>On Campus</td>
        <td>Prasad Vikas Tale</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Aditya Birla Sun Life AMC</td>
        <td>BFSI</td>
        <td>6.25</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>49</td>
        <td>PPO</td>
        <td>Shrikantreddy Ramreddy Pogal</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Global Market Insights</td>
        <td>Research</td>
        <td>6.12</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>50</td>
        <td>On Campus</td>
        <td>Suyash Yuvraj Deshmukh</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>VTP Realty</td>
        <td>Real Estate</td>
        <td>6</td>
        <td>Marketing</td>
    </tr>
	<tr>
        <td>51</td>
        <td>On Campus</td>
        <td>Vaishnavi Gajanan Patil</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Godrej Properties</td>
        <td>Real Estate</td>
        <td>6</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>52</td>
        <td>On Campus</td>
        <td>Shweta Arun Waghmare</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Spacewalk Advisors LLP</td>
        <td>Real Estate</td>
        <td>5.7</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>53</td>
        <td>On Campus</td>
        <td>Ayush Arun Kakde</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Haleon</td>
        <td>Healthcare</td>
        <td>5.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>54</td>
        <td>On Campus</td>
        <td>Suraj Shivaji Gondwale</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Haleon</td>
        <td>Healthcare</td>
        <td>5.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>55</td>
        <td>On Campus</td>
        <td>Abhishek Sitaram Dalal</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>WNS</td>
        <td>IT Consulting</td>
        <td>5</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>56</td>
        <td>On Campus</td>
        <td>Anjali Prakash Sayam</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>WNS</td>
        <td>IT Consulting</td>
        <td>5</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>57</td>
        <td>On Campus</td>
        <td>Bhojraj Ravishankar Merukar</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>WNS</td>
        <td>IT Consulting</td>
        <td>5</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>58</td>
        <td>On Campus</td>
        <td>Dipa Vishnu Falke</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>WNS</td>
        <td>IT Consulting</td>
        <td>5</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>59</td>
        <td>On Campus</td>
        <td>Meghana Prakash Sangame</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>WNS</td>
        <td>IT Consulting</td>
        <td>5</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>60</td>
        <td>On Campus</td>
        <td>Pravin Sonyabapu Salunke</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>WNS</td>
        <td>IT Consulting</td>
        <td>5</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>61</td>
        <td>On Campus</td>
        <td>Shubham Subhash Deshmukh</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>WNS</td>
        <td>IT Consulting</td>
        <td>5</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>62</td>
        <td>On Campus</td>
        <td>Anish Anil Rao</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>WNS</td>
        <td>IT Consulting</td>
        <td>5</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>63</td>
        <td>On Campus</td>
        <td>Arya Basveshvar Patil</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>WNS</td>
        <td>IT Consulting</td>
        <td>5</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>68</td>
        <td>On Campus</td>
        <td>Shubham Panditrao Bharti</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>ITC</td>
        <td>FMCG</td>
        <td>4.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>65</td>
        <td>On Campus</td>
        <td>Parth Santoshrao Gudadhe</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>AMD Consulting</td>
        <td>Consulting</td>
        <td>5</td>
        <td>Operation</td>
    </tr>
    <tr>
        <td>66</td>
        <td>On Campus</td>
        <td>Deepti Yogesh Joshi</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Searce</td>
        <td>IT</td>
        <td>4.5</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>67</td>
        <td>On Campus</td>
        <td>Ajinkya Suhas Kadam</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Grand View Research</td>
        <td>Research</td>
        <td>4.5</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>71</td>
        <td>On Campus</td>
        <td>Basavaraj Mohan Erandole</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td> LT Foods Ltd</td>
        <td>FMCG</td>
        <td>4.2</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>69</td>
        <td>On Campus</td>
        <td>Shubham Sanjay Gunjal</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Zepto</td>
        <td>Q-Commerce</td>
        <td>4.5</td>
        <td>Operation</td>
    </tr>
    <tr>
        <td>70</td>
        <td>On Campus</td>
        <td>Tejas Sanjiv Kendre</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Innovative Retail Concepts Pvt Ltd. (BigBasket.com)</td>
        <td>Retail</td>
        <td>4.3</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>64</td>
        <td>On Campus</td>
        <td>Manish Yuvraj Mistary</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>V Check</td>
        <td>Service Provider</td>
        <td>5</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>72</td>
        <td>On Campus</td>
        <td>Pallavi Sanjayrao Mahure</td>
        <td>ISBS MBA</td>
        <td>F</td>
        <td>Interpid Control System  </td>
        <td>Manufacturing</td>
        <td>4</td>
        <td>Finance</td>
    </tr>
    <tr>
        <td>73</td>
        <td>Self Placed</td>
        <td>Digvijay Pravin Patil</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Infosys</td>
        <td>IT</td>
        <td>3</td>
        <td>Operation</td>
    </tr>
    <tr>
        <td>74</td>
        <td>Self Placed</td>
        <td>Aniket Kishor Lohar</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Wisteria Properties Pvt. Ltd</td>
        <td>Real Estate</td>
        <td>2.7</td>
        <td>Marketing</td>
    </tr>
    <tr>
        <td>75</td>
        <td>Business</td>
        <td>Mayur Ramnath Pawase</td>
        <td>ISBS MBA</td>
        <td>M</td>
        <td>Business</td>
        <td>Business</td>
        <td>-</td>
        <td>Operation</td>
    </tr>
												
										
										</tbody>
										</Table>
									</div>


								</div>
							</div>}





						{Placement2022 &&
							<div className="paddingLeft MobPlaceMarginTop">
								<div className="col-md-12 padding15px whiteBackground">
									<h3 className="blockTitle">HOW INDIRA SCHOOL OF BUSINESS STUDIES (ISBS) RANKS
										HIGH ON MBA 2022-2024 PLACEMENT</h3>
									<p>MBA courses at ISBS are nationally recognised and offer aspiring business professionals the right career platform to grow even
										in challenging times.</p>
									<div className="row">
										<div className="col-md-3 marginBottom3em">
											<div className="col-md-12  boxShadow padding15px borderRadius15  text-center minHeight335">
												{/* <p><b>MBA BATCH PROFILE</b></p>
												<div className="row">
													<div className="col text-center">
														<p>Male</p>
														<img src={MaleImage} className="width80" />
														<div className="textColorBox1">
															71.91%
														</div>
													</div>
													<div className="col text-center">
														<p>Female</p>
														<img src={FemaleImage} className="width80" />
														<div className="textColorBox1">
															28.09%
														</div>
													</div>
												</div> */}
												<img src={Graph2024Profile} className="imgRes" />
											</div>
										</div>
										<div className="col-md-9 marginBottom3em">
											<div className="col-md-12 boxShadow padding15px borderRadius15 text-center minHeight335 mobHeightAuto">

												<img src={Graph2024Package} className="width80" />
											</div>
										</div>

										<div className="col-md-2"></div>
										<div className="col-md-8 boxShadow padding15px borderRadius15 text-center minHeight335 mobHeightAuto">
											<img src={Graph2024Employment} className="imgRes" />
										</div>
										<div className="col-md-2">

										</div>
									</div>
									<div className="col-md-12 marginBottom3em">
										<h3 className="blockTitle text-center">Top Placed Students Batch 2022-24 </h3>
										<div className="row">
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img1} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Akash Tulsidas Padile</b></h4>
														<h5><b>Company: </b>Federal Bank</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">16.3 Lakh</span></p>
													</div>
												</div>



											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img2} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Kishor M. Dongare</b></h4>
														<h5><b>Company: </b>Federal Bank </h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">16.3 Lakh</span></p>
													</div>
												</div>


											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img3} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Sanskar Anil Pariha</b></h4>
														<h5><b>Company: </b>Federal Bank</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">16.3 Lakh</span></p>
													</div>
												</div>


											</div>

										</div>

										<div className="row">
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img4} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Simrandas E. Vaishnav</b></h4>
														<h5><b>Company: </b>Federal Bank</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">16.3 Lakh</span></p>
													</div>
												</div>



											</div>
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img9} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Gayatri S. Tekade</b></h4>
														<h5><b>Company: </b>Federal Bank</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">16.3 Lakh</span></p>
													</div>
												</div>


											</div>

											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img11} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Vrushabh R. Wadbudhe   </b></h4>
														<h5><b>Company: </b> Federal Bank </h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">16.3 Lakh</span></p>
													</div>
												</div>


											</div>
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img10} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b> Prerna R. Jamkar</b></h4>
														<h5><b>Company: </b>Federal Bank</h5>
														<p><b>Stream: </b> MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">16.3 Lakh</span></p>
													</div>
												</div>



											</div>
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img5} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Falguni R. Deshmukh</b></h4>
														<h5><b>Company: </b>BNY </h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">10.20 Lakh</span></p>
													</div>
												</div>


											</div>

											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img6} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Prathna Agrawal</b></h4>
														<h5><b>Company: </b>BNY</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">10.20  Lakh</span></p>
													</div>
												</div>


											</div>


										</div>

										<div className="row">



											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img7} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Purva M. Chandak</b></h4>
														<h5><b>Company: </b>BNY</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">10.20 Lakh</span></p>
													</div>
												</div>



											</div>
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img8} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Rajashree R. Mukherjee</b></h4>
														<h5><b>Company: </b>BNY</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew"> 10.20 Lakh</span></p>
													</div>
												</div>


											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img15} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Abhishek Ramdas Patil   </b></h4>
														<h5><b>Company: </b> Mahindra Finance</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">10  Lakh</span></p>
													</div>
												</div>


											</div>
										</div>
										<div className="row">
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img13} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Vikrant Manoj Pardeshi    </b></h4>
														<h5><b>Company: </b> Reliance BP</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">9 Lakh</span></p>
													</div>
												</div>



											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img14} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Mangesh Vaijanath Shirole </b></h4>
														<h5><b>Company: </b>Emerson  </h5>
														<p><b>Stream: </b>MBA(HR)</p>
														<p><b>Package: </b><span className="packageNew">9 Lakh</span></p>
													</div>
												</div>


											</div>
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student22_24_Img12} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Vrushank Sanjay Pardeshi</b></h4>
														<h5><b>Company: </b>Bajaj Allianz General  Insurance Company</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">8.68 Lakh</span></p>
													</div>
												</div>


											</div>



										</div>

									</div>

									<div className="col-md-12 marginBottom3em">
										<h3 className="blockTitle text-center">Placed Students of Batch 2022-2024 </h3>
										<Table class="table table-responsive table-bordered" responsive>
											<tbody>
												<tr>
													<td><b>Sr No.</b></td>
													<td><b>On/Self/PPO Campus</b></td>
													<td><b>Students Name</b></td>
													<td><b>Campus</b></td>
													<td><b>Gender</b></td>
													<td><b>Name of the Company</b></td>
													<td><b>Sector</b></td>
													<td><b>Package in LPA INR</b></td>
													<td><b>Specialization(Major)</b></td>
												</tr>
												<tr>
													<td>1</td>
													<td>On Campus</td>
													<td>Akash Tulsidas Padile</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Federal Bank</td>
													<td>BFSI</td>
													<td>16.32</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>2</td>
													<td>On Campus</td>
													<td>Gayatri Sharad Tekade</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Federal Bank</td>
													<td>BFSI</td>
													<td>16.32</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>3</td>
													<td>On Campus</td>
													<td>Kishor Mukundrao Dongare</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Federal Bank</td>
													<td>BFSI</td>
													<td>16.32</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>4</td>
													<td>On Campus</td>
													<td>Prerna Ravindra Jamkar</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Federal Bank</td>
													<td>BFSI</td>
													<td>16.32</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>5</td>
													<td>On Campus</td>
													<td>Sanskar Anil parihar</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Federal Bank</td>
													<td>BFSI</td>
													<td>16.32</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>6</td>
													<td>On Campus</td>
													<td>Vrushabh Ramu Wadbudhe </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Federal Bank</td>
													<td>BFSI</td>
													<td>16.32</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>7</td>
													<td>On Campus</td>
													<td>Simrandas Eshwardas Vaishnav</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Federal Bank</td>
													<td>BFSI</td>
													<td>16.32</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>8</td>
													<td>On Campus</td>
													<td>
													Falguni Ramesh Deshmukh </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Bank of New York Mellon (BNY)</td>
													<td>BFSI</td>
													<td>10.2</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>9</td>
													<td>On Campus</td>
													<td>Simrandas Eshwardas Vaishnav</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Federal Bank</td>
													<td>BFSI</td>
													<td>16.32</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>10</td>
													<td>On Campus</td>
													<td>Falguni Ramesh Deshmukh </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Bank of New York Mellon (BNY)</td>
													<td>BFSI</td>
													<td>10.2</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>11</td>
													<td>On Campus</td>
													<td>Prathna Agrawal </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Bank of New York Mellon (BNY)</td>
													<td>BFSI</td>
													<td>10.2</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>12</td>
													<td>On Campus</td>
													<td>Purva Murlidhar Chandak </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Bank of New York Mellon (BNY)</td>
													<td>BFSI</td>
													<td>10.2</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>13</td>
													<td>On Campus</td>
													<td>Rajashree Raja Mukherjee</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Bank of New York Mellon (BNY)</td>
													<td>BFSI</td>
													<td>10.2</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>14</td>
													<td>On Campus</td>
													<td>Abhishek Ramdas Patil</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Mahindra Finance</td>
													<td>BFSI</td>
													<td>10</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>15</td>
													<td>On Campus</td>
													<td>Riya Kumari</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Pattern Technologies</td>
													<td>IT</td>
													<td>9.3000000000000007</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>16</td>
													<td>On Campus</td>
													<td>Abhay prakash Singh </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Hawkins</td>
													<td>Manufacturing</td>
													<td>9</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>17</td>
													<td>On Campus</td>
													<td>Vikrant Manoj Pardeshi </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Reliance BP</td>
													<td>Petroleum</td>
													<td>9</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>18</td>
													<td>On Campus</td>
													<td>Anirudh  Kaul</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Bajaj Allianz General Insurance Company</td>
													<td>BFSI</td>
													<td>8.68</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>19</td>
													<td>On Campus</td>
													<td>Vrushank Sanjay Pardeshi</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Bajaj Allianz General Insurance Company</td>
													<td>BFSI</td>
													<td>8.68</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>20</td>
													<td>On Campus</td>
													<td>Simran  </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Bajaj Allianz General Insurance Company</td>
													<td>BFSI</td>
													<td>8.68</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>21</td>
													<td>On Campus</td>
													<td>Rutuja Hanumant Kaware </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Allied Digital</td>
													<td>IT</td>
													<td>8.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>22</td>
													<td>On Campus</td>
													<td>Tanya Udaypratap Singh Thakur </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Hager Group</td>
													<td>Manufacturing</td>
													<td>8.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>23</td>
													<td>On Campus</td>
													<td>Gayatri Gajanan Nalkande </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>ICICI Bank</td>
													<td>BFSI</td>
													<td>8.2</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>24</td>
													<td>On Campus</td>
													<td>Kash Rakesh Budhlani </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>ICICI Bank</td>
													<td>BFSI</td>
													<td>8.2</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>25</td>
													<td>On Campus</td>
													<td>Nikhil Madan Lambat </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>ICICI Bank</td>
													<td>BFSI</td>
													<td>8.2</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>26</td>
													<td>On Campus</td>
													<td>Abhay Ramdhan Ade</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>ICICI Bank</td>
													<td>BFSI</td>
													<td>8.2</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>27</td>
													<td>On Campus</td>
													<td>Rishav</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>ICICI Bank</td>
													<td>BFSI</td>
													<td>8.2</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>28</td>
													<td>On Campus</td>
													<td>Rushikesh Baban Binnar</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>ICICI Bank</td>
													<td>BFSI</td>
													<td>8.2</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>29</td>
													<td>On Campus</td>
													<td>Pratik Devidas Bhosale </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Homes First Finance</td>
													<td>BFSI</td>
													<td>8</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>30</td>
													<td>On Campus</td>
													<td>Girish Ravindra Mohite </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Trinamix Systems Private Limited</td>
													<td>IT</td>
													<td>8</td>
													<td>Operation</td>
												</tr>
												<tr>
													<td>31</td>
													<td>On Campus</td>
													<td>Ashwin Ashok Ahire </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Homes First Finance</td>
													<td>BFSI</td>
													<td>8</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>32</td>
													<td>On Campus</td>
													<td>Durgesh Prafulla Thakur</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Bestosys Solutions Pvt. Ltd.</td>
													<td>IT</td>
													<td>8</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>33</td>
													<td>On Campus</td>
													<td>Rushikesh Vishnupant kathe</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Bestosys Solutions Pvt. Ltd.</td>
													<td>IT</td>
													<td>8</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>34</td>
													<td>On Campus</td>
													<td>Shivam Minkeshwar Chaudhari </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Deloitte </td>
													<td>Consultancy</td>
													<td>7.8</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>35</td>
													<td>On Campus</td>
													<td>Anisha Kumari</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Nestle Ltd.</td>
													<td>FMCG</td>
													<td>7.62</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>36</td>
													<td>On Campus</td>
													<td>Sakshi Shekhar Kalbande </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Deloitte </td>
													<td>Consultancy</td>
													<td>7.6</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>37</td>
													<td>On Campus</td>
													<td>Atharva Sanjay Kolhe </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Deloitte </td>
													<td>Consultancy</td>
													<td>7.6</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>38</td>
													<td>On Campus</td>
													<td>Darshit Dharmeshbhai Patel </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Bike Bazaar</td>
													<td>BFSI</td>
													<td>7.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>39</td>
													<td>On Campus</td>
													<td>Gopal  Bhadauriya</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Asian Paints Ltd.</td>
													<td>FMCD</td>
													<td>7.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>40</td>
													<td>On Campus</td>
													<td>Kiran kailas Wagh</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Asian Paints Ltd.</td>
													<td>FMCD</td>
													<td>7.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>41</td>
													<td>On Campus</td>
													<td>Kishor Arjun Kumbhar</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Grasim Industries Ltd (Birla Paints)</td>
													<td>FMCD</td>
													<td>7.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>42</td>
													<td>On Campus</td>
													<td>Premchand Agrawala</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Grasim Industries Ltd (Birla Paints)</td>
													<td>FMCD</td>
													<td>7.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>43</td>
													<td>On Campus</td>
													<td>Saish Kailas Gholap </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>ICICI Prudential life</td>
													<td>BFSI</td>
													<td>7.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>44</td>
													<td>On Campus</td>
													<td>Abhishek Mukesh  Ranjan</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Reliance Retail</td>
													<td>Retail</td>
													<td>7</td>
													<td>Operation</td>
												</tr>
												<tr>
													<td>45</td>
													<td>On Campus</td>
													<td>Akshay Najukram Gahane </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Reliance Retail</td>
													<td>Retail</td>
													<td>7</td>
													<td>Operation</td>
												</tr>
												<tr>
													<td>46</td>
													<td>On Campus</td>
													<td>Abhijit Suryabhan Kalhapure</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Legrand</td>
													<td>Manufacturing</td>
													<td>7</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>47</td>
													<td>On Campus</td>
													<td>Kaushik Bang</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Legrand</td>
													<td>Manufacturing</td>
													<td>7</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>48</td>
													<td>On Campus</td>
													<td>Rishi Anil Patil</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Legrand</td>
													<td>Manufacturing</td>
													<td>7</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>49</td>
													<td>On Campus</td>
													<td>Shubham Jaiprakash Jituri </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Reliance Retail</td>
													<td>Retail</td>
													<td>7</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>50</td>
													<td>On Campus</td>
													<td>Dhruv Ullas Patidar </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Xanadu Realty</td>
													<td>Real Estate</td>
													<td>6.82</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>51</td>
													<td>On Campus</td>
													<td>Aishwarya Suhas Sutar</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>HDFC Bank</td>
													<td>BFSI</td>
													<td>6.8</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>52</td>
													<td>On Campus</td>
													<td>Nikhita Abhay Katkar </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>FIS Global</td>
													<td>BFSI</td>
													<td>6.69</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>53</td>
													<td>On Campus</td>
													<td>Sanskruti Pravin Khandar </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>FIS Global</td>
													<td>BFSI</td>
													<td>6.6</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>54</td>
													<td>On Campus</td>
													<td>Utkarsh Subhash Lokhande </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>FIS Global</td>
													<td>BFSI</td>
													<td>6.6</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>55</td>
													<td>PPO</td>
													<td>Shivani Ashok Kale </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Global Market Vision</td>
													<td>Research</td>
													<td>6.6</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>56</td>
													<td>On Campus</td>
													<td>Shraddha Prakash Patil </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Siemens</td>
													<td>IT</td>
													<td>6.5</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>57</td>
													<td>On Campus</td>
													<td>Rutuj Waman Hukare </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Siemens</td>
													<td>IT</td>
													<td>6.5</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>58</td>
													<td>On Campus</td>
													<td>Adnan Ismail Attar</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Nj Group</td>
													<td>BFSI</td>
													<td>6.21</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>59</td>
													<td>On Campus</td>
													<td>Astha Kumari </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Saint Gobain</td>
													<td>Manufacturing</td>
													<td>6.2</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>60</td>
													<td>On Campus</td>
													<td>Ajinkya Vijay Kahalkar</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Global Market Insights</td>
													<td>Research</td>
													<td>6.12</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>61</td>
													<td>On Campus</td>
													<td>Komal Vasanta Uikey</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Emcure Pharmaceuticals</td>
													<td>Pharma</td>
													<td>6</td>
													<td>Operation</td>
												</tr>
												<tr>
													<td>62</td>
													<td>On Campus</td>
													<td>Achal Subhash Bhogekar </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Vibe Media Group</td>
													<td>Media</td>
													<td>6</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>63</td>
													<td>On Campus</td>
													<td>Dnyandeep Ashok Gawande</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Searce</td>
													<td>IT</td>
													<td>6</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>64</td>
													<td>On Campus</td>
													<td>Ajay Bhagwanrao Bokare </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Vijay Investedge Pvt. Ltd.</td>
													<td>BFSI</td>
													<td>6</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>65</td>
													<td>On Campus</td>
													<td>Anuj Mudgal</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Prism Johnson Limited</td>
													<td>Manufacturing</td>
													<td>6</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>66</td>
													<td>On Campus</td>
													<td>Prasad Vijaykumar Kadam</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Vibe Media Group</td>
													<td>Media</td>
													<td>6</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>67</td>
													<td>On Campus</td>
													<td>Punit</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Heaptrace Technology.</td>
													<td>Consultancy</td>
													<td>6</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>68</td>
													<td>On Campus</td>
													<td>Saurabh Devidas Bakal </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Intellect Design Arena</td>
													<td>IT</td>
													<td>6</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>69</td>
													<td>On Campus</td>
													<td>Juhi Anil Kurhe</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>American Express</td>
													<td>BFSI</td>
													<td>6</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>70</td>
													<td>On Campus</td>
													<td>Vaishnavi Dnyaneshwar Dhote</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Prism Johnson Limited</td>
													<td>Manufacturing</td>
													<td>6</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>71</td>
													<td>On Campus</td>
													<td>Ayushi Sushil Jain</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Radamatic Solutions Pvt Ltd </td>
													<td>IT</td>
													<td>6</td>
													<td>HR</td>
												</tr>
												<tr>
													<td>72</td>
													<td>On Campus</td>
													<td>Kajal Suresh Dave</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Tata Technologies</td>
													<td>IT</td>
													<td>5.95</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>73</td>
													<td>On Campus</td>
													<td>Akash Prakash Neje</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Tata Technologies</td>
													<td>IT</td>
													<td>5.95</td>
													<td>Operation</td>
												</tr>
												<tr>
													<td>74</td>
													<td>On Campus</td>
													<td>Lochan Dindayal Bawankule </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Tata Technologies</td>
													<td>IT</td>
													<td>5.95</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>75</td>
													<td>On Campus</td>
													<td>Shalaka Avinash Sule</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Tata Consultancy Services (TCS)</td>
													<td>IT</td>
													<td>5.79</td>
													<td>HR</td>
												</tr>
												<tr>
													<td>76</td>
													<td>On Campus</td>
													<td>Abhijeet Arvind Patange </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Bajaj Capital</td>
													<td>BFSI</td>
													<td>5.6</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>77</td>
													<td>On Campus</td>
													<td>Vaibhav Pravin Ghadge </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Bajaj Capital</td>
													<td>BFSI</td>
													<td>5.6</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>78</td>
													<td>On Campus</td>
													<td>Vikas Rajendra Shingote</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>HDFC Ergo General Insurance</td>
													<td>BFSI</td>
													<td>5.5</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>79</td>
													<td>On Campus</td>
													<td>Omkar Avinash Ghanekar </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Markets and Markets</td>
													<td>Research</td>
													<td>5.5</td>
													<td>Operation</td>
												</tr>
												<tr>
													<td>80</td>
													<td>On Campus</td>
													<td>Nirbhay Shukla </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Housiey</td>
													<td>Real Estate</td>
													<td>5.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>81</td>
													<td>On Campus</td>
													<td>Vedant Shrikant Kulkarni</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>HDFC Ergo General Insurance</td>
													<td>BFSI</td>
													<td>5.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>82</td>
													<td>On Campus</td>
													<td>Varun Subodh Mathur</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Grand View Research</td>
													<td>Research</td>
													<td>5.37</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>83</td>
													<td>On Campus</td>
													<td>Swapnil Chintaman Pathade</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Arofine Polymers</td>
													<td>Manufacturing</td>
													<td>5.3</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>84</td>
													<td>On Campus</td>
													<td>Chaitrali Rajesh Khandge </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>TK Elevators</td>
													<td>Manufacturing</td>
													<td>5</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>85</td>
													<td>On Campus</td>
													<td>Sejal Sunil Sahu</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>TK Elevators</td>
													<td>Manufacturing</td>
													<td>5</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>86</td>
													<td>On Campus</td>
													<td>Ashutosh Kailas More </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Anand Rathi Shares and Stock Brokers Ltd.</td>
													<td>Research</td>
													<td>5</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>87</td>
													<td>On Campus</td>
													<td>Govind Vijayprakash Tapadiya </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Anand Rathi</td>
													<td>BFSI</td>
													<td>5</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>88</td>
													<td>On Campus</td>
													<td>Rohal Deepak Kardile</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Globeflex Research India </td>
													<td>Research</td>
													<td>5</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>89</td>
													<td>Self Placed</td>
													<td>Amol Sahebrao Rathod </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Arriba Labs</td>
													<td>E-Commerce</td>
													<td>5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>90</td>
													<td>On Campus</td>
													<td>Sourabh Dhanaji Pote </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Bajaj Capital</td>
													<td>BFSI</td>
													<td>4.8</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>91</td>
													<td>On Campus</td>
													<td>Mayuresh Bhaskar Gahane </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>HDFC Life</td>
													<td>BFSI</td>
													<td>4.75</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>92</td>
													<td>On Campus</td>
													<td>Ravi Narayan Tirukhe</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>HDFC Life</td>
													<td>BFSI</td>
													<td>4.75</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>93</td>
													<td>On Campus</td>
													<td>Pratik Rajkumar Mohod </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Axis Bank</td>
													<td>BFSI</td>
													<td>4.6399999999999997</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>94</td>
													<td>On Campus</td>
													<td>Damini Mishra </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Himalaya Wellness</td>
													<td>Pharma</td>
													<td>4.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>95</td>
													<td>On Campus</td>
													<td>Abhishek Shelke</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>ADM Group</td>
													<td>Automotive</td>
													<td>4.5</td>
													<td>Operation</td>
												</tr>
												<tr>
													<td>96</td>
													<td>On Campus</td>
													<td>Ashay Gangadhar Langde </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Ultratech Cement </td>
													<td>Manufacturing</td>
													<td>4.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>97</td>
													<td>On Campus</td>
													<td>Sumeet Chandrajeet Yadav</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>ITC Ltd.</td>
													<td>FMCG</td>
													<td>4.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>98</td>
													<td>On Campus</td>
													<td>Shrushti Yashwant Patil</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Himalaya Wellness</td>
													<td>Pharma</td>
													<td>4.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>99</td>
													<td>On Campus</td>
													<td>Mrinal Bhimarao Patil </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>LTI Mindtree</td>
													<td>IT </td>
													<td>4.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>100</td>
													<td>On Campus</td>
													<td>Atharva Lakhe</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>EY(Ernst & Young)</td>
													<td>Consultancy</td>
													<td>4.46</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>101</td>
													<td>On Campus</td>
													<td>Hemant Pravin Gite</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Parekh Intergrated Services Ltd.</td>
													<td>Logistic</td>
													<td>4</td>
													<td>Operation</td>
												</tr>
												<tr>
													<td>102</td>
													<td>Self Placed</td>
													<td>Akshay Sanjay Daga</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Justo</td>
													<td>Real Estate</td>
													<td>4</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>103</td>
													<td>On Campus</td>
													<td>Pratik Deepak Kumar</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Bajaj Allianz life Insurance Company</td>
													<td>BFSI</td>
													<td>4</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>104</td>
													<td>On Campus</td>
													<td>Nikita Namdeorao Dharpure</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>WNS Global</td>
													<td>IT</td>
													<td>4</td>
													<td>HR</td>
												</tr>
												<tr>
													<td>105</td>
													<td>On Campus</td>
													<td>Vaibhav Rajiv Thorat</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>KPMG</td>
													<td>BFSI</td>
													<td>3.93</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>106</td>
													<td>On Campus</td>
													<td>Aarti Shiwaji Dawange </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>TML Business Services Limited.</td>
													<td>Consultancy</td>
													<td>3.5</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>107</td>
													<td>On Campus</td>
													<td>Annapurna Bharat Rathod</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>SG Analytics</td>
													<td>Consulting</td>
													<td>3.5</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>108</td>
													<td>On Campus</td>
													<td>Pinki Kailaschand Varma </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>TML Business Services Limited.</td>
													<td>Consultancy</td>
													<td>3.5</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>109</td>
													<td>On Campus</td>
													<td>Rashmi Laxman Patekar</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>SG Analytics</td>
													<td>Consulting</td>
													<td>3.5</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>110</td>
													<td>Self Placed</td>
													<td>Lipi Bowaria</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Zocdoc</td>
													<td>Healthcare</td>
													<td>3.5</td>
													<td>Operation</td>
												</tr>
												<tr>
													<td>111</td>
													<td>On Campus</td>
													<td>Avantika Nilawar </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>SG Analytics</td>
													<td>Consulting</td>
													<td>3.5</td>
													<td>Operation</td>
												</tr>
												<tr>
													<td>112</td>
													<td>On Campus</td>
													<td>Ajinkya Bhagwan Alhat </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Vibe Media Group</td>
													<td>Media</td>
													<td>3.5</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>113</td>
													<td>On Campus</td>
													<td>Anchal Choudhary </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Neeyamo Enterprise Solutions Pvt. Ltd.</td>
													<td>IT</td>
													<td>3.5</td>
													<td>HR</td>
												</tr>
												<tr>
													<td>114</td>
													<td>On Campus</td>
													<td>Mangesh Vaijanath Shirole </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Emerson</td>
													<td>Manufacturing</td>
													<td>2.16</td>
													<td>HR</td>
												</tr>
												<tr>
													<td>115</td>
													<td>On Campus</td>
													<td>Prachi Mahesh Shelar </td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Forbes Marshall</td>
													<td>Manufacturing</td>
													<td>2.16</td>
													<td>HR</td>
												</tr>
												<tr>
													<td>116</td>
													<td>Business</td>
													<td>Tanvi Kalpesh Kariya</td>
													<td>ISBS MBA</td>
													<td>F</td>
													<td>Business</td>
													<td>-</td>
													<td>-</td>
													<td>Finance</td>
												</tr>
												<tr>
													<td>117</td>
													<td>Business</td>
													<td>Lakshit Jain</td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Business</td>
													<td>-</td>
													<td>-</td>
													<td>Marketing</td>
												</tr>
												<tr>
													<td>118</td>
													<td>Business</td>
													<td>Akshay chandrakant Katruwar </td>
													<td>ISBS MBA</td>
													<td>M</td>
													<td>Business</td>
													<td>-</td>
													<td>-</td>
													<td>Operation</td>
												</tr>
													
												</tbody>
										</Table>
									</div>


								</div>
							</div>}

						{Placement2021 &&
							<div className="paddingLeft MobPlaceMarginTop">
								<div className="col-md-12 padding15px whiteBackground">
									<h3 className="blockTitle">HOW INDIRA SCHOOL OF BUSINESS STUDIES (ISBS) RANKS
										HIGH ON MBA 2021-2023 PLACEMENT</h3>
									<p>MBA courses at ISBS are nationally recognised and offer aspiring business professionals the right career platform to grow even
										in challenging times.</p>
									<div className="row">
										<div className="col-md-3 marginBottom3em">
											<div className="col-md-12 boxShadow padding15px borderRadius15  text-center minHeight335">
												<p><b>MBA BATCH PROFILE</b></p>
												<div className="row">
													<div className="col text-center">
														<p>Male</p>
														<img src={MaleImage} className="width80" />
														<div className="textColorBox1">
															63.71%
														</div>
													</div>
													<div className="col text-center">
														<p>Female</p>
														<img src={FemaleImage} className="width80" />
														<div className="textColorBox1">
															36.29%
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-9 marginBottom3em">
											<div className="col-md-12 boxShadow padding15px borderRadius15 text-center minHeight335 mobHeightAuto">

												<img src={Graph2023Package} className="width80" />
											</div>
										</div>

										<div className="col-md-2"></div>
										<div className="col-md-8 boxShadow padding15px borderRadius15 text-center minHeight335 mobHeightAuto">
											<img src={Graph2023Employment} className="imgRes" />
										</div>
										<div className="col-md-2">

										</div>
									</div>
									<div className="col-md-12 marginBottom3em">
										<h3 className="blockTitle text-center">Top Placed Students Batch 2021-23 </h3>
										<div className="row">
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img1} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Amruta Sanjay Khadke</b></h4>
														<h5><b>Company: </b>Federal Bank</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">13.58  Lakh</span></p>
													</div>
												</div>



											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img2} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Ankita Ramesh Ahire</b></h4>
														<h5><b>Company: </b>Federal Bank </h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">13.58  Lakh</span></p>
													</div>
												</div>


											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img3} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Chandrama C.  Sonawane</b></h4>
														<h5><b>Company: </b>Federal Bank</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">13.58 Lakh</span></p>
													</div>
												</div>


											</div>

										</div>

										<div className="row">
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img4} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Krutil Kailas Patel</b></h4>
														<h5><b>Company: </b>Markets & Markets</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">10.07  Lakh</span></p>
													</div>
												</div>



											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img5} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Aditya Sanjay Digraskar</b></h4>
														<h5><b>Company: </b>HDFC Bank Ltd. </h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">8.64  Lakh</span></p>
													</div>
												</div>


											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img6} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Amit C. Agarwal</b></h4>
														<h5><b>Company: </b>  HDFC Bank Ltd</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">  8.64 Lakh</span></p>
													</div>
												</div>


											</div>

										</div>

										<div className="row">

											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img7} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Anurag Mohan Goud Garugula</b></h4>
														<h5><b>Company: </b>HDFC Bank Ltd.</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">8.64  Lakh</span></p>
													</div>
												</div>



											</div>

											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img8} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Bhushan R. Ahire</b></h4>
														<h5><b>Company: </b>HDFC Bank Ltd.</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">8.64  Lakh</span></p>
													</div>
												</div>



											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img9} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Harshad R. Wankhade</b></h4>
														<h5><b>Company: </b>Writer Corporation</h5>
														<p><b>Stream: </b>MBA(Operation)</p>
														<p><b>Package: </b><span className="packageNew"> 9.5  Lakh</span></p>
													</div>
												</div>


											</div>




										</div>

										<div className="row">
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img10} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Chetan Vijay Masram</b></h4>
														<h5><b>Company: </b>Bonito Design</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">9.45  Lakh</span></p>
													</div>
												</div>


											</div>
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img11} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Mayur Dilip Chaudhari</b></h4>
														<h5><b>Company: </b>Bonito Design</h5>
														<p><b>Stream: </b> MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">9.45  Lakh</span></p>
													</div>
												</div>



											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img12} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Saurabh B. Panade   </b></h4>
														<h5><b>Company: </b>Bonito Design </h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">9.45  Lakh</span></p>
													</div>
												</div>


											</div>




										</div>

										<div className="row">
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img13} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Shruti M. Awaskar</b></h4>
														<h5><b>Company: </b>Bonito Design</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">9.45  Lakh</span></p>
													</div>
												</div>


											</div>
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img14} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Bhagyashree D Sawarkar  </b></h4>
														<h5><b>Company: </b> :  HDFC Bank Ltd</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">8.64 Lakh</span></p>
													</div>
												</div>



											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student21_23_Img15} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Chintan Prashant Wagh</b></h4>
														<h5><b>Company: </b>HDFC Bank Ltd </h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">8.64  Lakh</span></p>
													</div>
												</div>


											</div>




										</div>

									</div>

									<div className="col-md-12 marginBottom3em">
										<h3 className="blockTitle text-center">Placed Students of Batch 2021-2023 </h3>
										<Table class="table table-responsive table-bordered" responsive>
											<tbody>
												<tr>
													<td ><b>Sr No.</b></td>
													<td ><b>Students Name</b></td>
													<td ><b>Name of the Company</b></td>
													<td ><b>Specialization(Major)</b></td>
													<td ><b>Package in LPA INR</b></td>
												</tr>
												<tr>
													<td>1</td>
													<td>Amruta Sanjay Khadke</td>
													<td>Federal bank</td>
													<td>Finance</td>
													<td>13.58</td>
												</tr>
												<tr>
													<td>2</td>
													<td>Ankita Ramesh Ahire</td>
													<td>Federal bank</td>
													<td>Finance</td>
													<td>13.58</td>
												</tr>
												<tr>
													<td>3</td>
													<td>Chandrama Chandrashekhar Sonawane</td>
													<td>Federal bank</td>
													<td>Finance</td>
													<td>13.58</td>
												</tr>
												<tr>
													<td>4</td>
													<td>Krutil Kailas Patel</td>
													<td>Markets & Markets</td>
													<td>Marketing</td>
													<td>10.07</td>
												</tr>
												<tr>
													<td>5</td>
													<td>Harshad Rameshrao Wankhade</td>
													<td>Writer Corporation </td>
													<td>Operation</td>
													<td>9.5</td>
												</tr>
												<tr>
													<td>6</td>
													<td>Chetan Vijay Masram</td>
													<td>Bonito Design</td>
													<td>Marketing</td>
													<td>9.45</td>
												</tr>
												<tr>
													<td>7</td>
													<td>Mayur Dilip Chaudhari</td>
													<td>Bonito Design</td>
													<td>Marketing</td>
													<td>9.45</td>
												</tr>
												<tr>
													<td>8</td>
													<td>Saurabh Bhimrao Panade</td>
													<td>Bonito Design</td>
													<td>Marketing</td>
													<td>9.45</td>
												</tr>
												<tr>
													<td>9</td>
													<td>Shruti Machindra Awaskar</td>
													<td>Bonito Design</td>
													<td>Marketing</td>
													<td>9.45</td>
												</tr>
												<tr>
													<td>10</td>
													<td>Aditya Sanjay Digraskar</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>11</td>
													<td>Amit Chhailbihari Agarwal</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>12</td>
													<td>Anurag Mohan Goud Garugula</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>13</td>
													<td>Bhagyashree Devidas Sawarkar</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>14</td>
													<td>Bhushan Ramchandra Ahire</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>15</td>
													<td>Chintan Prashant Wagh</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>16</td>
													<td>Gayatri Govardhan Musande</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>17</td>
													<td>Hrishikesh Dattatray Kotlapure</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>18</td>
													<td>Jayesh Diliprao Pawar</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>19</td>
													<td>Kirtee Mallikarjun Kore</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>20</td>
													<td>Muskan Kukreja</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>21</td>
													<td>Pranav Vilas Shewale</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>22</td>
													<td>Rahul Digambar Idhate</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>23</td>
													<td>Riya Modi</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>24</td>
													<td>Sakshi Kailas Sarda</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>25</td>
													<td>Saurabh Ashok Singh</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>26</td>
													<td>Shamal Pramod Kakad</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>27</td>
													<td>Shrikant Gulabrao Khond</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>28</td>
													<td>Sneha Santosh Dodekar</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>29</td>
													<td>Yash Mahendra</td>
													<td>HDFC Bank Ltd</td>
													<td>Finance</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>30</td>
													<td>Sandip Dilip Jadhav</td>
													<td>HDFC Bank Ltd</td>
													<td>Marketing</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>31</td>
													<td>Siddhesh Sanjay Magar</td>
													<td>HDFC Bank Ltd</td>
													<td>Marketing</td>
													<td>8.64</td>
												</tr>
												<tr>
													<td>32</td>
													<td>Harshal Vilas Patil</td>
													<td>Prime Assets Realty Pvt. Ltd.</td>
													<td>Marketing</td>
													<td>8.12</td>
												</tr>
												<tr>
													<td>33</td>
													<td>Sagar Devidas Dhule</td>
													<td>ANZ Bank </td>
													<td>Finance</td>
													<td>8</td>
												</tr>
												<tr>
													<td>34</td>
													<td>Abhishek Sanjay Rajguru</td>
													<td>Nihilent Technologies</td>
													<td>Marketing</td>
													<td>8</td>
												</tr>
												<tr>
													<td>35</td>
													<td>Mayuri Prashant Waichal</td>
													<td>Nelsen IQ</td>
													<td>Marketing</td>
													<td>8</td>
												</tr>
												<tr>
													<td>36</td>
													<td>Parag Mahendra Mehta</td>
													<td>Nihilent Technologies</td>
													<td>Marketing</td>
													<td>8</td>
												</tr>
												<tr>
													<td>37</td>
													<td>Divya Kishor Kabra</td>
													<td>Deloitte (US Taxation)</td>
													<td>Finance</td>
													<td>7.6</td>
												</tr>
												<tr>
													<td>38</td>
													<td>Riya Rajkumar Soni</td>
													<td>Deloitte (US Taxation)</td>
													<td>Finance</td>
													<td>7.6</td>
												</tr>
												<tr>
													<td>39</td>
													<td>Suchita Pravin Patil</td>
													<td>Deloitte (US Taxation)</td>
													<td>Finance</td>
													<td>7.6</td>
												</tr>
												<tr>
													<td>40</td>
													<td>Abhishek Balasaheb Kalaskar</td>
													<td>Trinamix</td>
													<td>Operation</td>
													<td>7.5</td>
												</tr>
												<tr>
													<td>41</td>
													<td>Simron Birendra Sipani</td>
													<td>Aditya Birla Group</td>
													<td>Marketing</td>
													<td>7.5</td>
												</tr>
												<tr>
													<td>42</td>
													<td>Vikas Tatyaso Pawar</td>
													<td>Aditya Birla Group</td>
													<td>Marketing</td>
													<td>7.5</td>
												</tr>
												<tr>
													<td>43</td>
													<td>Abhishek Anil Patil</td>
													<td>UTI AMC Ltd</td>
													<td>Finance</td>
													<td>7.46</td>
												</tr>
												<tr>
													<td>44</td>
													<td>Ashwin Gajanan Gole</td>
													<td>HDFC Bank Ltd</td>
													<td>Marketing</td>
													<td>7.46</td>
												</tr>
												<tr>
													<td>45</td>
													<td>Atit Babarao Herole</td>
													<td>HDFC Bank Ltd</td>
													<td>Marketing</td>
													<td>7.46</td>
												</tr>
												<tr>
													<td>46</td>
													<td>Kishan Mahesh Mundhada</td>
													<td>HDFC Bank Ltd</td>
													<td>Marketing</td>
													<td>7.46</td>
												</tr>
												<tr>
													<td>47</td>
													<td>Satya Prakash Mishra</td>
													<td>HDFC Bank Ltd </td>
													<td>Marketing</td>
													<td>7.46</td>
												</tr>
												<tr>
													<td>48</td>
													<td>Shreyash Rajan Humne</td>
													<td>HDFC Bank Ltd </td>
													<td>Marketing</td>
													<td>7.46</td>
												</tr>
												<tr>
													<td>49</td>
													<td>Shreyas Vijaykumar Pardeshi</td>
													<td>HDFC Bank Ltd </td>
													<td>Marketing</td>
													<td>7.46</td>
												</tr>
												<tr>
													<td>50</td>
													<td>Tejashri Vijay Deore</td>
													<td>HDFC Bank Ltd </td>
													<td>Marketing</td>
													<td>7.46</td>
												</tr>
												<tr>
													<td>51</td>
													<td>Akash Rajwant Sharma</td>
													<td>Asian paints Ltd. </td>
													<td>Marketing</td>
													<td>7.35</td>
												</tr>
												<tr>
													<td>52</td>
													<td>Aditi Pandurang Deotkar</td>
													<td>TATA Bluescope Steel Pvt. Ltd.</td>
													<td>Marketing</td>
													<td>7.16</td>
												</tr>
												<tr>
													<td>53</td>
													<td>Mugdha Jayant Kulkarni</td>
													<td>Prism Johnson Ltd.</td>
													<td>HR</td>
													<td>7</td>
												</tr>
												<tr>
													<td>54</td>
													<td>Shrisha Ramkishan Tatikundalwar</td>
													<td>Ptechnosoft</td>
													<td>Marketing</td>
													<td>7</td>
												</tr>
												<tr>
													<td>55</td>
													<td>Vaishanvi Nitin Wani</td>
													<td>Nivea</td>
													<td>Marketing</td>
													<td>7</td>
												</tr>
												<tr>
													<td>56</td>
													<td>Omkar Rajesh Batwe</td>
													<td>Kaizen Institute</td>
													<td>Operation</td>
													<td>6.96</td>
												</tr>
												<tr>
													<td>57</td>
													<td>Aditya Mahadeo Daware</td>
													<td>Nestle</td>
													<td>Marketing</td>
													<td>6.9</td>
												</tr>
												<tr>
													<td>58</td>
													<td>Aishwarya Kishor Raut</td>
													<td>Xanadu Realty</td>
													<td>Marketing</td>
													<td>6.82</td>
												</tr>
												<tr>
													<td>59</td>
													<td>Bhalchandra Piraji Jawadwad</td>
													<td>Xanadu Realty</td>
													<td>Marketing</td>
													<td>6.82</td>
												</tr>
												<tr>
													<td>60</td>
													<td>Trushita Virendrakumar Katre</td>
													<td>TATA Bluescope Steel Pvt. Ltd.</td>
													<td>Marketing</td>
													<td>6.8</td>
												</tr>
												<tr>
													<td>61</td>
													<td>Samarpreet Singh Saluja</td>
													<td>Grand View Research</td>
													<td>Marketing</td>
													<td>6.57</td>
												</tr>
												<tr>
													<td>62</td>
													<td>Shubham Satish Wadnere</td>
													<td>Grand View Research</td>
													<td>Marketing</td>
													<td>6.57</td>
												</tr>
												<tr>
													<td>63</td>
													<td>Sirisha Venkatrao Mandhadi</td>
													<td>KPIT</td>
													<td>HR</td>
													<td>6.5</td>
												</tr>
												<tr>
													<td>64</td>
													<td>Gaurav Nanasaheb Chindhe</td>
													<td>Billion Base Camp</td>
													<td>Marketing</td>
													<td>6.5</td>
												</tr>
												<tr>
													<td>65</td>
													<td>Nehal kumar</td>
													<td>Hindware</td>
													<td>Marketing</td>
													<td>6.5</td>
												</tr>
												<tr>
													<td>66</td>
													<td>Shreyas Gopal Chaturbhuj</td>
													<td>Hindware</td>
													<td>Marketing</td>
													<td>6.5</td>
												</tr>
												<tr>
													<td>67</td>
													<td>Hemant Hiralal Kamble</td>
													<td>Vodaphone Idea Ltd.</td>
													<td>Marketing</td>
													<td>6.3</td>
												</tr>
												<tr>
													<td>68</td>
													<td>Palash Bandu Khobragade</td>
													<td>Saint-Gobain</td>
													<td>Marketing</td>
													<td>6.2</td>
												</tr>
												<tr>
													<td>69</td>
													<td>Pawan Suresh Bhatkulkar</td>
													<td>Veracity Investment Management</td>
													<td>Finance</td>
													<td>6.16</td>
												</tr>
												<tr>
													<td>70</td>
													<td>Adarsh Kumar Jha</td>
													<td>ANZ Bank </td>
													<td>Finance</td>
													<td>6</td>
												</tr>
												<tr>
													<td>71</td>
													<td>Dhiraj Bhausaheb Patil</td>
													<td>Kalolwala & Associates Pvt. Ltd.  </td>
													<td>Finance</td>
													<td>6</td>
												</tr>
												<tr>
													<td>72</td>
													<td>Juhi Mahesh Vachhani</td>
													<td>ANZ Bank </td>
													<td>Finance</td>
													<td>6</td>
												</tr>
												<tr>
													<td>73</td>
													<td>Vishal Laxman Tanpure</td>
													<td>ANZ Bank </td>
													<td>Finance</td>
													<td>6</td>
												</tr>
												<tr>
													<td>74</td>
													<td>Anurag Balasaheb Abnave</td>
													<td>PWC</td>
													<td>Operation</td>
													<td>6</td>
												</tr>
												<tr>
													<td>75</td>
													<td>Meghna Pramod Adhaoo</td>
													<td>Mahindra Logistics </td>
													<td>Operation</td>
													<td>6</td>
												</tr>
												<tr>
													<td>76</td>
													<td>Omkar Sharad Gurav</td>
													<td>Reliance Retail </td>
													<td>Operation</td>
													<td>6</td>
												</tr>
												<tr>
													<td>77</td>
													<td>Yogeshwar Sanjay Nighojkar</td>
													<td>Mahindra Logistics </td>
													<td>Operation</td>
													<td>6</td>
												</tr>
												<tr>
													<td>78</td>
													<td>Padmaja Bhanuprakash Patil</td>
													<td>Reliance Retail </td>
													<td>HR</td>
													<td>6</td>
												</tr>
												<tr>
													<td>79</td>
													<td>Priyadarshni Ramesh Kamble</td>
													<td>Bike Bazaar Finance</td>
													<td>HR</td>
													<td>6</td>
												</tr>
												<tr>
													<td>80</td>
													<td>Amar Vasant Dadibude</td>
													<td>Reliance Retail</td>
													<td>Marketing</td>
													<td>6</td>
												</tr>
												<tr>
													<td>81</td>
													<td>Apeksha Madhav Joglekar</td>
													<td>Wipro Enterprises Pvt.Ltd</td>
													<td>Marketing</td>
													<td>6</td>
												</tr>
												<tr>
													<td>82</td>
													<td>Prasad Shivaji Nalawade</td>
													<td>Straits Research Pvt. Ltd.</td>
													<td>Marketing</td>
													<td>6</td>
												</tr>
												<tr>
													<td>83</td>
													<td>Rutuja Narayan Kale</td>
													<td>Straits Research Pvt. Ltd.</td>
													<td>Marketing</td>
													<td>6</td>
												</tr>
												<tr>
													<td>84</td>
													<td>Shivraj Jaganrao Sonawane</td>
													<td>Reliance Retail</td>
													<td>Marketing</td>
													<td>6</td>
												</tr>
												<tr>
													<td>85</td>
													<td>Shubham Bhalchandra Rajhans</td>
													<td>TATA Technology</td>
													<td>Finance</td>
													<td>5.9</td>
												</tr>
												<tr>
													<td>86</td>
													<td>Piyusha Nitin Patil</td>
													<td>TCS</td>
													<td>HR</td>
													<td>5.75</td>
												</tr>
												<tr>
													<td>87</td>
													<td>Kumar Satish Motagi</td>
													<td>Zydus Wellness</td>
													<td>Marketing</td>
													<td>5.5</td>
												</tr>
												<tr>
													<td>88</td>
													<td>Shweta Ravikant Narwade</td>
													<td>Tech Mahindra</td>
													<td>Marketing</td>
													<td>5.5</td>
												</tr>
												<tr>
													<td>89</td>
													<td>Vaidehi Sudhir Kulkarni</td>
													<td>Veracity Investment Management</td>
													<td>Finance</td>
													<td>5.32</td>
												</tr>
												<tr>
													<td>90</td>
													<td>Avadhut Shashikant Mahamuni</td>
													<td>Global Market Insights</td>
													<td>Marketing</td>
													<td>5.28</td>
												</tr>
												<tr>
													<td>91</td>
													<td>Gauri Pavan Dabi</td>
													<td>Global Market Insights</td>
													<td>Marketing</td>
													<td>5.28</td>
												</tr>
												<tr>
													<td>92</td>
													<td>Saurabh Abhayrao Ambekar</td>
													<td>Global Market Insights</td>
													<td>Marketing</td>
													<td>5.28</td>
												</tr>
												<tr>
													<td>93</td>
													<td>Onkar Dattatray Gandule</td>
													<td>Acuity Knowledge Partners</td>
													<td>Finance</td>
													<td>5.16</td>
												</tr>
												<tr>
													<td>94</td>
													<td>Prachi Ganesh Bhavsar</td>
													<td>Acuity Knowledge Partners</td>
													<td>Finance</td>
													<td>5.16</td>
												</tr>
												<tr>
													<td>95</td>
													<td>Saloni Mahendra Bhalerao</td>
													<td>AU Small Finance Bank Limited</td>
													<td>Finance</td>
													<td>5</td>
												</tr>
												<tr>
													<td>96</td>
													<td>Pratik Prabhakar Patil</td>
													<td>MSKH Seatings India Pvt. Ltd.</td>
													<td>Operation</td>
													<td>5</td>
												</tr>
												<tr>
													<td>97</td>
													<td>Sagar Sunil Kasar</td>
													<td>e-Emphasys </td>
													<td>Operation</td>
													<td>5</td>
												</tr>
												<tr>
													<td>98</td>
													<td>Prashant Kiran Gadhari</td>
													<td>Indiamart</td>
													<td>Marketing</td>
													<td>5</td>
												</tr>
												<tr>
													<td>99</td>
													<td>Manisha Amit Dey</td>
													<td>Pinnacle industries ltd</td>
													<td>Marketing</td>
													<td>4.8</td>
												</tr>
												<tr>
													<td>100</td>
													<td>Nikita Vaman Bhoomkar</td>
													<td>S&P Global</td>
													<td>Finance</td>
													<td>4.75</td>
												</tr>
												<tr>
													<td>101</td>
													<td>Abhijeet Sanjaykumar Phadnis</td>
													<td>Kotak Mahindra Bank</td>
													<td>Finance</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>102</td>
													<td>Aditya Milind Borse</td>
													<td>Axis Bank</td>
													<td>Finance</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>103</td>
													<td>Ashish Suryakant Aher</td>
													<td>Ujjivan Small Finance Bank</td>
													<td>Finance</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>104</td>
													<td>Harshal Devidasrao Tembhe</td>
													<td>Kotak Mahindra Bank</td>
													<td>Finance</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>105</td>
													<td>Hitesh Narayan Desale</td>
													<td>Kotak Mahindra Bank</td>
													<td>Finance</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>106</td>
													<td>Janhavi Sunil Raut</td>
													<td>Kotak Mahindra Bank</td>
													<td>Finance</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>107</td>
													<td>Lalit Magan Ahire</td>
													<td>Kotak Mahindra Bank</td>
													<td>Finance</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>108</td>
													<td>Prafull Rajendra Deokar</td>
													<td>LTI Mindtree</td>
													<td>Finance</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>109</td>
													<td>Raj Sharad Panpaliya</td>
													<td>Kotak Mahindra Bank</td>
													<td>Finance</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>110</td>
													<td>Rutuja Rajesh Deshmukh</td>
													<td>Kotak Mahindra bank</td>
													<td>Finance</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>111</td>
													<td>Swarad Anand Deo</td>
													<td>LTI Mindtree</td>
													<td>Finance</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>112</td>
													<td>Shweta Sanjay Kirtane</td>
													<td>LTI Mindtree</td>
													<td>HR</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>113</td>
													<td>Trupti Subhashrao Deshmukh</td>
													<td>LTI Mindtree</td>
													<td>HR</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>114</td>
													<td>Ashwin Pradip Pawar</td>
													<td>Kolte Patil Developers Ltd</td>
													<td>Marketing</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>115</td>
													<td>Gaurav Hirachand Pawar</td>
													<td>Kotak Mahindra Bank </td>
													<td>Marketing</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>116</td>
													<td>Pratham Harish Joshi</td>
													<td>Kotak Mahindra Bank </td>
													<td>Marketing</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>117</td>
													<td>Rishab Biswas</td>
													<td>Kolte Patil Developers Ltd</td>
													<td>Marketing</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>118</td>
													<td>Shantanu Mansing Kate</td>
													<td>Kolte Patil Developers</td>
													<td>Marketing</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>119</td>
													<td>Vikrant Kalyan Jadhav</td>
													<td>Kotak Mahindra Bank</td>
													<td>Marketing</td>
													<td>4.5</td>
												</tr>
												<tr>
													<td>120</td>
													<td>Tejaswini Venktesh Padalwar</td>
													<td>EY(Ernst & Young)</td>
													<td>Finance</td>
													<td>4.46</td>
												</tr>
												<tr>
													<td>121</td>
													<td>Rahul Gautam Durge</td>
													<td>The Insight Partners</td>
													<td>Marketing</td>
													<td>4.4</td>
												</tr>
												<tr>
													<td>122</td>
													<td>Pritam Santosh Hanche</td>
													<td>TATA AIG Generel Insurance</td>
													<td>Finance</td>
													<td>3.25</td>
												</tr>
												<tr>
													<td>123</td>
													<td>Chetan Narendra Dhawale</td>
													<td>Au Small Finance Bank</td>
													<td>Marketing</td>
													<td>3</td>
												</tr>
												<tr>
													<td>124</td>
													<td>Aditya Sanjay Rayrikar</td>
													<td>Jaguar land rover</td>
													<td>Marketing</td>
													<td>2.4</td>
												</tr>
												<tr>
													<td>125</td>
													<td>Jitendra Pomaram Choudhary</td>
													<td>Entrepreneur</td>
													<td>Marketing</td>
													<td>-</td>
												</tr>

											</tbody>
										</Table>
									</div>


								</div>
							</div>}
						{Placement2020 &&
							<div className="paddingLeft MobPlaceMarginTop">
								<div className="col-md-12 padding15px whiteBackground">
									<h3 className="blockTitle">HOW INDIRA SCHOOL OF BUSINESS STUDIES (ISBS) RANKS
										HIGH ON MBA 2020-2022 PLACEMENT</h3>
									<p>MBA courses at ISBS are nationally recognised and offer aspiring business professionals the right career platform to grow even
										in challenging times.</p>
									<div className="row">
										<div className="col-md-3 marginBottom3em">
											<div className="col-md-12 boxShadow padding15px borderRadius15  text-center minHeight335">
												{/* <p><b>MBA BATCH PROFILE</b></p>
												<div className="row">
													<div className="col text-center">
														<p>Male</p>
														<img src={MaleImage} className="width80" />
														<div className="textColorBox1">
															71.91%
														</div>
													</div>
													<div className="col text-center">
														<p>Female</p>
														<img src={FemaleImage} className="width80" />
														<div className="textColorBox1">
															28.09%
														</div>
													</div>
												</div> */}
												<img src={Graph2020Profile} className="imgRes" />
											</div>
										</div>
										<div className="col-md-9 marginBottom3em">
											<div className="col-md-12 boxShadow padding15px borderRadius15 text-center minHeight335 mobHeightAuto">

												<img src={Graph2020Package} className="width80" />
											</div>
										</div>

										<div className="col-md-2"></div>
										<div className="col-md-8 boxShadow padding15px borderRadius15 text-center minHeight335 mobHeightAuto">
											<img src={Graph2020Employment} className="imgRes" />
										</div>
										<div className="col-md-2">

										</div>
									</div>
									<div className="col-md-12 marginBottom3em">
										<h3 className="blockTitle text-center">Top Placed Students Batch 2020-22 </h3>
										<div className="row">
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img1} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Naysaa Ahmed </b></h4>
														<h5><b>Company: </b>Alight Solution</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">12 Lakh</span></p>
													</div>
												</div>



											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img2} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Nupoor Balasaheb Tile</b></h4>
														<h5><b>Company: </b>Skyhigh Security</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew"> 12 Lakh</span></p>
													</div>
												</div>


											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img3} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Aarya Wadkar</b></h4>
														<h5><b>Company: </b>Skyhigh Security</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">12 Lakh</span></p>
													</div>
												</div>


											</div>

										</div>

										<div className="row">



											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img4} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Prithviraj Shantilal Rathod </b></h4>
														<h5><b>Company: </b>Skyhigh Security </h5>
														<p><b>Stream: </b>MBA(Operation)</p>
														<p><b>Package: </b><span className="packageNew"> 11 Lakh</span></p>
													</div>
												</div>


											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img5} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Ashutosh Pagare</b></h4>
														<h5><b>Company: </b> NielsenIQ</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">  8 Lakh</span></p>
													</div>
												</div>


											</div>
											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img6} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Yashodeep Patil</b></h4>
														<h5><b>Company: </b>Bottomline</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew"> 09.89 Lakh</span></p>
													</div>
												</div>



											</div>

										</div>

										<div className="row">



											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img7} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Aditi sachin Gaidhani</b></h4>
														<h5><b>Company: </b>FIS Global</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">09.52 Lakh</span></p>
													</div>
												</div>



											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img8} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Pranav Prakash Alone</b></h4>
														<h5><b>Company: </b>Hashedin by Deloitte</h5>
														<p><b>Stream: </b>MBA(Operation)</p>
														<p><b>Package: </b><span className="packageNew"> 08.1 Lakh</span></p>
													</div>
												</div>


											</div>

											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img9} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Ajinkya Patil</b></h4>
														<h5><b>Company: </b>Hashedin by Deloitte</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">08.1 Lakh</span></p>
													</div>
												</div>


											</div>


										</div>

										<div className="row">

											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img10} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Soham Shinde</b></h4>
														<h5><b>Company: </b>Hashedin by Deloitte</h5>
														<p><b>Stream: </b> MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew"> 08.1   Lakh</span></p>
													</div>
												</div>



											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img11} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Geetesh Jadhav </b></h4>
														<h5><b>Company: </b> Hashedin by Deloitte</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew"> 08.1 Lakh</span></p>
													</div>
												</div>


											</div>

											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img12} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Kshitij Dhanvijay</b></h4>
														<h5><b>Company: </b>Hashedin by Deloitte</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">08.1 Lakh</span></p>
													</div>
												</div>


											</div>


										</div>

										<div className="row">

											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img13} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Pooja Patil</b></h4>
														<h5><b>Company: </b> : Hashedin by Deloitte</h5>
														<p><b>Stream: </b>MBA(Finance)</p>
														<p><b>Package: </b><span className="packageNew">08.1  Lakh</span></p>
													</div>
												</div>



											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img14} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Sneha Chhayala  </b></h4>
														<h5><b>Company: </b>NielsenIQ  </h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">8  Lakh</span></p>
													</div>
												</div>


											</div>


											<div className="faculty-col col-md-4 col-12">
												<div className="row">
													<div className="col-md-4 col-4">
														<img src={student20_22_Img15} alt="" className="faculty-img" width="100%" />
													</div>
													<div className="faculty-name col-md-8 col-8 ">
														<h4><b>Kalpesh Dilip Patil  </b></h4>
														<h5><b>Company: </b> NielsenIQ</h5>
														<p><b>Stream: </b>MBA(Marketing)</p>
														<p><b>Package: </b><span className="packageNew">8   Lakh</span></p>
													</div>
												</div>


											</div>

										</div>

									</div>

									<div className="col-md-12 marginBottom3em">
										<h3 className="blockTitle text-center">Placed Students of Batch 2020-22 </h3>
										<Table class="table table-responsive table-bordered" responsive>

											<tbody>
												<tr>
													<td><b>Sr No.</b></td>
													<td><b>Students Name</b></td>
													<td><b>Name of the Company</b></td>
													<td><b>Specialization(Major)</b></td>
													<td><b>Package in LPA INR</b></td>
												</tr>
												<tr>
													<td >1</td>
													<td >Naysaa Ahmed</td>
													<td >Alight Solution</td>
													<td >Marketing</td>
													<td >12</td>
												</tr>
												<tr>
													<td >2</td>
													<td >Nupoor Balasaheb Tile</td>
													<td >Skyhigh Security </td>
													<td >Marketing</td>
													<td >12</td>
												</tr>
												<tr>
													<td >3</td>
													<td >Aarya Wadkar</td>
													<td >Skyhigh Security </td>
													<td >Marketing</td>
													<td >12</td>
												</tr>
												<tr>
													<td >4</td>
													<td >Prithviraj Shantilal Rathod</td>
													<td >Skyhigh Securities</td>
													<td >Operation</td>
													<td >11</td>
												</tr>
												<tr>
													<td >5</td>
													<td >Ashutosh Pagare</td>
													<td >XpressBees</td>
													<td >Operation</td>
													<td >11</td>
												</tr>
												<tr>
													<td >6</td>
													<td >Yashodeep Patil</td>
													<td >Bottomline</td>
													<td >Finance</td>
													<td >9.89</td>
												</tr>
												<tr>
													<td >7</td>
													<td >Aditi sachin Gaidhani</td>
													<td >FIS Global</td>
													<td >Finance</td>
													<td >9.52</td>
												</tr>
												<tr>
													<td >8</td>
													<td >Pranav Prakash Alone</td>
													<td >Hashedin by Deloitte</td>
													<td >Operation</td>
													<td >8.1</td>
												</tr>
												<tr>
													<td >9</td>
													<td >Ajinkya Patil</td>
													<td >Hashedin by Deloitte</td>
													<td >Finance</td>
													<td >8.1</td>
												</tr>
												<tr>
													<td >10</td>
													<td >Soham Shinde</td>
													<td >Hashedin by Deloitte</td>
													<td >Marketing</td>
													<td >8.1</td>
												</tr>
												<tr>
													<td >11</td>
													<td >Geetesh Jadhav</td>
													<td >Hashedin by Deloitte</td>
													<td >Marketing</td>
													<td >8.1</td>
												</tr>
												<tr>
													<td >12</td>
													<td >Kshitij Dhanvijay</td>
													<td >Hashedin by Deloitte</td>
													<td >Marketing</td>
													<td >8.1</td>
												</tr>
												<tr>
													<td >13</td>
													<td >Pooja Patil</td>
													<td >Hashedin by Deloitte</td>
													<td >Finance</td>
													<td >8.1</td>
												</tr>
												<tr>
													<td >14</td>
													<td >Sneha Chhayala</td>
													<td >NielsenIQ</td>
													<td >Marketing</td>
													<td >8</td>
												</tr>
												<tr>
													<td >15</td>
													<td >Kalpesh Dilip Patil</td>
													<td >NielsenIQ</td>
													<td >Marketing</td>
													<td >8</td>
												</tr>
												<tr>
													<td >16</td>
													<td >Pratik Arvind Chaudhari</td>
													<td >NielsenIQ</td>
													<td >Marketing</td>
													<td >8</td>
												</tr>
												<tr>
													<td >17</td>
													<td >Sanket Sonawane</td>
													<td >NielsenIQ</td>
													<td >Marketing</td>
													<td >8</td>
												</tr>
												<tr>
													<td >18</td>
													<td >Sangram Sanjay Shinde</td>
													<td >NielsenIQ</td>
													<td >Marketing</td>
													<td >8</td>
												</tr>
												<tr>
													<td >19</td>
													<td >Aishwarya Laxmarao Chate</td>
													<td >NielsenIQ</td>
													<td >Marketing</td>
													<td >8</td>
												</tr>
												<tr>
													<td >20</td>
													<td >Gokul Devising Rathod</td>
													<td >NielsenIQ</td>
													<td >Marketing</td>
													<td >8</td>
												</tr>
												<tr>
													<td >21</td>
													<td >Mayur Babaladi</td>
													<td >NielsenIQ</td>
													<td >Marketing</td>
													<td >8</td>
												</tr>
												<tr>
													<td >22</td>
													<td >Shubham Kinge</td>
													<td >Deloitte(RFA)</td>
													<td >Marketing</td>
													<td >7.87</td>
												</tr>
												<tr>
													<td >23</td>
													<td >Bhavesh Bele</td>
													<td >Deloitte(RFA)</td>
													<td >Finance</td>
													<td >7.87</td>
												</tr>
												<tr>
													<td >24</td>
													<td >Neha Khandhar</td>
													<td >NielsenIQ</td>
													<td >Marketing</td>
													<td >7.75</td>
												</tr>
												<tr>
													<td >25</td>
													<td >Smruti Lachake</td>
													<td >Deloitte(KS)</td>
													<td >HR</td>
													<td >7.6</td>
												</tr>
												<tr>
													<td >26</td>
													<td >Anirudha Vispute</td>
													<td >Deloitte(US Tax)</td>
													<td >Finance</td>
													<td >7.6</td>
												</tr>
												<tr>
													<td >27</td>
													<td >Manya Mishra</td>
													<td >Deloitte(US Tax)</td>
													<td >Finance</td>
													<td >7.6</td>
												</tr>
												<tr>
													<td >28</td>
													<td >Shreya Bhosale</td>
													<td >Deloitte(US Tax)</td>
													<td >Finance</td>
													<td >7.6</td>
												</tr>
												<tr>
													<td >29</td>
													<td >Chinmay Sontake</td>
													<td >Deloitte(US Tax)</td>
													<td >Finance</td>
													<td >7.6</td>
												</tr>
												<tr>
													<td >30</td>
													<td >Prashant Razdan</td>
													<td >Makebot</td>
													<td >Marketing</td>
													<td >7</td>
												</tr>
												<tr>
													<td >31</td>
													<td >samiksha Shirsat</td>
													<td >Makebot</td>
													<td >Marketing</td>
													<td >7</td>
												</tr>
												<tr>
													<td >32</td>
													<td >Disha Oswal</td>
													<td >HDFC Ltd.</td>
													<td >Marketing</td>
													<td >6.9</td>
												</tr>
												<tr>
													<td >33</td>
													<td >Ruchita Wankhede</td>
													<td >HDFC Ltd.</td>
													<td >Finance</td>
													<td >6.9</td>
												</tr>
												<tr>
													<td >34</td>
													<td >Suraj Pandit Shinde</td>
													<td >HDFC Bank</td>
													<td >Marketing</td>
													<td >6.75</td>
												</tr>
												<tr>
													<td >35</td>
													<td >Rohit Sanjay Markad</td>
													<td >HDFC Bank</td>
													<td >Marketing</td>
													<td >6.75</td>
												</tr>
												<tr>
													<td >36</td>
													<td >Pavan Lokhande</td>
													<td >HDFC Bank</td>
													<td >Marketing</td>
													<td >6.75</td>
												</tr>
												<tr>
													<td >37</td>
													<td >Nandkishor Solanke</td>
													<td >HDFC Bank</td>
													<td >Finance</td>
													<td >6.75</td>
												</tr>
												<tr>
													<td >38</td>
													<td >Pratiksha Deshmukh</td>
													<td >HDFC Bank</td>
													<td >Finance</td>
													<td >6.75</td>
												</tr>
												<tr>
													<td >39</td>
													<td >Vishant Jadhav</td>
													<td >HDFC Bank</td>
													<td >Finance</td>
													<td >6.75</td>
												</tr>
												<tr>
													<td >40</td>
													<td >Janmejai Singh</td>
													<td >HDFC Bank</td>
													<td >Finance</td>
													<td >6.57</td>
												</tr>
												<tr>
													<td >41</td>
													<td >Saket Anil More</td>
													<td >HDFC Bank</td>
													<td >Finance</td>
													<td >6.57</td>
												</tr>
												<tr>
													<td >42</td>
													<td >Shyam Bedre</td>
													<td >Eclerx</td>
													<td >Finance</td>
													<td >6.53</td>
												</tr>
												<tr>
													<td >43</td>
													<td >Akshay Sahare</td>
													<td >Tata Croma (Infiniti Retail Limited) </td>
													<td >Operation</td>
													<td >6.5</td>
												</tr>
												<tr>
													<td >44</td>
													<td >Suyasha Hole</td>
													<td >Kantar Global Delivery Center</td>
													<td >Marketing</td>
													<td >6.5</td>
												</tr>
												<tr>
													<td >45</td>
													<td >Rachit Shah</td>
													<td >HDFC Ltd.</td>
													<td >Finance</td>
													<td >6.4</td>
												</tr>
												<tr>
													<td >46</td>
													<td >Sumit Deshmukh</td>
													<td >Trinamix</td>
													<td >Operation</td>
													<td >6</td>
												</tr>
												<tr>
													<td >47</td>
													<td >Evangeline Jebraj</td>
													<td >Catenon</td>
													<td >HR</td>
													<td >6</td>
												</tr>
												<tr>
													<td >48</td>
													<td >Meet Rajesh Purohit</td>
													<td >Catenon</td>
													<td >Marketing</td>
													<td >6</td>
												</tr>
												<tr>
													<td >49</td>
													<td >Ritvik Rastogi</td>
													<td >Catenon</td>
													<td >Marketing</td>
													<td >6</td>
												</tr>
												<tr>
													<td >50</td>
													<td >Insha Khan</td>
													<td >TATA Technology</td>
													<td >HR</td>
													<td >6</td>
												</tr>
												<tr>
													<td >51</td>
													<td >Rajvardhan Jiwaji Mohite</td>
													<td >Grand View Research</td>
													<td >Marketing </td>
													<td >6</td>
												</tr>
												<tr>
													<td >52</td>
													<td >Devendra Mahankale</td>
													<td >BAGIC</td>
													<td >Marketing</td>
													<td >6</td>
												</tr>
												<tr>
													<td >53</td>
													<td >Swamini Kulkarni</td>
													<td >PWC</td>
													<td >Finance</td>
													<td >6</td>
												</tr>
												<tr>
													<td >54</td>
													<td >Shardul Sunil Gondhali</td>
													<td >TATA Technology</td>
													<td >Marketing</td>
													<td >5.9</td>
												</tr>
												<tr>
													<td >55</td>
													<td >Pallavi Priya</td>
													<td >Asian Paints</td>
													<td >Marketing</td>
													<td >5.8</td>
												</tr>
												<tr>
													<td >56</td>
													<td >Sanket Diliprao Kulkarni</td>
													<td >PhonePe</td>
													<td >Marketing</td>
													<td >5.8</td>
												</tr>
												<tr>
													<td >57</td>
													<td >Utkarsh Narendra Mhetre</td>
													<td >PhonePe</td>
													<td >Marketing</td>
													<td >5.8</td>
												</tr>
												<tr>
													<td >58</td>
													<td >Yash Subhash Salunkhe</td>
													<td >PhonePe</td>
													<td >Marketing</td>
													<td >5.8</td>
												</tr>
												<tr>
													<td >59</td>
													<td >Pratik Mahajan</td>
													<td >TCS</td>
													<td >Marketing</td>
													<td >5.79</td>
												</tr>
												<tr>
													<td >60</td>
													<td >Shruti Supare</td>
													<td >TATA Technology</td>
													<td >Marketing</td>
													<td >5.75</td>
												</tr>
												<tr>
													<td >61</td>
													<td >Shubham Kandalkar</td>
													<td >TATA Technology</td>
													<td >Operation</td>
													<td >5.75</td>
												</tr>
												<tr>
													<td >62</td>
													<td >Piyush Warulkar</td>
													<td >Berger Paints</td>
													<td >Marketing</td>
													<td >5.75</td>
												</tr>
												<tr>
													<td >63</td>
													<td >Jatin Singhal</td>
													<td >PWC</td>
													<td >Finance</td>
													<td >5.52</td>
												</tr>
												<tr>
													<td >64</td>
													<td >Nikhita Somshekhar Rudagi</td>
													<td >PWC</td>
													<td >Finance</td>
													<td >5.52</td>
												</tr>
												<tr>
													<td >65</td>
													<td >Anjana</td>
													<td >PWC</td>
													<td >Finance</td>
													<td >5.52</td>
												</tr>
												<tr>
													<td >66</td>
													<td >SHIVANI SHINDE</td>
													<td >PWC</td>
													<td >Finance</td>
													<td >5.52</td>
												</tr>
												<tr>
													<td >67</td>
													<td >Anjali Mungase</td>
													<td >Infosys</td>
													<td >Finance</td>
													<td >5.5</td>
												</tr>
												<tr>
													<td >68</td>
													<td >Shubham Sharnagat</td>
													<td >Amura Marketing</td>
													<td >Marketing</td>
													<td >5</td>
												</tr>
												<tr>
													<td >69</td>
													<td >Apoorva Jadhav</td>
													<td >TechAim</td>
													<td >HR</td>
													<td >5</td>
												</tr>
												<tr>
													<td >70</td>
													<td >Moreshwar Dhonge</td>
													<td >IndiaMart</td>
													<td >Marketing</td>
													<td >5</td>
												</tr>
												<tr>
													<td >71</td>
													<td >Rahul Dubey</td>
													<td >IndiaMart</td>
													<td >Finance</td>
													<td >5</td>
												</tr>
												<tr>
													<td >72</td>
													<td >Mayur Pawar</td>
													<td >Kalyani</td>
													<td >Operation</td>
													<td >5</td>
												</tr>
												<tr>
													<td >73</td>
													<td >Akshay Pandhare</td>
													<td >TK Elevator</td>
													<td >Operation</td>
													<td >5</td>
												</tr>
												<tr>
													<td >74</td>
													<td >Mohit Ulhe</td>
													<td >Anand Rathi</td>
													<td >Finance</td>
													<td >5</td>
												</tr>
												<tr>
													<td >75</td>
													<td >Akashkumar Nawalkar</td>
													<td >Anand Rathi</td>
													<td >Finance</td>
													<td >5</td>
												</tr>
												<tr>
													<td >76</td>
													<td >Ayushi Taksale</td>
													<td >Global Market Insights</td>
													<td >Marketing</td>
													<td >5</td>
												</tr>
												<tr>
													<td >77</td>
													<td >Laxman Kulkarni</td>
													<td >Global Market Insights</td>
													<td >Marketing</td>
													<td >5</td>
												</tr>
												<tr>
													<td >78</td>
													<td >Sameer Shejwal</td>
													<td >Global Market Insights</td>
													<td >Marketing</td>
													<td >5</td>
												</tr>
												<tr>
													<td >79</td>
													<td >Sayali Dhumale</td>
													<td >Reliance Retail</td>
													<td >HR</td>
													<td >5</td>
												</tr>
												<tr>
													<td >80</td>
													<td >Roshni Rayappa</td>
													<td >Smiths Detection</td>
													<td >HR</td>
													<td >5</td>
												</tr>
												<tr>
													<td >81</td>
													<td >Sakshi Deshpande</td>
													<td >Intelliswift</td>
													<td >HR</td>
													<td >5</td>
												</tr>
												<tr>
													<td >82</td>
													<td >Shelke Rushikesh Pravinrao</td>
													<td >SG Group </td>
													<td >Marketing</td>
													<td >5</td>
												</tr>
												<tr>
													<td >83</td>
													<td >Nishant Doke</td>
													<td >Motilal Oswal</td>
													<td >Marketing</td>
													<td >5</td>
												</tr>
												<tr>
													<td >84</td>
													<td >Chaitanya Koli</td>
													<td >Justo</td>
													<td >Marketing</td>
													<td >4.8</td>
												</tr>
												<tr>
													<td >85</td>
													<td >Arun Bhaskar Shetty</td>
													<td >NatWest Group</td>
													<td >Finance</td>
													<td >4.68</td>
												</tr>
												<tr>
													<td >86</td>
													<td >Uday Dhananjay Khillare</td>
													<td >ICICI Prudential</td>
													<td >Marketing</td>
													<td >4.6</td>
												</tr>
												<tr>
													<td >87</td>
													<td >Pratiksha Amritkar</td>
													<td >HDFC Life</td>
													<td >Marketing</td>
													<td >4.5</td>
												</tr>
												<tr>
													<td >88</td>
													<td >Smruti Kadu</td>
													<td >HDFC Life</td>
													<td >Finance</td>
													<td >4.5</td>
												</tr>
												<tr>
													<td >89</td>
													<td >Shivam Gosavi</td>
													<td >HDFC Life</td>
													<td >Finance</td>
													<td >4.5</td>
												</tr>
												<tr>
													<td >90</td>
													<td >Mitesh Vasant Gadekar</td>
													<td >Winspire</td>
													<td >Finance</td>
													<td >4.5</td>
												</tr>
												<tr>
													<td >91</td>
													<td >Aditi</td>
													<td >Kotak Mahindra Bank</td>
													<td >Finance</td>
													<td >4.5</td>
												</tr>
												<tr>
													<td >92</td>
													<td >Mohit Chandane</td>
													<td >EVOSYS</td>
													<td >Operation</td>
													<td >4.5</td>
												</tr>
												<tr>
													<td >93</td>
													<td >Abhishek Gramopadhye</td>
													<td >Future Supply Chain Solutions Ltd</td>
													<td >Operation</td>
													<td >4.5</td>
												</tr>
												<tr>
													<td >94</td>
													<td >Radha Rahane</td>
													<td >HDFC Life</td>
													<td >Finance</td>
													<td >4.5</td>
												</tr>
												<tr>
													<td >95</td>
													<td >Ankita Shetty</td>
													<td >Anand Rathi</td>
													<td >Finance</td>
													<td >4.5</td>
												</tr>
												<tr>
													<td >96</td>
													<td >Yug Turkar</td>
													<td >Future Supply Chain Solutions Ltd</td>
													<td >Marketing</td>
													<td >4.5</td>
												</tr>
												<tr>
													<td >97</td>
													<td >Snehal Dhandare</td>
													<td >Future Supply Chain Solutions Ltd</td>
													<td >Finance</td>
													<td >4.5</td>
												</tr>
												<tr>
													<td >98</td>
													<td >Sachin John Vivek</td>
													<td >Future Supply Chain Solutions Ltd</td>
													<td >Operation</td>
													<td >4.5</td>
												</tr>
												<tr>
													<td >99</td>
													<td >Roshan Bhalerao</td>
													<td >Venew</td>
													<td >Marketing</td>
													<td >4.5</td>
												</tr>
												<tr>
													<td >100</td>
													<td >Abhishek Kumar Mishra</td>
													<td >Peninsula Land</td>
													<td >Marketing</td>
													<td >4.5</td>
												</tr>
												<tr>
													<td >101</td>
													<td >Prabhat Kumar</td>
													<td >HDFC Life</td>
													<td >Marketing</td>
													<td >4.2</td>
												</tr>
												<tr>
													<td >102</td>
													<td >Sanket Ranjane</td>
													<td >Broadnet Technology</td>
													<td >Marketing</td>
													<td >4.2</td>
												</tr>
												<tr>
													<td >103</td>
													<td >Shruti Ugle</td>
													<td >L&T Infotech</td>
													<td >Finance</td>
													<td >4.15</td>
												</tr>
												<tr>
													<td >104</td>
													<td >Shivani Sewaiwar</td>
													<td >L&T Infotech/Hashedin by Deloitte</td>
													<td >Marketing</td>
													<td >4.15</td>
												</tr>
												<tr>
													<td >105</td>
													<td >Niyati Sheth</td>
													<td >L&T Infotech</td>
													<td >HR</td>
													<td >4.15</td>
												</tr>
												<tr>
													<td >106</td>
													<td >Roshan Sonawane</td>
													<td >Adlift</td>
													<td >Marketing</td>
													<td >4</td>
												</tr>
												<tr>
													<td >107</td>
													<td >Abhijeet Sanjay Dhamdhere</td>
													<td >Ernst & Young </td>
													<td >Operation</td>
													<td >4</td>
												</tr>
												<tr>
													<td >108</td>
													<td >Sagar Shivaji Raut</td>
													<td >Ernst & Young </td>
													<td >Operation</td>
													<td >4</td>
												</tr>
												<tr>
													<td >109</td>
													<td >Anshul Mandwariya</td>
													<td >Jana Small Finance Bank</td>
													<td >Marketing</td>
													<td >4</td>
												</tr>
												<tr>
													<td >110</td>
													<td >Kunal Tikaramji Ghagre</td>
													<td >Jana Small Finance Bank</td>
													<td >Marketing</td>
													<td >4</td>
												</tr>
												<tr>
													<td >111</td>
													<td >Akshit Purohit</td>
													<td >Jana Small Finance Bank</td>
													<td >Finance</td>
													<td >4</td>
												</tr>
												<tr>
													<td >112</td>
													<td >Tushar Pandurang Tonge</td>
													<td >Jana Small Finance Bank</td>
													<td >Finance</td>
													<td >4</td>
												</tr>
												<tr>
													<td >113</td>
													<td >Prasad Lakras</td>
													<td >Inspacco</td>
													<td >Finance</td>
													<td >4</td>
												</tr>
												<tr>
													<td >114</td>
													<td >Pallavi Singh</td>
													<td >Jana Small Finance Bank</td>
													<td >Finance</td>
													<td >4</td>
												</tr>
												<tr>
													<td >115</td>
													<td >Shivani Holkar</td>
													<td >XpressBees</td>
													<td >HR</td>
													<td >4</td>
												</tr>
												<tr>
													<td >116</td>
													<td >Rajeshwari Ashok Patil</td>
													<td >ICICI Securities</td>
													<td >Finance</td>
													<td >4</td>
												</tr>
												<tr>
													<td >117</td>
													<td >Tejal Sanjay Bagade</td>
													<td >ICICI Securities</td>
													<td >Finance</td>
													<td >4</td>
												</tr>
												<tr>
													<td >118</td>
													<td >Kushal Sharadrao Ganeshe</td>
													<td >ICICI Securities</td>
													<td >Finance</td>
													<td >4</td>
												</tr>
												<tr>
													<td >119</td>
													<td >Saurabh Sanjay Yeola</td>
													<td >ICICI Prudential</td>
													<td >Finance</td>
													<td >4</td>
												</tr>
												<tr>
													<td >120</td>
													<td >Sidhant Jadhav</td>
													<td >Axis Bank</td>
													<td >Marketing</td>
													<td >4</td>
												</tr>
												<tr>
													<td >121</td>
													<td >Unnati Naginbhai Bariya</td>
													<td >Waveraco Consulting</td>
													<td >Marketing</td>
													<td >4</td>
												</tr>
												<tr>
													<td >122</td>
													<td >Satish Rathod</td>
													<td >Institute of Technology and Management</td>
													<td >Finance</td>
													<td >4</td>
												</tr>
												<tr>
													<td >123</td>
													<td >Saurabh katre</td>
													<td >Transparency</td>
													<td >Marketing</td>
													<td >3.75</td>
												</tr>
												<tr>
													<td >124</td>
													<td >Bhagyashri Thoke</td>
													<td >Transparency</td>
													<td >Marketing</td>
													<td >3.75</td>
												</tr>
												<tr>
													<td >125</td>
													<td >Shubham Pohkar</td>
													<td >Transparency</td>
													<td >Marketing</td>
													<td >3.75</td>
												</tr>
												<tr>
													<td >126</td>
													<td >Manjiri Kshemkalyani</td>
													<td >Morningstar</td>
													<td >Finance</td>
													<td >3.5</td>
												</tr>
												<tr>
													<td >127</td>
													<td >Rupesh Rajaram Patil</td>
													<td >Meta Option</td>
													<td >Marketing</td>
													<td >2.7</td>
												</tr>
												<tr>
													<td >128</td>
													<td >Paras Bansod</td>
													<td >AU Small Finance Bank</td>
													<td >Marketing</td>
													<td >2.5</td>
												</tr>
												<tr>
													<td >129</td>
													<td >Sahnu Sharad</td>
													<td >Million Minds Securities Pvt. Ltd</td>
													<td >Finance</td>
													<td >2.5</td>
												</tr>
												<tr>
													<td >130</td>
													<td >Prashant Singh Chauhan</td>
													<td >Family business</td>
													<td >Marketing</td>
													<td >-</td>
												</tr>
												<tr>
													<td >131</td>
													<td >Shubham Rajesh Agarwal</td>
													<td >Family business</td>
													<td >Finance</td>
													<td >-</td>
												</tr>
												<tr>
													<td >132</td>
													<td >Shrihari Pravinkumar Laddha</td>
													<td >Family business</td>
													<td >Marketing</td>
													<td >-</td>
												</tr>

											</tbody>
										</Table>
									</div>


								</div>
							</div>}
						{Placement2019 &&
							<div className="paddingLeft MobPlaceMarginTop">
								<div className="col-md-12 padding15px whiteBackground">
									<h3 className="blockTitle">HOW INDIRA SCHOOL OF BUSINESS STUDIES (ISBS) RANKS
										HIGH ON MBA 2019-2021 PLACEMENT</h3>
									<p>MBA courses at ISBS are nationally recognised and offer aspiring business professionals the right career platform to grow even
										in challenging times.</p>
									<div className="row">
										<div className="col-md-3 marginBottom3em">
											<div className="col-md-12 boxShadow padding15px borderRadius15  text-center minHeight335">
												<p><b>MBA BATCH PROFILE</b></p>
												<div className="row">
													<div className="col text-center">
														<p className="text-center">Male</p>
														<img src={MaleImage} className="width80" />
														<div className="textColorBox1">
															55.39%
														</div>
													</div>
													<div className="col text-center">
														<p className="text-center">Female</p>
														<img src={FemaleImage} className="width80" />
														<div className="textColorBox1">
															55.39%
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-9 marginBottom3em">
											<div className="col-md-12 boxShadow padding15px borderRadius15 text-center minHeight335 mobHeightAuto">
												<p><b>STUDENTS’ ACADEMIC BACKGROUND</b></p>
												<img src={Graph1Image} className="width80" />
											</div>
										</div>
									</div>

									<div className="col-md-12 marginBottom3em">
										<div className="boxShadow padding15px borderRadius15">
											<div className="row">
												<div className="col-md-12 paddingTop">
													<p>Our placement offers a unique opportunity to apply your skills on real-life projects and make connections with exciting
														businesses across the globe. Whether you want to work in senior management, business consultancy, or build your own
														enterprise, our MBA will give you the skills, inspiration and connections to achieve it.</p>
												</div>
												<div className="col-md-2"></div>
												<div className="col-md-6">
													<img src={Graph2Image} className="imgRes" />
												</div>
												<div className="col-md-2">
													<img src={Graph3Image} className="width80 mobHide" />
													<img src={Graph3MImage} className="width80 mobShow" />
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12 marginBottom3em">
										<div className="boxShadow padding15px borderRadius15">
											<div className="row">
												<div className="col-md-12 paddingTop">
													<p>The ISBS MBA gives an invaluable depth of knowledge in global business and the roles each department plays within an
														organisation. Those tools opened the doors for our students and were a factor in the success of helping them achieve their
														dream job.</p>
												</div>
												<div className="row">
													<div className="col-md-4">
														<img src={Graph4Image} className="imgRes" />
													</div>
													<div className="col-md-4">
														<img src={Graph5Image} className="imgRes" />
													</div>
													<div className="col-md-4">
														<img src={Graph6Image} className="imgRes" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12 marginBottom3em">
										<div className="col-md-12 paddingTop text-center boxShadow padding15px borderRadius15 marginBottom3em">
											<p><b>PLACEMENT STRENGTH IN NUMBERS MBA</b></p>
											<img src={Graph7Image} className="width80 mobHide" />
											<img src={Graph71mImage} className="width80 mobShow" />
											<img src={Graph72mImage} className="width80 mobShow" />
											<img src={Graph73mImage} className="width80 mobShow" />
											<img src={Graph74mImage} className="width80 mobShow" />
										</div>
									</div>
								</div>
							</div>}
						{Placement2018 &&
							<div className="paddingLeft MobPlaceMarginTop">
								<div className="col-md-12 padding15px whiteBackground">
									<h3 className="blockTitle">HOW INDIRA SCHOOL OF BUSINESS STUDIES (ISBS) RANKS HIGH ON MBA 2018-2020 PLACEMENTS</h3>
									<p>MBA courses at ISBS are nationally recognised and offer aspiring business professionals the right career platform to grow even
										in challenging times.</p>
									<div className="row">
										<div className="col-md-3 marginBottom3em">
											<div className="col-md-12 boxShadow padding15px borderRadius15  text-center minHeight335">
												<p><b>MBA BATCH PROFILE</b></p>
												<div className="row">
													<div className="col text-center">
														<p className="text-center">Male</p>
														<img src={MaleImage} className="width80" />
														<div className="textColorBox1">
															64%
														</div>
													</div>
													<div className="col text-center">
														<p className="text-center">Female</p>
														<img src={FemaleImage} className="width80" />
														<div className="textColorBox1">
															36%
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-9 marginBottom3em">
											<div className="col-md-12 boxShadow padding15px borderRadius15 text-center minHeight335 mobHeightAuto">
												<p><b>STUDENTS’ ACADEMIC BACKGROUND</b></p>
												<img src={Graph2018Image} className="width80" />
											</div>
										</div>
									</div>

									<div className="col-md-12 marginBottom3em">
										<div className="boxShadow padding15px borderRadius15">
											<div className="row">
												<div className="col-md-12 paddingTop">
													<p>Our placement offers a unique opportunity to apply your skills on real-life projects and make connections with exciting
														businesses across the globe. Whether you want to work in senior management, business consultancy, or build your own
														enterprise, our MBA will give you the skills, inspiration and connections to achieve it.</p>
												</div>
												<div className="col-md-2"></div>
												<div className="col-md-8">
													<img src={Graph2018Employment} className="imgRes" />
												</div>
												<div className="col-md-2">

												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12 marginBottom3em">
										<div className="boxShadow padding15px borderRadius15">
											<div className="row">
												<div className="col-md-12 paddingTop">
													<p>The ISBS MBA gives an invaluable depth of knowledge in global business and the roles each department plays within an
														organisation. Those tools opened the doors for our students and were a factor in the success of helping them achieve their
														dream job.</p>
												</div>
												<div className="row">
													<div className="col-md-12">
														<img src={Graph2018Package} className="imgRes" />
													</div>

												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12 marginBottom3em">
										<div className="col-md-12 paddingTop text-center boxShadow padding15px borderRadius15 marginBottom3em">
											<p><b>PLACEMENT STRENGTH IN NUMBERS MBA</b></p>
											<img src={Graph2019Stats} className="width80 mobHide" />
											<img src={Graph18m1Image} className="width80 mobShow" />
											<img src={Graph18m2Image} className="width80 mobShow" />
											<img src={Graph18m3Image} className="width80 mobShow" />
											<img src={Graph18m4Image} className="width80 mobShow" />
										</div>
									</div>
								</div>
							</div>}


						{Placement2017 &&
							<div className="paddingLeft MobPlaceMarginTop">
								<div className="col-md-12 padding15px whiteBackground">
									<h3 className="blockTitle">HOW INDIRA SCHOOL OF BUSINESS STUDIES (ISBS) RANKS HIGH ON MBA 2017-2019 PLACEMENTS</h3>
									<p>MBA courses at ISBS are nationally recognised and offer aspiring business professionals the right career platform to grow even in challenging times.</p>
									<div className="row">
										<div className="col-md-3 marginBottom3em">
											<div className="col-md-12 boxShadow padding15px borderRadius15  text-center minHeight335">
												<p><b>MBA BATCH PROFILE</b></p>
												<div className="row">
													<div className="col text-center">
														<p className="text-center">Male</p>
														<img src={MaleImage} className="width80" />
														<div className="textColorBox1">
															77.66%
														</div>
													</div>
													<div className="col text-center">
														<p className="text-center">Female</p>
														<img src={FemaleImage} className="width80" />
														<div className="textColorBox1">
															28.34%
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-9 marginBottom3em">
											<div className="col-md-12 boxShadow padding15px borderRadius15 text-center minHeight335 mobHeightAuto">
												<p><b>STUDENTS’ ACADEMIC BACKGROUND</b></p>
												<img src={Graph2017Image} className="width80" />
											</div>
										</div>
									</div>

									<div className="col-md-12 marginBottom3em">
										<div className="boxShadow padding15px borderRadius15">
											<div className="row">
												<div className="col-md-12 paddingTop">
													<p>Our placement offers a unique opportunity to apply your skills on real-life projects and make connections with exciting businesses across the globe.
														Whether you want to work in senior management, business consultancy, or build your own enterprise, our MBA will give you the skills, inspiration
														and connections to achieve it.</p>
												</div>
												<div className="col-md-2"></div>
												<div className="col-md-8">
													<img src={Graph2017Employment} className="imgRes" />
												</div>
												<div className="col-md-2"></div>
											</div>
										</div>
									</div>
									<div className="col-md-12 marginBottom3em">
										<div className="boxShadow padding15px borderRadius15">
											<div className="row">
												<div className="col-md-12 paddingTop">
													<p>The ISBS MBA gives an invaluable depth of knowledge in global business and the roles each department plays within an
														organisation. Those tools opened the doors for our students and were a factor in the success of helping them achieve their
														dream job.</p>
												</div>
												<div className="row">
													<div className="col-md-12">
														<img src={Graph2017Package} className="imgRes" />
													</div>

												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12 marginBottom3em">
										<div className="col-md-12 paddingTop text-center boxShadow padding15px borderRadius15 marginBottom3em">
											<p><b>PLACEMENT STRENGTH IN NUMBERS MBA</b></p>
											<img src={Graph2017Stats} className="width80 mobHide" />
											<img src={Graph17m1Image} className="width80 mobShow" />
											<img src={Graph17m2Image} className="width80 mobShow" />
											<img src={Graph17m3Image} className="width80 mobShow" />
											<img src={Graph17m4Image} className="width80 mobShow" />
										</div>
									</div>
								</div>
							</div>}
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default PlacementAtISBS