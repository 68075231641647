import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/placement/last.png"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
const PlacementReport = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<Fragment>
			<Header></Header>
			<div className="col bannerCont">
				<img src={BannerImage} alt="" className="imgRes" />
				<h2 className="bannerTitleAbout leftAlignBannerTxt whiteText">PLACEMENT REPORT</h2>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h3 className="blockTitle">Placement &#62; <span> Previous years Placement Report</span></h3>
					</div>
				</div>
			</div>
			<div className="backgroundGreyPlain">
				<div className="container">
					<div className="row">
						<Tabs
							defaultActiveKey="2023-25"
							id="uncontrolled-tab-example"
							className="barColor col-4t transparentBackground"
						>
                           <Tab eventKey="2023-25" title="2023-25 Batch" className="whiteBackground paddingBottom3em">
								<div className="container">
									<div className="row">
										<div className="col-md-12">
											<h3 className="blockTitle">2023-25 Batch</h3>
											<Table class="table table-responsive table-bordered" responsive>
												<tbody>
													<tr>
														<td colspan="4"><b>Batch 2023-25 MBA Placement Summary Till 26.12.2024</b></td>
													</tr>
													<tr>
														<td><b>Specialization</b></td>
														<td><b>Placed Students</b></td>
														<td><b>Total Students</b></td>
														<td><b>Placed Percentage</b></td>
													</tr>
													<tr>
														<td><b>Finance</b></td>
														<td>35</td><td>108</td><td><b>32.41</b></td>
													</tr>
													<tr>
														<td><b>Marketing</b></td>
														<td>30</td><td>116</td><td><b>25.86</b></td>
													</tr>
													
													<tr>
														<td><b>HR</b></td>
														<td>0</td><td>18</td><td><b>0</b></td>
													</tr>
													<tr>
														<td><b>Operation</b></td>
														<td>10</td><td>20</td><td><b>50</b></td>
													</tr>
													<tr>
														<td><b>Total</b></td>
														<td><b>75</b></td><td><b>262</b></td><td><b>28.63</b></td>
													</tr>
												</tbody>
											</Table>

											<Table class="table table-responsive table-bordered" responsive>
												<tbody>
													<tr><td colspan="3"><b>Batch 2023-25 MBA Package</b></td></tr>
													{/* <tr><td colspan="3"><b>Batch 2020-22 MBA Package Summary  </b></td></tr> */}
													<tr><td><b>Specialization</b></td><td><b>Min Package LPA</b></td><td><b>Highest Package LPA</b></td></tr>
													<tr><td><b>Marketing</b></td><td>4.3</td><td>16.42 (Federal Bank)</td></tr>
													<tr><td><b>Finance</b></td><td>4</td><td>10.5 (Pattern Technology)</td></tr>
													<tr><td><b>HR</b></td><td>NA</td><td>NA</td></tr>
													<tr><td><b>Operation</b></td><td>3</td><td>10.5 (Philips)</td></tr>

												</tbody>
											</Table>
											<Table class="table table-responsive table-bordered" responsive>

												<tbody>
													<tr>
														<td className="text-center" colSpan={5}><b>Placed Students of Batch 2023-25 </b></td>

													</tr>
													<tr>
														<td><b>Sr No.</b></td>
														<td><b>Students Name</b></td>
														<td><b>Name of the Company</b></td>
														<td><b>Specialization(Major)</b></td>
														<td><b>Package in LPA INR</b></td>
													</tr>
												
													<tr>
								<td >1</td>
								<td >Ashish Popat Mote</td>
								<td >Federal Bank</td>
								<td >Finance</td>
								<td >16.42</td>
							</tr>
							<tr>
								<td >2</td>
								<td >Bhakti Ganesh Dahale</td>
								<td >Federal Bank</td>
								<td >Finance</td>
								<td >16.42</td>
							</tr>
							<tr>
								<td >3</td>
								<td >Aditya Kumar Mishra</td>
								<td >Federal Bank</td>
								<td >Finance</td>
								<td >16.42</td>
							</tr>
							<tr>
								<td >4</td>
								<td >Anjali Sanjay Borse </td>
								<td >Federal Bank</td>
								<td >Finance</td>
								<td >16.42</td>
							</tr>
							<tr>
								<td >5</td>
								<td >Bhargavi Dilip Wadighare</td>
								<td >Federal Bank</td>
								<td >Finance</td>
								<td >16.42</td>
							</tr>
							<tr>
								<td >6</td>
								<td >Abhishek Jamuwant Kurmelwar</td>
								<td >Pattern Technology</td>
								<td >Marketing</td>
								<td >10.5</td>
							</tr>
							<tr>
								<td >7</td>
								<td >Bhavesh Sunil Khadka</td>
								<td >Pattern Technology</td>
								<td >Marketing</td>
								<td >10.5</td>
							</tr>
							<tr>
								<td >8</td>
								<td >Poorvi Sanjay Sahu </td>
								<td >Pattern Technology</td>
								<td >Marketing</td>
								<td >10.5</td>
							</tr>
							<tr>
								<td >9</td>
								<td >Rushikesh Ulhas Medshikar </td>
								<td >Pattern Technology</td>
								<td >Marketing</td>
								<td >10.5</td>
							</tr>
							<tr>
								<td >10</td>
								<td >Yuganshu Dilip Khatke </td>
								<td >Pattern Technology</td>
								<td >Marketing</td>
								<td >10.5</td>
							</tr>
							<tr>
								<td >11</td>
								<td >Ajinkya Anantrao Sable</td>
								<td >Pattern Technology</td>
								<td >Marketing</td>
								<td >10.5</td>
							</tr>
							<tr>
								<td >12</td>
								<td >Bhavesh Ajit Pande</td>
								<td >Philips</td>
								<td >Operation</td>
								<td >10.5</td>
							</tr>
							<tr>
								<td >13</td>
								<td >Mansi Dattatray Nagre</td>
								<td >Philips</td>
								<td >Operation</td>
								<td >10</td>
							</tr>
							<tr>
								<td >14</td>
								<td >Vishal Mansing Pawar</td>
								<td >Mahindra Finance</td>
								<td >Finance</td>
								<td >10</td>
							</tr>
							<tr>
								<td >15</td>
								<td >Rupesh Bharat Agale </td>
								<td >Mahindra Finance</td>
								<td >Marketing</td>
								<td >10</td>
							</tr>
							<tr>
								<td >16</td>
								<td >Viraj Bharat Dandwate</td>
								<td>Icestesy Projects Pvt. Ltd.</td>
								<td >Marketing</td>
								<td >10</td>
							</tr>
							<tr>
								<td >17</td>
								<td >Harshal Ramchandra Dabhade</td>
								<td >Trinamix</td>
								<td >Operation</td>
								<td >9.5</td>
							</tr>
							<tr>
								<td >18</td>
								<td >Pratiksha Tandon </td>
								<td >Trinamix</td>
								<td >Operation</td>
								<td >9.5</td>
							</tr>
							<tr>
								<td >19</td>
								<td >Rohan Ravi Katkar</td>
								<td >BAGIC</td>
								<td >Marketing</td>
								<td >9.42</td>
							</tr>
							<tr>
								<td >20</td>
								<td >Vaishnavi Madhukarrao Joshi </td>
								<td >BAGIC</td>
								<td >Marketing</td>
								<td >9.42</td>
							</tr>
							<tr>
								<td >21</td>
								<td >Chaitanya Anil Sadafale </td>
								<td >Hafele</td>
								<td >Marketing</td>
								<td >8.54</td>
							</tr>
							<tr>
								<td >22</td>
								<td >Jayesh Kailas Gund </td>
								<td >Adani Wilmar</td>
								<td >Marketing</td>
								<td >8.5</td>
							</tr>
							<tr>
								<td >23</td>
								<td >Sumit  Joshi </td>
								<td >Nestle</td>
								<td >Marketing</td>
								<td >8.5</td>
							</tr>
							<tr>
								<td >24</td>
								<td >Abhishek Ganeshrao More</td>
								<td >ICICI Bank</td>
								<td >Finance</td>
								<td >8.29</td>
							</tr>
							<tr>
								<td >25</td>
								<td >Pranay Prakash Dheringe</td>
								<td >Forvis Mazars</td>
								<td >Marketing</td>
								<td >7.95</td>
							</tr>
							<tr>
								<td >26</td>
								<td >Aashish Dnyaneshwar Saravade</td>
								<td >Deloitte (Tax)</td>
								<td >Finance</td>
								<td >7.6</td>
							</tr>
							<tr>
								<td >27</td>
								<td >Akash Sayaji Pandilwar </td>
								<td >Deloitte (Tax)</td>
								<td >Finance</td>
								<td >7.6</td>
							</tr>
							<tr>
								<td >28</td>
								<td >Apoorv Mohan Deshmukh </td>
								<td >Deloitte (Tax)</td>
								<td >Finance</td>
								<td >7.6</td>
							</tr>
							<tr>
								<td >29</td>
								<td >Sakshi Surendra More</td>
								<td >Deloitte (Tax)</td>
								<td >Finance</td>
								<td >7.6</td>
							</tr>
							<tr>
								<td >30</td>
								<td >Vyankatesh Bibhishanrao Jadhav</td>
								<td >Deloitte (Tax)</td>
								<td >Finance</td>
								<td >7.6</td>
							</tr>
							<tr>
								<td >31</td>
								<td >Yashraj Umesh Joshi</td>
								<td >Deloitte (Tax)</td>
								<td >Finance</td>
								<td >7.6</td>
							</tr>
							<tr>
        <td>32</td>
        <td>Sanya Agrawal</td>
        <td>Deloitte (Tax)</td>
        <td>Finance</td>
        <td>7.6</td>
    </tr>
    <tr>
        <td>33</td>
        <td>Sumedh Digvijay Mahanur</td>
        <td>Deloitte TPMS</td>
        <td>Marketing</td>
        <td>7.6</td>
    </tr>
    <tr>
        <td>34</td>
        <td>Janhavi Ravindra Paraskar</td>
        <td>Birla Opus Aditya Birla Group</td>
        <td>Marketing</td>
        <td>7.5</td>
    </tr>
    <tr>
        <td>35</td>
        <td>Soniya Giridhar Waghmare</td>
        <td>Birla Opus Aditya Birla Group</td>
        <td>Marketing</td>
        <td>7.5</td>
    </tr>
    <tr>
        <td>36</td>
        <td>Pavan Chandrakant Patil</td>
        <td>KD Logistics</td>
        <td>Operation</td>
        <td>7.5</td>
    </tr>
    <tr>
        <td>37</td>
        <td>Vedant Satish Bhambere</td>
        <td>The Orchids International</td>
        <td>Marketing</td>
        <td>7</td>
    </tr>
    <tr>
        <td>38</td>
        <td>Kshitija Raju Shinde</td>
        <td>Factsheet</td>
        <td>Finance</td>
        <td>6.6</td>
    </tr>
    <tr>
        <td>39</td>
        <td>Rohit Kale</td>
        <td>Factsheet</td>
        <td>Finance</td>
        <td>6.6</td>
    </tr>
    <tr>
        <td>40</td>
        <td>Hitesh Suresh Ruke</td>
        <td>Factsheet</td>
        <td>Finance</td>
        <td>6.6</td>
    </tr>
    <tr>
        <td>41</td>
        <td>Ramola Praveen Shete</td>
        <td>UBS </td>
        <td>Finance</td>
        <td>6.5</td>
    </tr>
    <tr>
        <td>42</td>
        <td>Shubham Rajendra Deore</td>
        <td>Aditya Birla Sun Life</td>
        <td>Marketing</td>
        <td>6.5</td>
    </tr>
    <tr>
        <td>43</td>
        <td>Mansi Udaysingh Rajput</td>
        <td>Faurecia Talent Hub</td>
        <td>Operation</td>
        <td>6.5</td>
    </tr>
    <tr>
        <td>44</td>
        <td>Priya Kumari</td>
        <td>ANZ Bank</td>
        <td>Finance</td>
        <td>6.3</td>
    </tr>
    <tr>
        <td>45</td>
        <td>Priyanka Arjun Nale </td>
        <td>ANZ Bank</td>
        <td>Finance</td>
        <td>6.3</td>
    </tr>
    <tr>
        <td>46</td>
        <td>Tejas Anil Bhome</td>
        <td>ANZ Bank</td>
        <td>Finance</td>
        <td>6.3</td>
    </tr>
    <tr>
        <td>47</td>
        <td>Ritisha Manoj Kukreja</td>
        <td>ANZ Bank</td>
        <td>Finance</td>
        <td>6.3</td>
    </tr>
    <tr>
        <td>48</td>
        <td>Prasad Vikas Tale</td>
        <td>Aditya Birla Sun Life AMC</td>
        <td>Finance</td>
        <td>6.25</td>
    </tr>
    <tr>
        <td>49</td>
        <td>Shrikantreddy Ramreddy Pogal</td>
        <td>Global Market Insights</td>
        <td>Marketing</td>
        <td>6.12</td>
    </tr>
    <tr>
        <td>50</td>
        <td>Suyash Yuvraj Deshmukh</td>
        <td>VTP Realty</td>
        <td>Marketing</td>
        <td>6</td>
    </tr>
    <tr>
        <td>51</td>
        <td>Vaishnavi Gajanan Patil</td>
        <td>Godrej Properties</td>
        <td>Marketing</td>
        <td>6</td>
    </tr>
    <tr>
        <td>52</td>
        <td>Shweta Arun Waghmare</td>
        <td>Spacewalk Advisors LLP</td>
        <td>Marketing</td>
        <td>5.7</td>
    </tr>
    <tr>
        <td>53</td>
        <td>Ayush Arun Kakde</td>
        <td>Haleon</td>
        <td>Marketing</td>
        <td>5.5</td>
    </tr>
    <tr>
        <td>54</td>
        <td>Suraj Shivaji Gondwale</td>
        <td>Haleon</td>
        <td>Marketing</td>
        <td>5.5</td>
    </tr>
    <tr>
        <td>55</td>
        <td>Abhishek Sitaram Dalal</td>
        <td>WNS</td>
        <td>Finance</td>
        <td>5</td>
    </tr>
    <tr>
        <td>56</td>
        <td>Anjali Prakash Sayam</td>
        <td>WNS</td>
        <td>Finance</td>
        <td>5</td>
    </tr>
    <tr>
        <td>57</td>
        <td>Bhojraj Ravishankar Merukar</td>
        <td>WNS</td>
        <td>Finance</td>
        <td>5</td>
    </tr>
    <tr>
        <td>58</td>
        <td>Dipa Vishnu Falke</td>
        <td>WNS</td>
        <td>Finance</td>
        <td>5</td>
    </tr>
    <tr>
        <td>59</td>
        <td>Meghana Prakash Sangame</td>
        <td>WNS</td>
        <td>Finance</td>
        <td>5</td>
    </tr>
    <tr>
        <td>60</td>
        <td>Pravin Sonyabapu Salunke</td>
        <td>WNS</td>
        <td>Finance</td>
        <td>5</td>
    </tr>
    <tr>
        <td>61</td>
        <td>Shubham Subhash Deshmukh</td>
        <td>WNS</td>
        <td>Finance</td>
        <td>5</td>
    </tr>
    <tr>
        <td>62</td>
        <td>Anish Anil Rao</td>
        <td>WNS</td>
        <td>Finance</td>
        <td>5</td>
    </tr>
    <tr>
        <td>63</td>
        <td>Arya Basveshvar Patil</td>
        <td>WNS</td>
        <td>Finance</td>
        <td>5</td>
    </tr>
    <tr>
        <td>64</td>
        <td>Shubham Panditrao Bharti</td>
        <td>ITC</td>
        <td>Marketing</td>
        <td>4.5</td>
    </tr>
    <tr>
        <td>65</td>
        <td>Parth Santoshrao Gudadhe</td>
        <td>AMD Consulting</td>
        <td>Operation</td>
        <td>5</td>
    </tr>
    <tr>
        <td>66</td>
        <td>Deepti Yogesh Joshi</td>
        <td>Searce</td>
        <td>Finance</td>
        <td>4.5</td>
    </tr>
    <tr>
        <td>67</td>
        <td>Ajinkya Suhas Kadam</td>
        <td>Grand View Research</td>
        <td>Marketing</td>
        <td>4.5</td>
    </tr>
    <tr>
        <td>68</td>
        <td>Basavaraj Mohan Erandole</td>
        <td> LT Foods Ltd</td>
        <td>Marketing</td>
        <td>4.2</td>
    </tr>
    <tr>
        <td>69</td>
        <td>Shubham Sanjay Gunjal</td>
        <td>Zepto</td>
        <td>Operation</td>
        <td>4.5</td>
    </tr>
    <tr>
        <td>70</td>
        <td>Tejas Sanjiv Kendre</td>
        <td>Innovative Retail Concepts Pvt Ltd. (BigBasket.com)</td>
        <td>Marketing</td>
        <td>4.3</td>
    </tr>
    <tr>
        <td>71</td>
        <td>Manish Yuvraj Mistary</td>
        <td>V Check</td>
        <td>Finance</td>
        <td>5</td>
    </tr>
    <tr>
        <td>72</td>
        <td>Pallavi Sanjayrao Mahure</td>
        <td>Interpid Control System  </td>
        <td>Finance</td>
        <td>4</td>
    </tr>
    <tr>
        <td>73</td>
        <td>Digvijay Pravin Patil</td>
        <td>Infosys</td>
        <td>Operation</td>
        <td>3</td>
    </tr>
    <tr>
        <td>74</td>
        <td>Aniket Kishor Lohar</td>
        <td>Wisteria Properties Pvt. Ltd</td>
        <td>Marketing</td>
        <td>2.7</td>
    </tr>
    <tr>
        <td>75</td>
        <td>Mayur Ramnath Pawase</td>
        <td>Business</td>
        <td>Operation</td>
        <td>-</td>
    </tr>
							
													

												</tbody>
											</Table>

										</div>
									</div>
								</div>
							</Tab>

							<Tab eventKey="2022-24" title="2022-24 Batch" className="whiteBackground paddingBottom3em">
								<div className="container">
									<div className="row">
										<div className="col-md-12">
											<h3 className="blockTitle">2022-24 Batch</h3>
											<Table class="table table-responsive table-bordered" responsive>
												<tbody>
													<tr>
														<td colspan="4"><b>Batch 2022-24 MBA Placement Summary</b></td>
													</tr>
													<tr>
														<td><b>Specialization</b></td>
														<td><b>Placed Students</b></td>
														<td><b>Total Students</b></td>
														<td><b>Placed Percentage</b></td>
													</tr>
													<tr>
														<td><b>Marketing</b></td>
														<td>55</td><td>62</td><td><b>88.71</b></td>
													</tr>
													<tr>
														<td><b>Finance</b></td>
														<td>43</td><td>54</td><td><b>79.63</b></td>
													</tr>
													<tr>
														<td><b>HR</b></td>
														<td>6</td><td>7</td><td><b>85.71</b></td>
													</tr>
													<tr>
														<td><b>Operation</b></td>
														<td>10</td><td>12</td><td><b>83.33</b></td>
													</tr>
													<tr>
														<td><b>Total</b></td>
														<td><b>114</b></td><td><b>135</b></td><td><b>84.44</b></td>
													</tr>
												</tbody>
											</Table>

											<Table class="table table-responsive table-bordered" responsive>
												<tbody>
													<tr><td colspan="3"><b>Batch 2022-24 MBA Package</b></td></tr>
													{/* <tr><td colspan="3"><b>Batch 2020-22 MBA Package Summary  </b></td></tr> */}
													<tr><td><b>Specialization</b></td><td><b>Min Package LPA</b></td><td><b>Highest Package LPA</b></td></tr>
													<tr><td><b>Marketing</b></td><td>4</td><td>16.32 (Federal Bank)</td></tr>
													<tr><td><b>Finance</b></td><td>3.5</td><td>16.32 (Federal Bank)</td></tr>
													<tr><td><b>HR</b></td><td>3.5</td><td>5.79 (TCS)</td></tr>
													<tr><td><b>Operation</b></td><td>5.5</td><td>8 (Trinamix)</td></tr>

												</tbody>
											</Table>
											<Table class="table table-responsive table-bordered" responsive>

												<tbody>
													<tr>
														<td className="text-center" colSpan={5}><b>Placed Students of Batch 2022-24 </b></td>

													</tr>
													<tr>
														<td><b>Sr No.</b></td>
														<td><b>Students Name</b></td>
														<td><b>Name of the Company</b></td>
														<td><b>Specialization(Major)</b></td>
														<td><b>Package in LPA INR</b></td>
													</tr>
												
													<tr>
								<td >1</td>
								<td >Akash Tulsidas Padile</td>
								<td >Federal Bank</td>
								<td >Finance</td>
								<td >16.32</td>
							</tr>
							<tr>
								<td >2</td>
								<td >Kishor Mukundrao Dongare</td>
								<td >Federal Bank</td>
								<td >Finance</td>
								<td >16.32</td>
							</tr>
							<tr>
								<td >3</td>
								<td >Sanskar Anil parihar</td>
								<td >Federal Bank</td>
								<td >Finance</td>
								<td >16.32</td>
							</tr>
							<tr>
								<td >4</td>
								<td >Vrushabh Ramu Wadbudhe </td>
								<td >Federal Bank</td>
								<td >Finance</td>
								<td >16.32</td>
							</tr>
							<tr>
								<td >5</td>
								<td >Gayatri Sharad Tekade </td>
								<td >Federal Bank</td>
								<td >Finance</td>
								<td >16.32</td>
							</tr>
							<tr>
								<td >6</td>
								<td >Prerna  Ravindra Jamkar</td>
								<td >Federal Bank</td>
								<td >Finance</td>
								<td >16.32</td>
							</tr>
							<tr>
								<td >7</td>
								<td >Simrandas Eshwardas Vaishnav</td>
								<td >Federal Bank</td>
								<td >Marketing</td>
								<td >16.32</td>
							</tr>
							<tr>
								<td >8</td>
								<td >Falguni Ramesh Deshmukh </td>
								<td >Bank of New York Mellon (BNY)</td>
								<td >Finance</td>
								<td >10.2</td>
							</tr>
							<tr>
								<td >9</td>
								<td >Prathna Agrawal </td>
								<td >Bank of New York Mellon (BNY)</td>
								<td >Finance</td>
								<td >10.2</td>
							</tr>
							<tr>
								<td >10</td>
								<td >Purva Murlidhar Chandak </td>
								<td >Bank of New York Mellon (BNY)</td>
								<td >Finance</td>
								<td >10.2</td>
							</tr>
							<tr>
								<td >11</td>
								<td >Rajashree Raja Mukherjee</td>
								<td >Bank of New York Mellon (BNY)</td>
								<td >Finance</td>
								<td >10.2</td>
							</tr>
							<tr>
								<td >12</td>
								<td >Abhishek Ramdas Patil</td>
								<td >Mahindra Finance</td>
								<td >Marketing</td>
								<td >10</td>
							</tr>
							<tr>
								<td >13</td>
								<td >Riya Kumari</td>
								<td >Pattern Technologies</td>
								<td >Marketing</td>
								<td >9.3</td>
							</tr>
							<tr>
								<td >14</td>
								<td >Abhay prakash Singh </td>
								<td >Hawkins</td>
								<td >Marketing</td>
								<td >9</td>
							</tr>
							<tr>
								<td >15</td>
								<td >Vikrant Manoj Pardeshi </td>
								<td >Reliance BP</td>
								<td >Marketing</td>
								<td >9</td>
							</tr>
							<tr>
								<td >16</td>
								<td >Anirudh  Kaul</td>
								<td >Bajaj Allianz General Insurance Company</td>
								<td >Marketing</td>
								<td >8.68</td>
							</tr>
							<tr>
								<td >17</td>
								<td >Vrushank Sanjay Pardeshi</td>
								<td >Bajaj Allianz General Insurance Company</td>
								<td >Marketing</td>
								<td >8.68</td>
							</tr>
							<tr>
								<td >18</td>
								<td >Simran  </td>
								<td >Bajaj Allianz General Insurance Company</td>
								<td >Marketing</td>
								<td >8.68</td>
							</tr>
							<tr>
								<td >19</td>
								<td >Rutuja Hanumant Kaware </td>
								<td >Allied Digital</td>
								<td >Marketing</td>
								<td >8.5</td>
							</tr>
							<tr>
								<td >20</td>
								<td >Tanya Udaypratap Singh Thakur </td>
								<td >Hager Group</td>
								<td >Marketing</td>
								<td >8.5</td>
							</tr>
							<tr>
								<td >21</td>
								<td >Gayatri Gajanan Nalkande </td>
								<td >ICICI Bank</td>
								<td >Finance</td>
								<td >8.2</td>
							</tr>
							<tr>
								<td >22</td>
								<td >Kash Rakesh Budhlani </td>
								<td >ICICI Bank</td>
								<td >Finance</td>
								<td >8.2</td>
							</tr>
							<tr>
								<td >23</td>
								<td >Nikhil Madan Lambat </td>
								<td >ICICI Bank</td>
								<td >Finance</td>
								<td >8.2</td>
							</tr>
							<tr>
								<td >24</td>
								<td >Abhay Ramdhan Ade</td>
								<td >ICICI Bank</td>
								<td >Marketing</td>
								<td >8.2</td>
							</tr>
							<tr>
								<td >25</td>
								<td >Rishav</td>
								<td >ICICI Bank</td>
								<td >Marketing</td>
								<td >8.2</td>
							</tr>
							<tr>
								<td >26</td>
								<td >Rushikesh Baban Binnar</td>
								<td >ICICI Bank</td>
								<td >Marketing</td>
								<td >8.2</td>
							</tr>
							<tr>
								<td >27</td>
								<td >Pratik Devidas Bhosale </td>
								<td >Homes First Finance</td>
								<td >Finance</td>
								<td >8</td>
							</tr>
							<tr>
								<td >28</td>
								<td >Girish Ravindra Mohite </td>
								<td >Trinamix Systems Private Limited</td>
								<td >Operation</td>
								<td >8</td>
							</tr>
							<tr>
								<td >29</td>
								<td >Ashwin Ashok Ahire </td>
								<td >Homes First Finance</td>
								<td >Marketing</td>
								<td >8</td>
							</tr>
							<tr>
								<td >30</td>
								<td >Durgesh Prafulla Thakur</td>
								<td >Bestosys Solutions Pvt. Ltd.</td>
								<td >Marketing</td>
								<td >8</td>
							</tr>
							<tr>
								<td >31</td>
								<td >Rushikesh Vishnupant kathe</td>
								<td >Bestosys Solutions Pvt. Ltd.</td>
								<td >Marketing</td>
								<td >8</td>
							</tr>
							<tr>
								<td >32</td>
								<td >Shivam Minkeshwar Chaudhari </td>
								<td >Deloitte </td>
								<td >Finance</td>
								<td >7.8</td>
							</tr>
							<tr>
								<td >33</td>
								<td >Anisha Kumari</td>
								<td >Nestle Ltd.</td>
								<td >Marketing</td>
								<td >7.62</td>
							</tr>
							<tr>
								<td >34</td>
								<td >Sakshi Shekhar Kalbande </td>
								<td >Deloitte </td>
								<td >Finance</td>
								<td >7.6</td>
							</tr>
							<tr>
								<td >35</td>
								<td >Atharva Sanjay Kolhe </td>
								<td >Deloitte </td>
								<td >Finance</td>
								<td >7.6</td>
							</tr>
							<tr>
								<td >36</td>
								<td >Darshit Dharmeshbhai Patel </td>
								<td >Bike Bazaar</td>
								<td >Marketing</td>
								<td >7.5</td>
							</tr>
							<tr>
								<td >37</td>
								<td >Gopal  Bhadauriya</td>
								<td >Asian Paints Ltd.</td>
								<td >Marketing</td>
								<td >7.5</td>
							</tr>
							<tr>
								<td >38</td>
								<td >Kiran kailas Wagh</td>
								<td >Asian Paints Ltd.</td>
								<td >Marketing</td>
								<td >7.5</td>
							</tr>
							<tr>
								<td >39</td>
								<td >Kishor Arjun Kumbhar</td>
								<td >Grasim Industries Ltd (Birla Paints)</td>
								<td >Marketing</td>
								<td >7.5</td>
							</tr>
							<tr>
								<td >40</td>
								<td >Premchand Agrawala</td>
								<td >Grasim Industries Ltd (Birla Paints)</td>
								<td >Marketing</td>
								<td >7.5</td>
							</tr>
							<tr>
								<td >41</td>
								<td >Saish Kailas Gholap </td>
								<td >ICICI Prudential life</td>
								<td >Marketing</td>
								<td >7.5</td>
							</tr>
							<tr>
								<td >42</td>
								<td >Abhishek Mukesh  Ranjan</td>
								<td >Reliance Retail</td>
								<td >Operation</td>
								<td >7</td>
							</tr>
							<tr>
								<td >43</td>
								<td >Akshay Najukram Gahane </td>
								<td >Reliance Retail</td>
								<td >Operation</td>
								<td >7</td>
							</tr>
							<tr>
								<td >44</td>
								<td >Abhijit Suryabhan Kalhapure</td>
								<td >Legrand</td>
								<td >Marketing</td>
								<td >7</td>
							</tr>
							<tr>
								<td >45</td>
								<td >Kaushik Bang</td>
								<td >Legrand</td>
								<td >Marketing</td>
								<td >7</td>
							</tr>
							<tr>
								<td >46</td>
								<td >Rishi Anil Patil</td>
								<td >Legrand</td>
								<td >Marketing</td>
								<td >7</td>
							</tr>
							<tr>
								<td >47</td>
								<td >Shubham Jaiprakash Jituri </td>
								<td >Reliance Retail</td>
								<td >Marketing</td>
								<td >7</td>
							</tr>
							<tr>
								<td >48</td>
								<td >Dhruv Ullas Patidar </td>
								<td >Xanadu Realty</td>
								<td >Marketing</td>
								<td >6.82</td>
							</tr>
							<tr>
								<td >49</td>
								<td >Aishwarya Suhas Sutar</td>
								<td >HDFC Bank</td>
								<td >Finance</td>
								<td >6.8</td>
							</tr>
							<tr>
								<td >50</td>
								<td >Nikhita Abhay Katkar </td>
								<td >FIS Global</td>
								<td >Finance</td>
								<td >6.69</td>
							</tr>
							<tr>
								<td >51</td>
								<td >Sanskruti Pravin Khandar </td>
								<td >FIS Global</td>
								<td >Finance</td>
								<td >6.6</td>
							</tr>
							<tr>
								<td >52</td>
								<td >Utkarsh Subhash Lokhande </td>
								<td >FIS Global</td>
								<td >Finance</td>
								<td >6.6</td>
							</tr>
							<tr>
								<td >53</td>
								<td >Shivani Ashok Kale </td>
								<td >Global Market Vision</td>
								<td >Marketing</td>
								<td >6.6</td>
							</tr>
							<tr>
								<td >54</td>
								<td >Shraddha Prakash Patil </td>
								<td >Siemens</td>
								<td >Finance</td>
								<td >6.5</td>
							</tr>
							<tr>
								<td >55</td>
								<td >Rutuj Waman Hukare </td>
								<td >Siemens</td>
								<td >Finance</td>
								<td >6.5</td>
							</tr>
							<tr>
								<td >56</td>
								<td >Adnan Ismail Attar</td>
								<td >Nj Group</td>
								<td >Finance</td>
								<td >6.21</td>
							</tr>
							<tr>
								<td >57</td>
								<td >Astha Kumari </td>
								<td >Saint Gobain</td>
								<td >Marketing</td>
								<td >6.2</td>
							</tr>
							<tr>
								<td >58</td>
								<td >Ajinkya Vijay Kahalkar</td>
								<td >Global Market Insights</td>
								<td >Marketing</td>
								<td >6.12</td>
							</tr>
							<tr>
								<td >59</td>
								<td >Komal Vasanta Uikey</td>
								<td >Emcure Pharmaceuticals</td>
								<td >Operation</td>
								<td >6</td>
							</tr>
							<tr>
								<td >60</td>
								<td >Achal Subhash Bhogekar </td>
								<td >Vibe Media Group</td>
								<td >Marketing</td>
								<td >6</td>
							</tr>
							<tr>
								<td >61</td>
								<td >Dnyandeep Ashok Gawande</td>
								<td >Searce</td>
								<td >Finance</td>
								<td >6</td>
							</tr>
							<tr>
								<td >62</td>
								<td >Ajay Bhagwanrao Bokare </td>
								<td >Vijay Investedge Pvt. Ltd.</td>
								<td >Marketing</td>
								<td >6</td>
							</tr>
							<tr>
								<td >63</td>
								<td >Anuj Mudgal</td>
								<td >Prism Johnson Limited</td>
								<td >Marketing</td>
								<td >6</td>
							</tr>
							<tr>
								<td >64</td>
								<td >Prasad Vijaykumar Kadam</td>
								<td >Vibe Media Group</td>
								<td >Marketing</td>
								<td >6</td>
							</tr>
							<tr>
								<td >65</td>
								<td >Punit</td>
								<td >Heaptrace Technology.</td>
								<td >Marketing</td>
								<td >6</td>
							</tr>
							<tr>
								<td >66</td>
								<td >Saurabh Devidas Bakal </td>
								<td >Intellect Design Arena</td>
								<td >Marketing</td>
								<td >6</td>
							</tr>
							<tr>
								<td >67</td>
								<td >Juhi Anil Kurhe</td>
								<td >American Express</td>
								<td >Marketing</td>
								<td >6</td>
							</tr>
							<tr>
								<td >68</td>
								<td >Vaishnavi Dnyaneshwar Dhote</td>
								<td >Prism Johnson Limited</td>
								<td >Marketing</td>
								<td >6</td>
							</tr>
							<tr>
								<td >69</td>
								<td >Ayushi Sushil Jain</td>
								<td >Radamatic Solutions Pvt Ltd </td>
								<td >HR</td>
								<td >6</td>
							</tr>
							<tr>
								<td >70</td>
								<td >Kajal Suresh Dave</td>
								<td >Tata Technologies</td>
								<td >Finance</td>
								<td >5.95</td>
							</tr>
							<tr>
								<td >71</td>
								<td >Akash Prakash Neje</td>
								<td >Tata Technologies</td>
								<td >Operation</td>
								<td >5.95</td>
							</tr>
							<tr>
								<td >72</td>
								<td >Lochan Dindayal Bawankule </td>
								<td >Tata Technologies</td>
								<td >Marketing</td>
								<td >5.95</td>
							</tr>
							<tr>
								<td >73</td>
								<td >Shalaka Avinash Sule</td>
								<td >Tata Consultancy Services (TCS)</td>
								<td >HR</td>
								<td >5.79</td>
							</tr>
							<tr>
								<td >74</td>
								<td >Abhijeet Arvind Patange </td>
								<td >Bajaj Capital</td>
								<td >Finance</td>
								<td >5.6</td>
							</tr>
							<tr>
								<td >75</td>
								<td >Vaibhav Pravin Ghadge </td>
								<td >Bajaj Capital</td>
								<td >Finance</td>
								<td >5.6</td>
							</tr>
							<tr>
								<td >76</td>
								<td >Vikas Rajendra Shingote</td>
								<td >HDFC Ergo General Insurance</td>
								<td >Finance</td>
								<td >5.5</td>
							</tr>
							<tr>
								<td >77</td>
								<td >Omkar Avinash Ghanekar </td>
								<td >Markets and Markets</td>
								<td >Operation</td>
								<td >5.5</td>
							</tr>
							<tr>
								<td >78</td>
								<td >Nirbhay Shukla </td>
								<td >Housiey</td>
								<td >Marketing</td>
								<td >5.5</td>
							</tr>
							<tr>
								<td >79</td>
								<td >Vedant Shrikant Kulkarni</td>
								<td >HDFC Ergo General Insurance</td>
								<td >Marketing</td>
								<td >5.5</td>
							</tr>
							<tr>
								<td >80</td>
								<td >Varun Subodh Mathur</td>
								<td >Grand View Research</td>
								<td >Marketing</td>
								<td >5.37</td>
							</tr>
							<tr>
								<td >81</td>
								<td >Swapnil Chintaman Pathade</td>
								<td >Arofine Polymers</td>
								<td >Marketing</td>
								<td >5.3</td>
							</tr>
							<tr>
								<td >82</td>
								<td >Chaitrali Rajesh Khandge </td>
								<td >TK Elevators</td>
								<td >Finance</td>
								<td >5</td>
							</tr>
							<tr>
								<td >83</td>
								<td >Sejal Sunil Sahu</td>
								<td >TK Elevators</td>
								<td >Finance</td>
								<td >5</td>
							</tr>
							<tr>
								<td >84</td>
								<td >Ashutosh Kailas More </td>
								<td >Anand Rathi Shares and Stock Brokers Ltd.</td>
								<td >Finance</td>
								<td >5</td>
							</tr>
							<tr>
								<td >85</td>
								<td >Govind Vijayprakash Tapadiya </td>
								<td >Anand Rathi</td>
								<td >Finance</td>
								<td >5</td>
							</tr>
							<tr>
								<td >86</td>
								<td >Rohal Deepak Kardile</td>
								<td >Globeflex Research India </td>
								<td >Finance</td>
								<td >5</td>
							</tr>
							<tr>
								<td >87</td>
								<td >Amol Sahebrao Rathod </td>
								<td >Arriba Labs</td>
								<td >Marketing</td>
								<td >5</td>
							</tr>
							<tr>
								<td >88</td>
								<td >Sourabh Dhanaji Pote </td>
								<td >Bajaj Capital</td>
								<td >Finance</td>
								<td >4.8</td>
							</tr>
							<tr>
								<td >89</td>
								<td >Mayuresh Bhaskar Gahane </td>
								<td >HDFC Life</td>
								<td >Finance</td>
								<td >4.75</td>
							</tr>
							<tr>
								<td >90</td>
								<td >Ravi Narayan Tirukhe</td>
								<td >HDFC Life</td>
								<td >Marketing</td>
								<td >4.75</td>
							</tr>
							<tr>
								<td >91</td>
								<td >Pratik Rajkumar Mohod </td>
								<td >Axis Bank</td>
								<td >Finance</td>
								<td >4.64</td>
							</tr>
							<tr>
								<td >92</td>
								<td >Damini Mishra </td>
								<td >Himalaya Wellness</td>
								<td >Marketing</td>
								<td >4.5</td>
							</tr>
							<tr>
								<td >93</td>
								<td >Abhishek Shelke</td>
								<td >ADM Group</td>
								<td >Operation</td>
								<td >4.5</td>
							</tr>
							<tr>
								<td >94</td>
								<td >Ashay Gangadhar Langde </td>
								<td >Ultratech Cement </td>
								<td >Marketing</td>
								<td >4.5</td>
							</tr>
							<tr>
								<td >95</td>
								<td >Sumeet Chandrajeet Yadav</td>
								<td >ITC Ltd.</td>
								<td >Marketing</td>
								<td >4.5</td>
							</tr>
							<tr>
								<td >96</td>
								<td >Shrushti Yashwant Patil</td>
								<td >Himalaya Wellness</td>
								<td >Marketing</td>
								<td >4.5</td>
							</tr>
							<tr>
								<td >97</td>
								<td >Mrinal Bhimarao Patil </td>
								<td >LTI Mindtree</td>
								<td >Marketing</td>
								<td >4.5</td>
							</tr>
							<tr>
								<td >98</td>
								<td >Atharva Lakhe</td>
								<td >EY(Ernst & Young)</td>
								<td >Finance</td>
								<td >4.46</td>
							</tr>
							<tr>
								<td >99</td>
								<td >Hemant Pravin Gite</td>
								<td >Parekh Intergrated Services Ltd.</td>
								<td >Operation</td>
								<td >4</td>
							</tr>
							<tr>
								<td >100</td>
								<td >Akshay Sanjay Daga</td>
								<td >Justo</td>
								<td >Marketing</td>
								<td >4</td>
							</tr>
							<tr>
								<td >101</td>
								<td >Pratik Deepak Kumar</td>
								<td >Bajaj Allianz life Insurance Company</td>
								<td >Marketing</td>
								<td >4</td>
							</tr>
							<tr>
								<td >102</td>
								<td >Nikita Namdeorao Dharpure</td>
								<td >WNS Global</td>
								<td >HR</td>
								<td >4</td>
							</tr>
							<tr>
								<td >103</td>
								<td >Vaibhav Rajiv Thorat</td>
								<td >KPMG</td>
								<td >Finance</td>
								<td >3.93</td>
							</tr>
							<tr>
								<td >104</td>
								<td >Aarti Shiwaji Dawange </td>
								<td >TML Business Services Limited.</td>
								<td >Finance</td>
								<td >3.5</td>
							</tr>
							<tr>
								<td >105</td>
								<td >Annapurna Bharat Rathod</td>
								<td >SG Analytics</td>
								<td >Finance</td>
								<td >3.5</td>
							</tr>
							<tr>
								<td >106</td>
								<td >Pinki Kailaschand Varma </td>
								<td >TML Business Services Limited.</td>
								<td >Finance</td>
								<td >3.5</td>
							</tr>
							<tr>
								<td >107</td>
								<td >Lipi Bowaria</td>
								<td >Zocdoc</td>
								<td >Operation</td>
								<td >3.5</td>
							</tr>
							<tr>
								<td >108</td>
								<td >Avantika Nilawar </td>
								<td >SG Analytics</td>
								<td >Operation</td>
								<td >3.5</td>
							</tr>
							<tr>
								<td >109</td>
								<td >Ajinkya Bhagwan Alhat </td>
								<td >Vibe Media Group</td>
								<td >Marketing</td>
								<td >3.5</td>
							</tr>
							<tr>
								<td >110</td>
								<td >Anchal Choudhary </td>
								<td >Neeyamo Enterprise Solutions Pvt. Ltd.</td>
								<td >HR</td>
								<td >3.5</td>
							</tr>
							<tr>
								<td >111</td>
								<td >Mangesh Vaijanath Shirole </td>
								<td >Emerson</td>
								<td >HR</td>
								<td >2.16</td>
							</tr>
							<tr>
								<td >112</td>
								<td >Prachi Mahesh Shelar </td>
								<td >Forbes Marshall</td>
								<td >HR</td>
								<td >2.16</td>
							</tr>
							<tr>
								<td >113</td>
								<td >Tanvi Kalpesh Kariya</td>
								<td >Business</td>
								<td >Finance</td>
								<td >-</td>
							</tr>
							<tr>
								<td >114</td>
								<td >Lakshit Jain</td>
								<td >Business</td>
								<td >Marketing</td>
								<td >-</td>
							</tr>
													

												</tbody>
											</Table>

										</div>
									</div>
								</div>
							</Tab>

							<Tab eventKey="2021-23" title="2021-23 Batch" className="whiteBackground paddingBottom3em">
								<div className="container">
									<div className="row">
										<div className="col-md-12">
											<h3 className="blockTitle">2021-23 Batch</h3>
											<Table class="table table-responsive table-bordered" responsive>
												<tbody>
													<tr ><td colspan="5"><b>Batch 2021-23 MBA Placement Summary </b></td></tr>
													<tr><td><b>Specialization</b></td><td><b>Placed Students</b></td><td><b>Total Students</b></td><td><b>Placed Percentage</b></td></tr>
													<tr><td><b>Marketing</b></td><td>56</td><td>59</td><td>94.92</td></tr>
													<tr><td><b>Finance</b></td><td>53</td><td>56</td><td>94.64</td></tr>
													<tr><td><b>HR</b></td><td>7</td><td>9</td><td>77.78</td></tr>
													<tr><td><b>Operation</b></td><td>9</td><td>9</td><td>100</td></tr>
													<tr><td><b>Total</b></td><td><b>125</b></td><td><b>133</b></td><td><b>93.98</b></td></tr>

												</tbody>
											</Table>

											<Table class="table table-responsive table-bordered" responsive>
												<tbody>
													<tr><td colspan="3"><b>Batch 2021-23 MBA Package Summary </b></td></tr>
													{/* <tr><td colspan="3"><b>Batch 2020-22 MBA Package Summary  </b></td></tr> */}

													<tr><td><b>Specialization</b></td><td><b>Min Package LPA</b></td><td><b>Highest Package LPA</b></td></tr>
													<tr><td><b>Marketing</b></td><td>2.4</td><td>10.07 (MarketsandMarkets Research Pvt. Ltd)</td></tr>
													<tr><td><b>Finance</b></td><td>4.5</td><td>13.58 (Federal Bank)</td></tr>
													<tr><td><b>HR</b></td><td>4.5</td><td>7 (Prism Johnson Limited)</td></tr>
													<tr><td><b>Operation</b></td><td>5</td><td>9.5 (Writer Corporation )</td></tr>

												</tbody>
											</Table>
											<Table class="table table-responsive table-bordered" responsive>

												<tbody>
													<tr>
														<td className="text-center" colSpan={5}><b>Placed Students of Batch 2021-23</b></td>

													</tr>
													<tr>
														<td ><b>Sr No.</b></td>
														<td ><b>Students Name</b></td>
														<td ><b>Name of the Company</b></td>
														<td ><b>Specialization(Major)</b></td>
														<td ><b>Package in LPA INR</b></td>
													</tr>
													<tr>
														<td >1</td>
														<td >Amruta Sanjay Khadke</td>
														<td >Federal bank</td>
														<td >Finance</td>
														<td >13.58</td>
													</tr>
													<tr>
														<td >2</td>
														<td >Ankita Ramesh Ahire</td>
														<td >Federal bank</td>
														<td >Finance</td>
														<td >13.58</td>
													</tr>
													<tr>
														<td >3</td>
														<td >Chandrama Chandrashekhar Sonawane</td>
														<td >Federal bank</td>
														<td >Finance</td>
														<td >13.58</td>
													</tr>
													<tr>
														<td >4</td>
														<td >Krutil Kailas Patel</td>
														<td >Markets & Markets</td>
														<td >Marketing</td>
														<td >10.07</td>
													</tr>
													<tr>
														<td >5</td>
														<td >Harshad Rameshrao Wankhade</td>
														<td >Writer Corporation </td>
														<td >Operation</td>
														<td >9.5</td>
													</tr>
													<tr>
														<td >6</td>
														<td >Chetan Vijay Masram</td>
														<td >Bonito Design</td>
														<td >Marketing</td>
														<td >9.45</td>
													</tr>
													<tr>
														<td >7</td>
														<td >Mayur Dilip Chaudhari</td>
														<td >Bonito Design</td>
														<td >Marketing</td>
														<td >9.45</td>
													</tr>
													<tr>
														<td >8</td>
														<td >Saurabh Bhimrao Panade</td>
														<td >Bonito Design</td>
														<td >Marketing</td>
														<td >9.45</td>
													</tr>
													<tr>
														<td >9</td>
														<td >Shruti Machindra Awaskar</td>
														<td >Bonito Design</td>
														<td >Marketing</td>
														<td >9.45</td>
													</tr>
													<tr>
														<td >10</td>
														<td >Aditya Sanjay Digraskar</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >11</td>
														<td >Amit Chhailbihari Agarwal</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >12</td>
														<td >Anurag Mohan Goud Garugula</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >13</td>
														<td >Bhagyashree Devidas Sawarkar</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >14</td>
														<td >Bhushan Ramchandra Ahire</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >15</td>
														<td >Chintan Prashant Wagh</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >16</td>
														<td >Gayatri Govardhan Musande</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >17</td>
														<td >Hrishikesh Dattatray Kotlapure</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >18</td>
														<td >Jayesh Diliprao Pawar</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >19</td>
														<td >Kirtee Mallikarjun Kore</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >20</td>
														<td >Muskan Kukreja</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >21</td>
														<td >Pranav Vilas Shewale</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >22</td>
														<td >Rahul Digambar Idhate</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >23</td>
														<td >Riya Modi</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >24</td>
														<td >Sakshi Kailas Sarda</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >25</td>
														<td >Saurabh Ashok Singh</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >26</td>
														<td >Shamal Pramod Kakad</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >27</td>
														<td >Shrikant Gulabrao Khond</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >28</td>
														<td >Sneha Santosh Dodekar</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >29</td>
														<td >Yash Mahendra</td>
														<td >HDFC Bank Ltd</td>
														<td >Finance</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >30</td>
														<td >Sandip Dilip Jadhav</td>
														<td >HDFC Bank Ltd</td>
														<td >Marketing</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >31</td>
														<td >Siddhesh Sanjay Magar</td>
														<td >HDFC Bank Ltd</td>
														<td >Marketing</td>
														<td >8.64</td>
													</tr>
													<tr>
														<td >32</td>
														<td >Harshal Vilas Patil</td>
														<td >Prime Assets Realty Pvt. Ltd.</td>
														<td >Marketing</td>
														<td >8.12</td>
													</tr>
													<tr>
														<td >33</td>
														<td >Sagar Devidas Dhule</td>
														<td >ANZ Bank </td>
														<td >Finance</td>
														<td >8</td>
													</tr>
													<tr>
														<td >34</td>
														<td >Abhishek Sanjay Rajguru</td>
														<td >Nihilent Technologies</td>
														<td >Marketing</td>
														<td >8</td>
													</tr>
													<tr>
														<td >35</td>
														<td >Mayuri Prashant Waichal</td>
														<td >Nelsen IQ</td>
														<td >Marketing</td>
														<td >8</td>
													</tr>
													<tr>
														<td >36</td>
														<td >Parag Mahendra Mehta</td>
														<td >Nihilent Technologies</td>
														<td >Marketing</td>
														<td >8</td>
													</tr>
													<tr>
														<td >37</td>
														<td >Divya Kishor Kabra</td>
														<td >Deloitte (US Taxation)</td>
														<td >Finance</td>
														<td >7.6</td>
													</tr>
													<tr>
														<td >38</td>
														<td >Riya Rajkumar Soni</td>
														<td >Deloitte (US Taxation)</td>
														<td >Finance</td>
														<td >7.6</td>
													</tr>
													<tr>
														<td >39</td>
														<td >Suchita Pravin Patil</td>
														<td >Deloitte (US Taxation)</td>
														<td >Finance</td>
														<td >7.6</td>
													</tr>
													<tr>
														<td >40</td>
														<td >Abhishek Balasaheb Kalaskar</td>
														<td >Trinamix</td>
														<td >Operation</td>
														<td >7.5</td>
													</tr>
													<tr>
														<td >41</td>
														<td >Simron Birendra Sipani</td>
														<td >Aditya Birla Group</td>
														<td >Marketing</td>
														<td >7.5</td>
													</tr>
													<tr>
														<td >42</td>
														<td >Vikas Tatyaso Pawar</td>
														<td >Aditya Birla Group</td>
														<td >Marketing</td>
														<td >7.5</td>
													</tr>
													<tr>
														<td >43</td>
														<td >Abhishek Anil Patil</td>
														<td >UTI AMC Ltd</td>
														<td >Finance</td>
														<td >7.46</td>
													</tr>
													<tr>
														<td >44</td>
														<td >Ashwin Gajanan Gole</td>
														<td >HDFC Bank Ltd</td>
														<td >Marketing</td>
														<td >7.46</td>
													</tr>
													<tr>
														<td >45</td>
														<td >Atit Babarao Herole</td>
														<td >HDFC Bank Ltd</td>
														<td >Marketing</td>
														<td >7.46</td>
													</tr>
													<tr>
														<td >46</td>
														<td >Kishan Mahesh Mundhada</td>
														<td >HDFC Bank Ltd</td>
														<td >Marketing</td>
														<td >7.46</td>
													</tr>
													<tr>
														<td >47</td>
														<td >Satya Prakash Mishra</td>
														<td >HDFC Bank Ltd </td>
														<td >Marketing</td>
														<td >7.46</td>
													</tr>
													<tr>
														<td >48</td>
														<td >Shreyash Rajan Humne</td>
														<td >HDFC Bank Ltd </td>
														<td >Marketing</td>
														<td >7.46</td>
													</tr>
													<tr>
														<td >49</td>
														<td >Shreyas Vijaykumar Pardeshi</td>
														<td >HDFC Bank Ltd </td>
														<td >Marketing</td>
														<td >7.46</td>
													</tr>
													<tr>
														<td >50</td>
														<td >Tejashri Vijay Deore</td>
														<td >HDFC Bank Ltd </td>
														<td >Marketing</td>
														<td >7.46</td>
													</tr>
													<tr>
														<td >51</td>
														<td >Akash Rajwant Sharma</td>
														<td >Asian paints Ltd. </td>
														<td >Marketing</td>
														<td >7.35</td>
													</tr>
													<tr>
														<td >52</td>
														<td >Aditi Pandurang Deotkar</td>
														<td >TATA Bluescope Steel Pvt. Ltd.</td>
														<td >Marketing</td>
														<td >7.16</td>
													</tr>
													<tr>
														<td >53</td>
														<td >Mugdha Jayant Kulkarni</td>
														<td >Prism Johnson Ltd.</td>
														<td >HR</td>
														<td >7</td>
													</tr>
													<tr>
														<td >54</td>
														<td >Shrisha Ramkishan Tatikundalwar</td>
														<td >Ptechnosoft</td>
														<td >Marketing</td>
														<td >7</td>
													</tr>
													<tr>
														<td >55</td>
														<td >Vaishanvi Nitin Wani</td>
														<td >Nivea</td>
														<td >Marketing</td>
														<td >7</td>
													</tr>
													<tr>
														<td >56</td>
														<td >Omkar Rajesh Batwe</td>
														<td >Kaizen Institute</td>
														<td >Operation</td>
														<td >6.96</td>
													</tr>
													<tr>
														<td >57</td>
														<td >Aditya Mahadeo Daware</td>
														<td >Nestle</td>
														<td >Marketing</td>
														<td >6.9</td>
													</tr>
													<tr>
														<td >58</td>
														<td >Aishwarya Kishor Raut</td>
														<td >Xanadu Realty</td>
														<td >Marketing</td>
														<td >6.82</td>
													</tr>
													<tr>
														<td >59</td>
														<td >Bhalchandra Piraji Jawadwad</td>
														<td >Xanadu Realty</td>
														<td >Marketing</td>
														<td >6.82</td>
													</tr>
													<tr>
														<td >60</td>
														<td >Trushita Virendrakumar Katre</td>
														<td >TATA Bluescope Steel Pvt. Ltd.</td>
														<td >Marketing</td>
														<td >6.8</td>
													</tr>
													<tr>
														<td >61</td>
														<td >Samarpreet Singh Saluja</td>
														<td >Grand View Research</td>
														<td >Marketing</td>
														<td >6.57</td>
													</tr>
													<tr>
														<td >62</td>
														<td >Shubham Satish Wadnere</td>
														<td >Grand View Research</td>
														<td >Marketing</td>
														<td >6.57</td>
													</tr>
													<tr>
														<td >63</td>
														<td >Sirisha Venkatrao Mandhadi</td>
														<td >KPIT</td>
														<td >HR</td>
														<td >6.5</td>
													</tr>
													<tr>
														<td >64</td>
														<td >Gaurav Nanasaheb Chindhe</td>
														<td >Billion Base Camp</td>
														<td >Marketing</td>
														<td >6.5</td>
													</tr>
													<tr>
														<td >65</td>
														<td >Nehal kumar</td>
														<td >Hindware</td>
														<td >Marketing</td>
														<td >6.5</td>
													</tr>
													<tr>
														<td >66</td>
														<td >Shreyas Gopal Chaturbhuj</td>
														<td >Hindware</td>
														<td >Marketing</td>
														<td >6.5</td>
													</tr>
													<tr>
														<td >67</td>
														<td >Hemant Hiralal Kamble</td>
														<td >Vodaphone Idea Ltd.</td>
														<td >Marketing</td>
														<td >6.3</td>
													</tr>
													<tr>
														<td >68</td>
														<td >Palash Bandu Khobragade</td>
														<td >Saint-Gobain</td>
														<td >Marketing</td>
														<td >6.2</td>
													</tr>
													<tr>
														<td >69</td>
														<td >Pawan Suresh Bhatkulkar</td>
														<td >Veracity Investment Management</td>
														<td >Finance</td>
														<td >6.16</td>
													</tr>
													<tr>
														<td >70</td>
														<td >Adarsh Kumar Jha</td>
														<td >ANZ Bank </td>
														<td >Finance</td>
														<td >6</td>
													</tr>
													<tr>
														<td >71</td>
														<td >Dhiraj Bhausaheb Patil</td>
														<td >Kalolwala & Associates Pvt. Ltd.  </td>
														<td >Finance</td>
														<td >6</td>
													</tr>
													<tr>
														<td >72</td>
														<td >Juhi Mahesh Vachhani</td>
														<td >ANZ Bank </td>
														<td >Finance</td>
														<td >6</td>
													</tr>
													<tr>
														<td >73</td>
														<td >Vishal Laxman Tanpure</td>
														<td >ANZ Bank </td>
														<td >Finance</td>
														<td >6</td>
													</tr>
													<tr>
														<td >74</td>
														<td >Anurag Balasaheb Abnave</td>
														<td >PWC</td>
														<td >Operation</td>
														<td >6</td>
													</tr>
													<tr>
														<td >75</td>
														<td >Meghna Pramod Adhaoo</td>
														<td >Mahindra Logistics </td>
														<td >Operation</td>
														<td >6</td>
													</tr>
													<tr>
														<td >76</td>
														<td >Omkar Sharad Gurav</td>
														<td >Reliance Retail </td>
														<td >Operation</td>
														<td >6</td>
													</tr>
													<tr>
														<td >77</td>
														<td >Yogeshwar Sanjay Nighojkar</td>
														<td >Mahindra Logistics </td>
														<td >Operation</td>
														<td >6</td>
													</tr>
													<tr>
														<td >78</td>
														<td >Padmaja Bhanuprakash Patil</td>
														<td >Reliance Retail </td>
														<td >HR</td>
														<td >6</td>
													</tr>
													<tr>
														<td >79</td>
														<td >Priyadarshni Ramesh Kamble</td>
														<td >Bike Bazaar Finance</td>
														<td >HR</td>
														<td >6</td>
													</tr>
													<tr>
														<td >80</td>
														<td >Amar Vasant Dadibude</td>
														<td >Reliance Retail</td>
														<td >Marketing</td>
														<td >6</td>
													</tr>
													<tr>
														<td >81</td>
														<td >Apeksha Madhav Joglekar</td>
														<td >Wipro Enterprises Pvt.Ltd</td>
														<td >Marketing</td>
														<td >6</td>
													</tr>
													<tr>
														<td >82</td>
														<td >Prasad Shivaji Nalawade</td>
														<td >Straits Research Pvt. Ltd.</td>
														<td >Marketing</td>
														<td >6</td>
													</tr>
													<tr>
														<td >83</td>
														<td >Rutuja Narayan Kale</td>
														<td >Straits Research Pvt. Ltd.</td>
														<td >Marketing</td>
														<td >6</td>
													</tr>
													<tr>
														<td >84</td>
														<td >Shivraj Jaganrao Sonawane</td>
														<td >Reliance Retail</td>
														<td >Marketing</td>
														<td >6</td>
													</tr>
													<tr>
														<td >85</td>
														<td >Shubham Bhalchandra Rajhans</td>
														<td >TATA Technology</td>
														<td >Finance</td>
														<td >5.9</td>
													</tr>
													<tr>
														<td >86</td>
														<td >Piyusha Nitin Patil</td>
														<td >TCS</td>
														<td >HR</td>
														<td >5.75</td>
													</tr>
													<tr>
														<td >87</td>
														<td >Kumar Satish Motagi</td>
														<td >Zydus Wellness</td>
														<td >Marketing</td>
														<td >5.5</td>
													</tr>
													<tr>
														<td >88</td>
														<td >Shweta Ravikant Narwade</td>
														<td >Tech Mahindra</td>
														<td >Marketing</td>
														<td >5.5</td>
													</tr>
													<tr>
														<td >89</td>
														<td >Vaidehi Sudhir Kulkarni</td>
														<td >Veracity Investment Management</td>
														<td >Finance</td>
														<td >5.32</td>
													</tr>
													<tr>
														<td >90</td>
														<td >Avadhut Shashikant Mahamuni</td>
														<td >Global Market Insights</td>
														<td >Marketing</td>
														<td >5.28</td>
													</tr>
													<tr>
														<td >91</td>
														<td >Gauri Pavan Dabi</td>
														<td >Global Market Insights</td>
														<td >Marketing</td>
														<td >5.28</td>
													</tr>
													<tr>
														<td >92</td>
														<td >Saurabh Abhayrao Ambekar</td>
														<td >Global Market Insights</td>
														<td >Marketing</td>
														<td >5.28</td>
													</tr>
													<tr>
														<td >93</td>
														<td >Onkar Dattatray Gandule</td>
														<td >Acuity Knowledge Partners</td>
														<td >Finance</td>
														<td >5.16</td>
													</tr>
													<tr>
														<td >94</td>
														<td >Prachi Ganesh Bhavsar</td>
														<td >Acuity Knowledge Partners</td>
														<td >Finance</td>
														<td >5.16</td>
													</tr>
													<tr>
														<td >95</td>
														<td >Saloni Mahendra Bhalerao</td>
														<td >AU Small Finance Bank Limited</td>
														<td >Finance</td>
														<td >5</td>
													</tr>
													<tr>
														<td >96</td>
														<td >Pratik Prabhakar Patil</td>
														<td >MSKH Seatings India Pvt. Ltd.</td>
														<td >Operation</td>
														<td >5</td>
													</tr>
													<tr>
														<td >97</td>
														<td >Sagar Sunil Kasar</td>
														<td >e-Emphasys </td>
														<td >Operation</td>
														<td >5</td>
													</tr>
													<tr>
														<td >98</td>
														<td >Prashant Kiran Gadhari</td>
														<td >Indiamart</td>
														<td >Marketing</td>
														<td >5</td>
													</tr>
													<tr>
														<td >99</td>
														<td >Manisha Amit Dey</td>
														<td >Pinnacle industries ltd</td>
														<td >Marketing</td>
														<td >4.8</td>
													</tr>
													<tr>
														<td >100</td>
														<td >Nikita Vaman Bhoomkar</td>
														<td >S&P Global</td>
														<td >Finance</td>
														<td >4.75</td>
													</tr>
													<tr>
														<td >101</td>
														<td >Abhijeet Sanjaykumar Phadnis</td>
														<td >Kotak Mahindra Bank</td>
														<td >Finance</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >102</td>
														<td >Aditya Milind Borse</td>
														<td >Axis Bank</td>
														<td >Finance</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >103</td>
														<td >Ashish Suryakant Aher</td>
														<td >Ujjivan Small Finance Bank</td>
														<td >Finance</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >104</td>
														<td >Harshal Devidasrao Tembhe</td>
														<td >Kotak Mahindra Bank</td>
														<td >Finance</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >105</td>
														<td >Hitesh Narayan Desale</td>
														<td >Kotak Mahindra Bank</td>
														<td >Finance</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >106</td>
														<td >Janhavi Sunil Raut</td>
														<td >Kotak Mahindra Bank</td>
														<td >Finance</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >107</td>
														<td >Lalit Magan Ahire</td>
														<td >Kotak Mahindra Bank</td>
														<td >Finance</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >108</td>
														<td >Prafull Rajendra Deokar</td>
														<td >LTI Mindtree</td>
														<td >Finance</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >109</td>
														<td >Raj Sharad Panpaliya</td>
														<td >Kotak Mahindra Bank</td>
														<td >Finance</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >110</td>
														<td >Rutuja Rajesh Deshmukh</td>
														<td >Kotak Mahindra bank</td>
														<td >Finance</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >111</td>
														<td >Swarad Anand Deo</td>
														<td >LTI Mindtree</td>
														<td >Finance</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >112</td>
														<td >Shweta Sanjay Kirtane</td>
														<td >LTI Mindtree</td>
														<td >HR</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >113</td>
														<td >Trupti Subhashrao Deshmukh</td>
														<td >LTI Mindtree</td>
														<td >HR</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >114</td>
														<td >Ashwin Pradip Pawar</td>
														<td >Kolte Patil Developers Ltd</td>
														<td >Marketing</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >115</td>
														<td >Gaurav Hirachand Pawar</td>
														<td >Kotak Mahindra Bank </td>
														<td >Marketing</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >116</td>
														<td >Pratham Harish Joshi</td>
														<td >Kotak Mahindra Bank </td>
														<td >Marketing</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >117</td>
														<td >Rishab Biswas</td>
														<td >Kolte Patil Developers Ltd</td>
														<td >Marketing</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >118</td>
														<td >Shantanu Mansing Kate</td>
														<td >Kolte Patil Developers</td>
														<td >Marketing</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >119</td>
														<td >Vikrant Kalyan Jadhav</td>
														<td >Kotak Mahindra Bank</td>
														<td >Marketing</td>
														<td >4.5</td>
													</tr>
													<tr>
														<td >120</td>
														<td >Tejaswini Venktesh Padalwar</td>
														<td >EY(Ernst & Young)</td>
														<td >Finance</td>
														<td >4.46</td>
													</tr>
													<tr>
														<td >121</td>
														<td >Rahul Gautam Durge</td>
														<td >The Insight Partners</td>
														<td >Marketing</td>
														<td >4.40</td>
													</tr>
													<tr>
														<td >122</td>
														<td >Pritam Santosh Hanche</td>
														<td >TATA AIG Generel Insurance</td>
														<td >Finance</td>
														<td >3.25</td>
													</tr>
													<tr>
														<td >123</td>
														<td >Chetan Narendra Dhawale</td>
														<td >Au Small Finance Bank</td>
														<td >Marketing</td>
														<td >3</td>
													</tr>
													<tr>
														<td >124</td>
														<td >Aditya Sanjay Rayrikar</td>
														<td >Jaguar land rover</td>
														<td >Marketing</td>
														<td >2.4</td>
													</tr>
													<tr>
														<td >125</td>
														<td >Jitendra Pomaram Choudhary</td>
														<td >Entrepreneur</td>
														<td >Marketing</td>
														<td >-</td>
													</tr>
												</tbody>
											</Table>

										</div>
									</div>
								</div>
							</Tab>
							<Tab eventKey="2020-22" title="2020-22 Batch" className="whiteBackground paddingBottom3em">
								<div className="container">
									<div className="row">
										<div className="col-md-12">
											<h3 className="blockTitle">2020-22 Batch</h3>
											<Table class="table table-responsive table-bordered" responsive>
												<tbody>
													<tr ><td colspan="4"><b>Batch 2020-22 MBA Placement Summary </b></td></tr>

													<tr><td><b>Specialization</b></td><td><b>Placed Students</b></td><td><b>Total Students</b></td><td><b>Placed Percentage</b></td></tr>
													<tr><td><b>Marketing</b></td><td>63</td><td>64</td><td>98.44</td></tr>
													<tr><td><b>Finance</b></td><td>47</td><td>48</td><td>97.92</td></tr>
													<tr><td><b>HR</b></td><td>9</td><td>9</td><td>100</td></tr>
													<tr><td><b>Operation</b></td><td>13</td><td>13</td><td>100</td></tr>
													<tr><td><b>Total</b></td><td><b>132</b></td><td><b>134</b></td><td><b>98.51</b></td></tr>
													{/* <tr><td><b>Marketing</b></td><td>58</td><td>62</td><td>93.54</td><td>4</td></tr>
                                                    <tr><td><b>Finance</b></td><td>42</td><td>47</td><td>89.36</td><td>5</td></tr>
                                                    <tr><td><b>HR</b></td><td>9</td><td>9</td><td>100</td><td>0</td></tr>
                                                    <tr><td><b>Operation</b></td><td>13</td><td>13</td><td>100</td><td>0</td></tr>
                                                    <tr><td><b>Total</b></td><td><b>122</b></td><td><b>131</b></td><td><b>93.12</b></td><td><b>9</b></td>
													</tr> */}
												</tbody>
											</Table>

											<Table class="table table-responsive table-bordered" responsive>
												<tbody>
													{/* <tr><td colspan="3"><b>Batch 2020-22 MBA Package Summary Till 16 May2023</b></td></tr> */}
													<tr><td colspan="3"><b>Batch 2020-22 MBA Package Summary </b></td></tr>
													{/* <tr><td></td><td><b>Mean Package</b></td><td><b>Highest Package</b></td></tr>
                                                <tr><td><b>Marketing</b></td><td>6.09</td><td>12(Alight Solution)</td></tr>
                                                <tr><td><b>Finance</b></td><td>5.65</td><td>9.52(FIS Global)</td></tr>
                                                <tr><td><b>HR</b></td><td>5.06</td><td>7.6(Deloitte)</td></tr>
                                                <tr><td><b>Operation</b></td><td>5.9</td><td>11(XpressBees)</td></tr>
                                                <tr><td><b>Total</b></td><td><b>5.67</b></td><td><b>12(Alight Solution)</b></td></tr> */}
													<tr><td><b>Specialization</b></td><td><b>Min Package</b></td><td><b>Highest Package</b></td></tr>
													<tr><td><b>Marketing</b></td><td>2.5</td><td>12 (Alight Solution and Skyhigh Security)</td></tr>
													<tr><td><b>Finance</b></td><td>2.5</td><td>9.52 (FIS Global)</td></tr>
													<tr><td><b>HR</b></td><td>4</td><td>7.6 (Deloitte(KS))</td></tr>
													<tr><td><b>Operation</b></td><td>4</td><td>11 (XpressBees)</td></tr>

												</tbody>
											</Table>
											<Table class="table table-responsive table-bordered" responsive>
												<tbody>
													<tr>
														<td className="text-center" colspan="5"><b>Placed Students of Batch 2020-22</b></td>

													</tr>
													<tr>
														<td ><b>Sr No.</b></td>
														<td ><b>Students Name</b></td>
														<td ><b>Name of the Company</b></td>
														<td ><b>Specialization(Major)</b></td>
														<td ><b>Package in LPA INR</b></td>
													</tr>
													<tr>
														<td>1</td>
														<td>Naysaa Ahmed</td>
														<td>Alight Solution</td>
														<td>Marketing</td>
														<td>12</td>
													</tr>
													<tr>
														<td>2</td>
														<td>Nupoor Balasaheb Tile</td>
														<td>Skyhigh Security </td>
														<td>Marketing</td>
														<td>12</td>
													</tr>
													<tr>
														<td>3</td>
														<td>Aarya Wadkar</td>
														<td>Skyhigh Security </td>
														<td>Marketing</td>
														<td>12</td>
													</tr>
													<tr>
														<td>4</td>
														<td>Prithviraj Shantilal Rathod</td>
														<td>Skyhigh Securities</td>
														<td>Operation</td>
														<td>11</td>
													</tr>
													<tr>
														<td>5</td>
														<td>Ashutosh Pagare</td>
														<td>XpressBees</td>
														<td>Operation</td>
														<td>11</td>
													</tr>
													<tr>
														<td>6</td>
														<td>Yashodeep Patil</td>
														<td>Bottomline</td>
														<td>Finance</td>
														<td>9.89</td>
													</tr>
													<tr>
														<td>7</td>
														<td>Aditi sachin Gaidhani</td>
														<td>FIS Global</td>
														<td>Finance</td>
														<td>9.52</td>
													</tr>
													<tr>
														<td>8</td>
														<td>Pranav Prakash Alone</td>
														<td>Hashedin by Deloitte</td>
														<td>Operation</td>
														<td>8.1</td>
													</tr>
													<tr>
														<td>9</td>
														<td>Ajinkya Patil</td>
														<td>Hashedin by Deloitte</td>
														<td>Finance</td>
														<td>8.1</td>
													</tr>
													<tr>
														<td>10</td>
														<td>Soham Shinde</td>
														<td>Hashedin by Deloitte</td>
														<td>Marketing</td>
														<td>8.1</td>
													</tr>
													<tr>
														<td>11</td>
														<td>Geetesh Jadhav</td>
														<td>Hashedin by Deloitte</td>
														<td>Marketing</td>
														<td>8.1</td>
													</tr>
													<tr>
														<td>12</td>
														<td>Kshitij Dhanvijay</td>
														<td>Hashedin by Deloitte</td>
														<td>Marketing</td>
														<td>8.1</td>
													</tr>
													<tr>
														<td>13</td>
														<td>Pooja Patil</td>
														<td>Hashedin by Deloitte</td>
														<td>Finance</td>
														<td>8.1</td>
													</tr>
													<tr>
														<td>14</td>
														<td>Sneha Chhayala</td>
														<td>NielsenIQ</td>
														<td>Marketing</td>
														<td>8</td>
													</tr>
													<tr>
														<td>15</td>
														<td>Kalpesh Dilip Patil</td>
														<td>NielsenIQ</td>
														<td>Marketing</td>
														<td>8</td>
													</tr>
													<tr>
														<td>16</td>
														<td>Pratik Arvind Chaudhari</td>
														<td>NielsenIQ</td>
														<td>Marketing</td>
														<td>8</td>
													</tr>
													<tr>
														<td>17</td>
														<td>Sanket Sonawane</td>
														<td>NielsenIQ</td>
														<td>Marketing</td>
														<td>8</td>
													</tr>
													<tr>
														<td>18</td>
														<td>Sangram Sanjay Shinde</td>
														<td>NielsenIQ</td>
														<td>Marketing</td>
														<td>8</td>
													</tr>
													<tr>
														<td>19</td>
														<td>Aishwarya Laxmarao Chate</td>
														<td>NielsenIQ</td>
														<td>Marketing</td>
														<td>8</td>
													</tr>
													<tr>
														<td>20</td>
														<td>Gokul Devising Rathod</td>
														<td>NielsenIQ</td>
														<td>Marketing</td>
														<td>8</td>
													</tr>
													<tr>
														<td>21</td>
														<td>Mayur Babaladi</td>
														<td>NielsenIQ</td>
														<td>Marketing</td>
														<td>8</td>
													</tr>
													<tr>
														<td>22</td>
														<td>Shubham Kinge</td>
														<td>Deloitte(RFA)</td>
														<td>Marketing</td>
														<td>7.87</td>
													</tr>
													<tr>
														<td>23</td>
														<td>Bhavesh Bele</td>
														<td>Deloitte(RFA)</td>
														<td>Finance</td>
														<td>7.87</td>
													</tr>
													<tr>
														<td>24</td>
														<td>Neha Khandhar</td>
														<td>NielsenIQ</td>
														<td>Marketing</td>
														<td>7.75</td>
													</tr>
													<tr>
														<td>25</td>
														<td>Smruti Lachake</td>
														<td>Deloitte(KS)</td>
														<td>HR</td>
														<td>7.6</td>
													</tr>
													<tr>
														<td>26</td>
														<td>Anirudha Vispute</td>
														<td>Deloitte(US Tax)</td>
														<td>Finance</td>
														<td>7.6</td>
													</tr>
													<tr>
														<td>27</td>
														<td>Manya Mishra</td>
														<td>Deloitte(US Tax)</td>
														<td>Finance</td>
														<td>7.6</td>
													</tr>
													<tr>
														<td>28</td>
														<td>Shreya Bhosale</td>
														<td>Deloitte(US Tax)</td>
														<td>Finance</td>
														<td>7.6</td>
													</tr>
													<tr>
														<td>29</td>
														<td>Chinmay Sontake</td>
														<td>Deloitte(US Tax)</td>
														<td>Finance</td>
														<td>7.6</td>
													</tr>
													<tr>
														<td>30</td>
														<td>Prashant Razdan</td>
														<td>Makebot</td>
														<td>Marketing</td>
														<td>7</td>
													</tr>
													<tr>
														<td>31</td>
														<td>samiksha Shirsat</td>
														<td>Makebot</td>
														<td>Marketing</td>
														<td>7</td>
													</tr>
													<tr>
														<td>32</td>
														<td>Disha Oswal</td>
														<td>HDFC Ltd.</td>
														<td>Marketing</td>
														<td>6.9</td>
													</tr>
													<tr>
														<td>33</td>
														<td>Ruchita Wankhede</td>
														<td>HDFC Ltd.</td>
														<td>Finance</td>
														<td>6.9</td>
													</tr>
													<tr>
														<td>34</td>
														<td>Suraj Pandit Shinde</td>
														<td>HDFC Bank</td>
														<td>Marketing</td>
														<td>6.75</td>
													</tr>
													<tr>
														<td>35</td>
														<td>Rohit Sanjay Markad</td>
														<td>HDFC Bank</td>
														<td>Marketing</td>
														<td>6.75</td>
													</tr>
													<tr>
														<td>36</td>
														<td>Pavan Lokhande</td>
														<td>HDFC Bank</td>
														<td>Marketing</td>
														<td>6.75</td>
													</tr>
													<tr>
														<td>37</td>
														<td>Nandkishor Solanke</td>
														<td>HDFC Bank</td>
														<td>Finance</td>
														<td>6.75</td>
													</tr>
													<tr>
														<td>38</td>
														<td>Pratiksha Deshmukh</td>
														<td>HDFC Bank</td>
														<td>Finance</td>
														<td>6.75</td>
													</tr>
													<tr>
														<td>39</td>
														<td>Vishant Jadhav</td>
														<td>HDFC Bank</td>
														<td>Finance</td>
														<td>6.75</td>
													</tr>
													<tr>
														<td>40</td>
														<td>Janmejai Singh</td>
														<td>HDFC Bank</td>
														<td>Finance</td>
														<td>6.57</td>
													</tr>
													<tr>
														<td>41</td>
														<td>Saket Anil More</td>
														<td>HDFC Bank</td>
														<td>Finance</td>
														<td>6.57</td>
													</tr>
													<tr>
														<td>42</td>
														<td>Shyam Bedre</td>
														<td>Eclerx</td>
														<td>Finance</td>
														<td>6.53</td>
													</tr>
													<tr>
														<td>43</td>
														<td>Akshay Sahare</td>
														<td>Tata Croma (Infiniti Retail Limited) </td>
														<td>Operation</td>
														<td>6.5</td>
													</tr>
													<tr>
														<td>44</td>
														<td>Suyasha Hole</td>
														<td>Kantar Global Delivery Center</td>
														<td>Marketing</td>
														<td>6.5</td>
													</tr>
													<tr>
														<td>45</td>
														<td>Rachit Shah</td>
														<td>HDFC Ltd.</td>
														<td>Finance</td>
														<td>6.4</td>
													</tr>
													<tr>
														<td>46</td>
														<td>Sumit Deshmukh</td>
														<td>Trinamix</td>
														<td>Operation</td>
														<td>6</td>
													</tr>
													<tr>
														<td>47</td>
														<td>Evangeline Jebraj</td>
														<td>Catenon</td>
														<td>HR</td>
														<td>6</td>
													</tr>
													<tr>
														<td>48</td>
														<td>Meet Rajesh Purohit</td>
														<td>Catenon</td>
														<td>Marketing</td>
														<td>6</td>
													</tr>
													<tr>
														<td>49</td>
														<td>Ritvik Rastogi</td>
														<td>Catenon</td>
														<td>Marketing</td>
														<td>6</td>
													</tr>
													<tr>
														<td>50</td>
														<td>Insha Khan</td>
														<td>TATA Technology</td>
														<td>HR</td>
														<td>6</td>
													</tr>
													<tr>
														<td>51</td>
														<td>Rajvardhan Jiwaji Mohite</td>
														<td>Grand View Research</td>
														<td>Marketing </td>
														<td>6</td>
													</tr>
													<tr>
														<td>52</td>
														<td>Devendra Mahankale</td>
														<td>BAGIC</td>
														<td>Marketing</td>
														<td>6</td>
													</tr>
													<tr>
														<td>53</td>
														<td>Swamini Kulkarni</td>
														<td>PWC</td>
														<td>Finance</td>
														<td>6</td>
													</tr>
													<tr>
														<td>54</td>
														<td>Shardul Sunil Gondhali</td>
														<td>TATA Technology</td>
														<td>Marketing</td>
														<td>5.9</td>
													</tr>
													<tr>
														<td>55</td>
														<td>Pallavi Priya</td>
														<td>Asian Paints</td>
														<td>Marketing</td>
														<td>5.8</td>
													</tr>
													<tr>
														<td>56</td>
														<td>Sanket Diliprao Kulkarni</td>
														<td>PhonePe</td>
														<td>Marketing</td>
														<td>5.8</td>
													</tr>
													<tr>
														<td>57</td>
														<td>Utkarsh Narendra Mhetre</td>
														<td>PhonePe</td>
														<td>Marketing</td>
														<td>5.8</td>
													</tr>
													<tr>
														<td>58</td>
														<td>Yash Subhash Salunkhe</td>
														<td>PhonePe</td>
														<td>Marketing</td>
														<td>5.8</td>
													</tr>
													<tr>
														<td>59</td>
														<td>Pratik Mahajan</td>
														<td>TCS</td>
														<td>Marketing</td>
														<td>5.79</td>
													</tr>
													<tr>
														<td>60</td>
														<td>Shruti Supare</td>
														<td>TATA Technology</td>
														<td>Marketing</td>
														<td>5.75</td>
													</tr>
													<tr>
														<td>61</td>
														<td>Shubham Kandalkar</td>
														<td>TATA Technology</td>
														<td>Operation</td>
														<td>5.75</td>
													</tr>
													<tr>
														<td>62</td>
														<td>Piyush Warulkar</td>
														<td>Berger Paints</td>
														<td>Marketing</td>
														<td>5.75</td>
													</tr>
													<tr>
														<td>63</td>
														<td>Jatin Singhal</td>
														<td>PWC</td>
														<td>Finance</td>
														<td>5.52</td>
													</tr>
													<tr>
														<td>64</td>
														<td>Nikhita Somshekhar Rudagi</td>
														<td>PWC</td>
														<td>Finance</td>
														<td>5.52</td>
													</tr>
													<tr>
														<td>65</td>
														<td>Anjana</td>
														<td>PWC</td>
														<td>Finance</td>
														<td>5.52</td>
													</tr>
													<tr>
														<td>66</td>
														<td>SHIVANI SHINDE</td>
														<td>PWC</td>
														<td>Finance</td>
														<td>5.52</td>
													</tr>
													<tr>
														<td>67</td>
														<td>Anjali Mungase</td>
														<td>Infosys</td>
														<td>Finance</td>
														<td>5.5</td>
													</tr>
													<tr>
														<td>68</td>
														<td>Shubham Sharnagat</td>
														<td>Amura Marketing</td>
														<td>Marketing</td>
														<td>5</td>
													</tr>
													<tr>
														<td>69</td>
														<td>Apoorva Jadhav</td>
														<td>TechAim</td>
														<td>HR</td>
														<td>5</td>
													</tr>
													<tr>
														<td>70</td>
														<td>Moreshwar Dhonge</td>
														<td>IndiaMart</td>
														<td>Marketing</td>
														<td>5</td>
													</tr>
													<tr>
														<td>71</td>
														<td>Rahul Dubey</td>
														<td>IndiaMart</td>
														<td>Finance</td>
														<td>5</td>
													</tr>
													<tr>
														<td>72</td>
														<td>Mayur Pawar</td>
														<td>Kalyani</td>
														<td>Operation</td>
														<td>5</td>
													</tr>
													<tr>
														<td>73</td>
														<td>Akshay Pandhare</td>
														<td>TK Elevator</td>
														<td>Operation</td>
														<td>5</td>
													</tr>
													<tr>
														<td>74</td>
														<td>Mohit Ulhe</td>
														<td>Anand Rathi</td>
														<td>Finance</td>
														<td>5</td>
													</tr>
													<tr>
														<td>75</td>
														<td>Akashkumar Nawalkar</td>
														<td>Anand Rathi</td>
														<td>Finance</td>
														<td>5</td>
													</tr>
													<tr>
														<td>76</td>
														<td>Ayushi Taksale</td>
														<td>Global Market Insights</td>
														<td>Marketing</td>
														<td>5</td>
													</tr>
													<tr>
														<td>77</td>
														<td>Laxman Kulkarni</td>
														<td>Global Market Insights</td>
														<td>Marketing</td>
														<td>5</td>
													</tr>
													<tr>
														<td>78</td>
														<td>Sameer Shejwal</td>
														<td>Global Market Insights</td>
														<td>Marketing</td>
														<td>5</td>
													</tr>
													<tr>
														<td>79</td>
														<td>Sayali Dhumale</td>
														<td>Reliance Retail</td>
														<td>HR</td>
														<td>5</td>
													</tr>
													<tr>
														<td>80</td>
														<td>Roshni Rayappa</td>
														<td>Smiths Detection</td>
														<td>HR</td>
														<td>5</td>
													</tr>
													<tr>
														<td>81</td>
														<td>Sakshi Deshpande</td>
														<td>Intelliswift</td>
														<td>HR</td>
														<td>5</td>
													</tr>
													<tr>
														<td>82</td>
														<td>Shelke Rushikesh Pravinrao</td>
														<td>SG Group </td>
														<td>Marketing</td>
														<td>5</td>
													</tr>
													<tr>
														<td>83</td>
														<td>Nishant Doke</td>
														<td>Motilal Oswal</td>
														<td>Marketing</td>
														<td>5</td>
													</tr>
													<tr>
														<td>84</td>
														<td>Chaitanya Koli</td>
														<td>Justo</td>
														<td>Marketing</td>
														<td>4.8</td>
													</tr>
													<tr>
														<td>85</td>
														<td>Arun Bhaskar Shetty</td>
														<td>NatWest Group</td>
														<td>Finance</td>
														<td>4.68</td>
													</tr>
													<tr>
														<td>86</td>
														<td>Uday Dhananjay Khillare</td>
														<td>ICICI Prudential</td>
														<td>Marketing</td>
														<td>4.6</td>
													</tr>
													<tr>
														<td>87</td>
														<td>Pratiksha Amritkar</td>
														<td>HDFC Life</td>
														<td>Marketing</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>88</td>
														<td>Smruti Kadu</td>
														<td>HDFC Life</td>
														<td>Finance</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>89</td>
														<td>Shivam Gosavi</td>
														<td>HDFC Life</td>
														<td>Finance</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>90</td>
														<td>Mitesh Vasant Gadekar</td>
														<td>Winspire</td>
														<td>Finance</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>91</td>
														<td>Aditi</td>
														<td>Kotak Mahindra Bank</td>
														<td>Finance</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>92</td>
														<td>Mohit Chandane</td>
														<td>EVOSYS</td>
														<td>Operation</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>93</td>
														<td>Abhishek Gramopadhye</td>
														<td>Future Supply Chain Solutions Ltd</td>
														<td>Operation</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>94</td>
														<td>Radha Rahane</td>
														<td>HDFC Life</td>
														<td>Finance</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>95</td>
														<td>Ankita Shetty</td>
														<td>Anand Rathi</td>
														<td>Finance</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>96</td>
														<td>Yug Turkar</td>
														<td>Future Supply Chain Solutions Ltd</td>
														<td>Marketing</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>97</td>
														<td>Snehal Dhandare</td>
														<td>Future Supply Chain Solutions Ltd</td>
														<td>Finance</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>98</td>
														<td>Sachin John Vivek</td>
														<td>Future Supply Chain Solutions Ltd</td>
														<td>Operation</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>99</td>
														<td>Roshan Bhalerao</td>
														<td>Venew</td>
														<td>Marketing</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>100</td>
														<td>Abhishek Kumar Mishra</td>
														<td>Peninsula Land</td>
														<td>Marketing</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>101</td>
														<td>Prabhat Kumar</td>
														<td>HDFC Life</td>
														<td>Marketing</td>
														<td>4.2</td>
													</tr>
													<tr>
														<td>102</td>
														<td>Sanket Ranjane</td>
														<td>Broadnet Technology</td>
														<td>Marketing</td>
														<td>4.2</td>
													</tr>
													<tr>
														<td>103</td>
														<td>Shruti Ugle</td>
														<td>L&T Infotech</td>
														<td>Finance</td>
														<td>4.15</td>
													</tr>
													<tr>
														<td>104</td>
														<td>Shivani Sewaiwar</td>
														<td>L&T Infotech/Hashedin by Deloitte</td>
														<td>Marketing</td>
														<td>4.15</td>
													</tr>
													<tr>
														<td>105</td>
														<td>Niyati Sheth</td>
														<td>L&T Infotech</td>
														<td>HR</td>
														<td>4.15</td>
													</tr>
													<tr>
														<td>106</td>
														<td>Roshan Sonawane</td>
														<td>Adlift</td>
														<td>Marketing</td>
														<td>4</td>
													</tr>
													<tr>
														<td>107</td>
														<td>Abhijeet Sanjay Dhamdhere</td>
														<td>Ernst & Young </td>
														<td>Operation</td>
														<td>4</td>
													</tr>
													<tr>
														<td>108</td>
														<td>Sagar Shivaji Raut</td>
														<td>Ernst & Young </td>
														<td>Operation</td>
														<td>4</td>
													</tr>
													<tr>
														<td>109</td>
														<td>Anshul Mandwariya</td>
														<td>Jana Small Finance Bank</td>
														<td>Marketing</td>
														<td>4</td>
													</tr>
													<tr>
														<td>110</td>
														<td>Kunal Tikaramji Ghagre</td>
														<td>Jana Small Finance Bank</td>
														<td>Marketing</td>
														<td>4</td>
													</tr>
													<tr>
														<td>111</td>
														<td>Akshit Purohit</td>
														<td>Jana Small Finance Bank</td>
														<td>Finance</td>
														<td>4</td>
													</tr>
													<tr>
														<td>112</td>
														<td>Tushar Pandurang Tonge</td>
														<td>Jana Small Finance Bank</td>
														<td>Finance</td>
														<td>4</td>
													</tr>
													<tr>
														<td>113</td>
														<td>Prasad Lakras</td>
														<td>Inspacco</td>
														<td>Finance</td>
														<td>4</td>
													</tr>
													<tr>
														<td>114</td>
														<td>Pallavi Singh</td>
														<td>Jana Small Finance Bank</td>
														<td>Finance</td>
														<td>4</td>
													</tr>
													<tr>
														<td>115</td>
														<td>Shivani Holkar</td>
														<td>XpressBees</td>
														<td>HR</td>
														<td>4</td>
													</tr>
													<tr>
														<td>116</td>
														<td>Rajeshwari Ashok Patil</td>
														<td>ICICI Securities</td>
														<td>Finance</td>
														<td>4</td>
													</tr>
													<tr>
														<td>117</td>
														<td>Tejal Sanjay Bagade</td>
														<td>ICICI Securities</td>
														<td>Finance</td>
														<td>4</td>
													</tr>
													<tr>
														<td>118</td>
														<td>Kushal Sharadrao Ganeshe</td>
														<td>ICICI Securities</td>
														<td>Finance</td>
														<td>4</td>
													</tr>
													<tr>
														<td>119</td>
														<td>Saurabh Sanjay Yeola</td>
														<td>ICICI Prudential</td>
														<td>Finance</td>
														<td>4</td>
													</tr>
													<tr>
														<td>120</td>
														<td>Sidhant Jadhav</td>
														<td>Axis Bank</td>
														<td>Marketing</td>
														<td>4</td>
													</tr>
													<tr>
														<td>121</td>
														<td>Unnati Naginbhai Bariya</td>
														<td>Waveraco Consulting</td>
														<td>Marketing</td>
														<td>4</td>
													</tr>
													<tr>
														<td>122</td>
														<td>Satish Rathod</td>
														<td>Institute of Technology and Management</td>
														<td>Finance</td>
														<td>4</td>
													</tr>
													<tr>
														<td>123</td>
														<td>Saurabh katre</td>
														<td>Transparency</td>
														<td>Marketing</td>
														<td>3.75</td>
													</tr>
													<tr>
														<td>124</td>
														<td>Bhagyashri Thoke</td>
														<td>Transparency</td>
														<td>Marketing</td>
														<td>3.75</td>
													</tr>
													<tr>
														<td>125</td>
														<td>Shubham Pohkar</td>
														<td>Transparency</td>
														<td>Marketing</td>
														<td>3.75</td>
													</tr>
													<tr>
														<td>126</td>
														<td>Manjiri Kshemkalyani</td>
														<td>Morningstar</td>
														<td>Finance</td>
														<td>3.5</td>
													</tr>
													<tr>
														<td>127</td>
														<td>Rupesh Rajaram Patil</td>
														<td>Meta Option</td>
														<td>Marketing</td>
														<td>2.7</td>
													</tr>
													<tr>
														<td>128</td>
														<td>Paras Bansod</td>
														<td>AU Small Finance Bank</td>
														<td>Marketing</td>
														<td>2.5</td>
													</tr>
													<tr>
														<td>129</td>
														<td>Sahnu Sharad</td>
														<td>Million Minds Securities Pvt. Ltd</td>
														<td>Finance</td>
														<td>2.5</td>
													</tr>
													<tr>
														<td>130</td>
														<td>Prashant Singh Chauhan</td>
														<td>Family business</td>
														<td>Marketing</td>
														<td>-</td>
													</tr>
													<tr>
														<td>131</td>
														<td>Shubham Rajesh Agarwal</td>
														<td>Family business</td>
														<td>Finance</td>
														<td>-</td>
													</tr>
													<tr>
														<td>132</td>
														<td>Shrihari Pravinkumar Laddha</td>
														<td>Family business</td>
														<td>Marketing</td>
														<td>-</td>
													</tr>
												</tbody>
											</Table>

										</div>
									</div>
								</div>
							</Tab>
							<Tab eventKey="2019-21" title="2019-21 Batch" className="whiteBackground paddingBottom3em">
								<div className="container">
									<div className="row">
										<div className="col-md-12">
											<h3 className="blockTitle">2019-21 Batch</h3>
											<Table class="table table-responsive table-bordered" responsive>
												<tbody>
													<tr>
														<td><b>No</b></td>
														<td><b>Name</b></td>
														<td><b>Specialization</b></td>
														<td><b>Company Name</b></td>
														<td><b>Packages</b></td>
													</tr>
													<tr>
														<td>1</td>
														<td>Rhiddhi Khartadkar</td>
														<td>Marketing</td>
														<td>Deloitte(KS)</td>
														<td>5.5</td>
													</tr>
													<tr>
														<td>2</td>
														<td>Swapnil Nikam</td>
														<td>Marketing</td>
														<td>Deloitte(KS)</td>
														<td>5.5</td>
													</tr>
													<tr>
														<td>3</td>
														<td>Akshay Nilkanth Jawale</td>
														<td>Marketing</td>
														<td>Marico</td>
														<td>5.5</td>
													</tr>
													<tr>
														<td>4</td>
														<td>Anshul Jain</td>
														<td>Marketing</td>
														<td>ICICI Bank</td>
														<td>5</td>
													</tr>
													<tr>
														<td>5</td>
														<td>Pankaj Shankar Shedage</td>
														<td>Marketing</td>
														<td>ICICI Bank</td>
														<td>5</td>
													</tr>
													<tr>
														<td>6</td>
														<td>Ankush Ingle</td>
														<td>Marketing</td>
														<td>ITC</td>
														<td>4.43</td>
													</tr>
													<tr>
														<td>7</td>
														<td>Shruti Hinger</td>
														<td>Marketing</td>
														<td>ICICI Prudential</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>8</td>
														<td>Shubham Sanjay Naik</td>
														<td>Marketing</td>
														<td>ICICI Prudential</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>9</td>
														<td>Aniket Mahajan </td>
														<td>Marketing</td>
														<td>Berger Paint</td>
														<td>5.75</td>
													</tr>
													<tr>
														<td>10</td>
														<td>Adya Mishra</td>
														<td>Marketing</td>
														<td>TCS</td>
														<td>6.6</td>
													</tr>
													<tr>
														<td>11</td>
														<td>Viraj Sunil Shinde</td>
														<td>Marketing</td>
														<td>NoPaperForms</td>
														<td>7.5</td>
													</tr>
													<tr>
														<td>12</td>
														<td>Sumit Kotwani</td>
														<td>Marketing</td>
														<td>Reliance Retail</td>
														<td>5</td>
													</tr>
													<tr>
														<td>13</td>
														<td>Mohit Madurwar</td>
														<td>Marketing</td>
														<td>Property Pistol</td>
														<td>7.8</td>
													</tr>
													<tr>
														<td>14</td>
														<td>Nitesh Nath</td>
														<td>Marketing</td>
														<td>Property Pistol</td>
														<td>7.8</td>
													</tr>
													<tr>
														<td>15</td>
														<td>Aishwarya Ganjan Manatkar</td>
														<td>Marketing</td>
														<td>Tech Mahindra</td>
														<td>4.14</td>
													</tr>
													<tr>
														<td>16</td>
														<td>Mahima Modi</td>
														<td>Marketing</td>
														<td>Yojana Organic</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>17</td>
														<td>Riddhi Vadher</td>
														<td>Marketing</td>
														<td>New Vision Software</td>
														<td>3.5</td>
													</tr>
													<tr>
														<td>18</td>
														<td>Sumit Anil Bashide</td>
														<td>Marketing</td>
														<td>IDFC Bank</td>
														<td>3.75</td>
													</tr>
													<tr>
														<td>19</td>
														<td>Saurabh Sunil Munot</td>
														<td>Marketing</td>
														<td>IDFC Bank</td>
														<td>3.75</td>
													</tr>
													<tr>
														<td>20</td>
														<td>Kartik Mangare</td>
														<td>Marketing</td>
														<td>ICICI Bank</td>
														<td>5</td>
													</tr>
													<tr>
														<td>21</td>
														<td>Shubham shinde</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>6.75</td>
													</tr>
													<tr>
														<td>22</td>
														<td>Sumit Bulbule</td>
														<td>Marketing</td>
														<td>TAM Media Research</td>
														<td>4.25</td>
													</tr>
													<tr>
														<td>23</td>
														<td>Mahima Bangar</td>
														<td>Marketing</td>
														<td>Gati KWE</td>
														<td>3.4</td>
													</tr>
													<tr>
														<td>24</td>
														<td>Akash Bhalgat</td>
														<td>Marketing</td>
														<td>ICICI Securities</td>
														<td>4.05</td>
													</tr>
													<tr>
														<td>25</td>
														<td>Devarti Kandalkar</td>
														<td>Marketing</td>
														<td>Federal Bank</td>
														<td>8.89</td>
													</tr>
													<tr>
														<td>26</td>
														<td>Anchal Khurana</td>
														<td>Marketing</td>
														<td>Federal Bank</td>
														<td>8.89</td>
													</tr>
													<tr>
														<td>27</td>
														<td>Milind Bahulgaonkar</td>
														<td>Marketing</td>
														<td>SBI PO</td>
														<td>4</td>
													</tr>
													<tr>
														<td>28</td>
														<td>Pratik Gaikwad</td>
														<td>Marketing</td>
														<td>Hettich India</td>
														<td>5</td>
													</tr>
													<tr>
														<td>29</td>
														<td>Megha Rungta</td>
														<td>Marketing</td>
														<td>Quadrant Solution</td>
														<td>5</td>
													</tr>
													<tr>
														<td>30</td>
														<td>Jyoti Lokhande</td>
														<td>Marketing</td>
														<td>Satrap</td>
														<td>5</td>
													</tr>
													<tr>
														<td>31</td>
														<td>Mudita Pawar</td>
														<td>Marketing</td>
														<td>GD Research Center</td>
														<td>3.55</td>
													</tr>
													<tr>
														<td>32</td>
														<td>Prachi Salve</td>
														<td>Marketing</td>
														<td>Maxx Beverages and energy</td>
														<td>1.2</td>
													</tr>
													<tr>
														<td>33</td>
														<td>Sakshi Mishra</td>
														<td>Marketing</td>
														<td>Radient</td>
														<td>5</td>
													</tr>
													<tr>
														<td>34</td>
														<td>Snehal Augad</td>
														<td>Marketing</td>
														<td>TCS</td>
														<td>5.79</td>
													</tr>
													<tr>
														<td>35</td>
														<td>Neha Janorkar</td>
														<td>Finance</td>
														<td>Huron Consulting Group</td>
														<td>4.4</td>
													</tr>
													<tr>
														<td>36</td>
														<td>Virendra Sarde</td>
														<td>Finance</td>
														<td>XL Dynamics</td>
														<td>6</td>
													</tr>
													<tr>
														<td>37</td>
														<td>Snehal Nandeswar</td>
														<td>Finance</td>
														<td>Property Pistol</td>
														<td>7.8</td>
													</tr>
													<tr>
														<td>38</td>
														<td>Pushpesh Bhat</td>
														<td>Finance</td>
														<td>e-Emphasys</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>39</td>
														<td>Jay Suhas Chaugaonkar</td>
														<td>Finance</td>
														<td>ICICI Bank</td>
														<td>5</td>
													</tr>
													<tr>
														<td>40</td>
														<td>Shubham Anil Shelke</td>
														<td>Finance</td>
														<td>ICICI Bank</td>
														<td>5</td>
													</tr>
													<tr>
														<td>41</td>
														<td>Juilee Patil</td>
														<td>Finance</td>
														<td>ICICI Prudential</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>42</td>
														<td>Pooja Khatal</td>
														<td>Finance</td>
														<td>TCS</td>
														<td>5.79</td>
													</tr>
													<tr>
														<td>43</td>
														<td>Meghna Rajkonwar</td>
														<td>Finance</td>
														<td>Volkswagen IT Services</td>
														<td>5</td>
													</tr>
													<tr>
														<td>44</td>
														<td>Vikram Dixit</td>
														<td>Finance</td>
														<td>XL Dynamics</td>
														<td>5</td>
													</tr>
													<tr>
														<td>45</td>
														<td>Aakanksha Manoj Shirode</td>
														<td>Finance</td>
														<td>HDFC Bank</td>
														<td>6.75</td>
													</tr>
													<tr>
														<td>46</td>
														<td>Monica Bhagat</td>
														<td>Finance</td>
														<td>FIS Global</td>
														<td>5.22</td>
													</tr>
													<tr>
														<td>47</td>
														<td>Tinku Singh</td>
														<td>Finance</td>
														<td>FIS Global</td>
														<td>5.22</td>
													</tr>
													<tr>
														<td>48</td>
														<td>Payal Rathi</td>
														<td>Finance</td>
														<td>Johnsons Control</td>
														<td>3.5</td>
													</tr>
													<tr>
														<td>49</td>
														<td>Pratik More</td>
														<td>Finance</td>
														<td>HDFC Ltd.</td>
														<td>6.82</td>
													</tr>
													<tr>
														<td>50</td>
														<td>Ayesha Tamboli</td>
														<td>Finance</td>
														<td>SG Analytics</td>
														<td>3.5</td>
													</tr>
													<tr>
														<td>51</td>
														<td>Niraj Pendor</td>
														<td>Finance</td>
														<td>ICICI Securities</td>
														<td>5</td>
													</tr>
													<tr>
														<td>52</td>
														<td>Shubhangi Pawar</td>
														<td>Finance</td>
														<td>SG Analytics</td>
														<td>3.36</td>
													</tr>
													<tr>
														<td>53</td>
														<td>Awanti Pandey</td>
														<td>Finance</td>
														<td>Purnartha</td>
														<td>6.14</td>
													</tr>
													<tr>
														<td>54</td>
														<td>Krishna Tawari</td>
														<td>Finance</td>
														<td>Eclerx</td>
														<td>5.5</td>
													</tr>
													<tr>
														<td>55</td>
														<td>Prashant Shinde</td>
														<td>Finance</td>
														<td>TCS</td>
														<td>5</td>
													</tr>
													<tr>
														<td>56</td>
														<td>Tejal Melge</td>
														<td>HR</td>
														<td>Teamlease(KSB LTD.)</td>
														<td>3</td>
													</tr>
													<tr>
														<td>57</td>
														<td>Aishwarya Giradkar</td>
														<td>HR</td>
														<td>KSB</td>
														<td>5</td>
													</tr>
													<tr>
														<td>58</td>
														<td>Anurag Kale</td>
														<td>Operation</td>
														<td>TCS</td>
														<td>5.79</td>
													</tr>
													<tr>
														<td>59</td>
														<td>Shubham Deshmukh</td>
														<td>Operation</td>
														<td>TCS</td>
														<td>6.6</td>
													</tr>
													<tr>
														<td>60</td>
														<td>Radheya Ayarekar</td>
														<td>Operation</td>
														<td>Reliance Retail</td>
														<td>5</td>
													</tr>
													<tr>
														<td>61</td>
														<td>Prathmesh Anil Nachane</td>
														<td>Operation</td>
														<td>Volkswagen IT Services</td>
														<td>5</td>
													</tr>
													<tr>
														<td>62</td>
														<td>Ajinkya Kulkarni</td>
														<td>Operation</td>
														<td>Grand view Research</td>
														<td>3.2</td>
													</tr>
													<tr>
														<td>63</td>
														<td>Dattatraya Avdhoot</td>
														<td>Operation</td>
														<td>Repro India</td>
														<td>5</td>
													</tr>
												</tbody>
											</Table>
										</div>

									</div>
								</div>
							</Tab>
							<Tab eventKey="2018-20" title="2018-20 Batch" className="whiteBackground paddingBottom3em">
								<div className="container">
									<div className="row">
										<div className="col-md-12">
											<h3 className="blockTitle">2018-20 Batch</h3>
											<Table class="table table-responsive table-bordered" responsive>
												<tbody>

													<tr>
														<td><b>No</b></td>
														<td><b>Name</b></td>
														<td><b>Specialization</b></td>
														<td><b>Company Name</b></td>
														<td><b>Packages</b></td>
													</tr>
													<tr>
														<td>1</td>
														<td>Shubham Jain</td>
														<td>Marketing</td>
														<td>Square Yard Dubai</td>
														<td>9.6</td>
													</tr>
													<tr>
														<td>2</td>
														<td>Sumit Khartade</td>
														<td>Marketing</td>
														<td>Square Yard Dubai</td>
														<td>9.6</td>
													</tr>
													<tr>
														<td>3</td>
														<td>Aditya Sawarkar</td>
														<td>Marketing</td>
														<td>Square Yard Dubai</td>
														<td>9.6</td>
													</tr>
													<tr>
														<td>4</td>
														<td>Varsha Dhar</td>
														<td>Marketing</td>
														<td>Deloite(KS)</td>
														<td>5.5</td>
													</tr>
													<tr>
														<td>5</td>
														<td>Sayali Tupe</td>
														<td>Marketing</td>
														<td>Deloite(Advisory)</td>
														<td>6.25</td>
													</tr>
													<tr>
														<td>6</td>
														<td>Aishwarya Rajesh Ware</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>4.86</td>
													</tr>
													<tr>
														<td>7</td>
														<td>Amruta nandkumar bhore</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>4.89</td>
													</tr>
													<tr>
														<td>8</td>
														<td>Chatainyan Jadhav</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>6.75</td>
													</tr>
													<tr>
														<td>9</td>
														<td>Rahul Mahato</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>4.89</td>
													</tr>
													<tr>
														<td>10</td>
														<td>Rajat Nawkhare</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>4.83</td>
													</tr>
													<tr>
														<td>11</td>
														<td>Pritam Khasbage</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>4.89</td>
													</tr>
													<tr>
														<td>12</td>
														<td>Tejashree Nivarutti Pawar</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>4.86</td>
													</tr>
													<tr>
														<td>13</td>
														<td>Ishan  Singh</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>4.89</td>
													</tr>
													<tr>
														<td>14</td>
														<td>Sayali Bande</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>4.8</td>
													</tr>
													<tr>
														<td>15</td>
														<td>Vaibhav Kanakdande </td>
														<td>Marketing</td>
														<td>TCS</td>
														<td>5.79</td>
													</tr>
													<tr>
														<td>16</td>
														<td>Suraj  Bhagwat</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>6.75</td>
													</tr>
													<tr>
														<td>17</td>
														<td>Navoneel Sarkar</td>
														<td>Marketing</td>
														<td>Federal Bank</td>
														<td>7.3</td>
													</tr>
													<tr>
														<td>18</td>
														<td>Mayur Sanjayrao Shrisathe</td>
														<td>Marketing</td>
														<td>ICICI Bank</td>
														<td>4.02</td>
													</tr>
													<tr>
														<td>19</td>
														<td>Pallavi Phirke</td>
														<td>Marketing</td>
														<td>ICICI Bank</td>
														<td>4.12</td>
													</tr>
													<tr>
														<td>20</td>
														<td>Saurabh Maruti Ghodekar</td>
														<td>Marketing</td>
														<td>ICICI Bank</td>
														<td>4.12</td>
													</tr>
													<tr>
														<td>21</td>
														<td>Akshay Patil</td>
														<td>Marketing</td>
														<td>Kumar Properties</td>
														<td>3.5</td>
													</tr>
													<tr>
														<td>22</td>
														<td>Chanshu Patil</td>
														<td>Marketing</td>
														<td>Jana Small Finance Bank</td>
														<td>3.5</td>
													</tr>
													<tr>
														<td>23</td>
														<td>Vishal Pujari</td>
														<td>Marketing</td>
														<td>Berger Paints</td>
														<td>5.75</td>
													</tr>
													<tr>
														<td>24</td>
														<td>Chinmay Jagannath Gonjari</td>
														<td>Marketing</td>
														<td>Bajaj Allianz General Insurance </td>
														<td>4</td>
													</tr>
													<tr>
														<td>25</td>
														<td>Akshay Bagade</td>
														<td>Marketing</td>
														<td>Cybage</td>
														<td>5</td>
													</tr>
													<tr>
														<td>26</td>
														<td>Samidha Dandhare</td>
														<td>Marketing</td>
														<td>Bajaj Housing Finance Limited.</td>
														<td>3.5</td>
													</tr>
													<tr>
														<td>27</td>
														<td>Nidhi Garampalli</td>
														<td>Marketing</td>
														<td>FabHotels</td>
														<td>4</td>
													</tr>
													<tr>
														<td>28</td>
														<td>Yogesh Kumar Pawar</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>4.83</td>
													</tr>
													<tr>
														<td>29</td>
														<td>Rutwik Sawarkar</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>4.86</td>
													</tr>
													<tr>
														<td>30</td>
														<td>Deeksha Gupta</td>
														<td>Marketing</td>
														<td>PhonePe</td>
														<td>4.89</td>
													</tr>
													<tr>
														<td>31</td>
														<td>Tejas Jilthe</td>
														<td>Marketing</td>
														<td>PhonePe</td>
														<td>4.89</td>
													</tr>
													<tr>
														<td>32</td>
														<td>Dimpesh Lanjewar</td>
														<td>Marketing</td>
														<td>TCS</td>
														<td>6.5</td>
													</tr>
													<tr>
														<td>33</td>
														<td>Prasad Ashok Mane</td>
														<td>Marketing</td>
														<td>TCS</td>
														<td>6.09</td>
													</tr>
													<tr>
														<td>34</td>
														<td>Shailesh Moharlikar</td>
														<td>Marketing</td>
														<td>AU Small Finance Bank Limited </td>
														<td>5</td>
													</tr>
													<tr>
														<td>35</td>
														<td>kamalshil manohare </td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>4.86</td>
													</tr>
													<tr>
														<td>36</td>
														<td>Tarun Jangid</td>
														<td>Marketing</td>
														<td>Property Pistol</td>
														<td>7.8</td>
													</tr>
													<tr>
														<td>37</td>
														<td>Akshay Divekar</td>
														<td>Marketing</td>
														<td>HDFC Sales Pvt. Ltd.</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>38</td>
														<td>Sourabh Sanjay Mandhare</td>
														<td>Marketing</td>
														<td>Godrej & Boyce</td>
														<td>6</td>
													</tr>
													<tr>
														<td>39</td>
														<td>Samiksha Mahakalkar</td>
														<td>Marketing</td>
														<td>Lido Learning</td>
														<td>8</td>
													</tr>
													<tr>
														<td>40</td>
														<td>Ashish Khaimode</td>
														<td>Marketing</td>
														<td>Sany Heavy Industry</td>
														<td>4</td>
													</tr>
													<tr>
														<td>41</td>
														<td>Pooja Goyal</td>
														<td>Marketing</td>
														<td>Byjus</td>
														<td>3</td>
													</tr>
													<tr>
														<td>42</td>
														<td>Sujit Modhe</td>
														<td>Marketing</td>
														{/*<td>Stark digital</td>*/}
														<td>Ordinet Solutions Pvt Ltd</td>
														<td>5</td>
													</tr>
													<tr>
														<td>43</td>
														<td>Ashvin Rathod</td>
														<td>Marketing</td>
														<td>Airtel Business</td>
														<td>5</td>
													</tr>
													<tr>
														<td>44</td>
														<td>Harshali Joshi</td>
														<td>Marketing</td>
														<td>Minitek India</td>
														<td>1.6</td>
													</tr>
													<tr>
														<td>45</td>
														<td>Manali Joshi</td>
														<td>Finance</td>
														<td>Schlumberger</td>
														<td>9.11</td>
													</tr>
													<tr>
														<td>46</td>
														<td>Rishika Parihar</td>
														<td>Finance</td>
														<td>HDFC Bank</td>
														<td>4.83</td>
													</tr>
													<tr>
														<td>47</td>
														<td>Sourabh rathi</td>
														<td>Finance</td>
														<td>ICICI Bank</td>
														<td>4.12</td>
													</tr>
													<tr>
														<td>48</td>
														<td>Ankita Ashok Gole</td>
														<td>Finance</td>
														<td>ANZ</td>
														<td>6</td>
													</tr>
													<tr>
														<td>49</td>
														<td>Aditi joshi</td>
														<td>Finance</td>
														<td>AU Small Finance Bank Limited </td>
														<td>5</td>
													</tr>
													<tr>
														<td>50</td>
														<td>Shubham Dagade</td>
														<td>Finance</td>
														<td>Decimal Point</td>
														<td>2.83</td>
													</tr>
													<tr>
														<td>51</td>
														<td>Ambika Gupta</td>
														<td>HR</td>
														<td>InterviewBit</td>
														<td>4.26</td>
													</tr>
													<tr>
														<td>52</td>
														<td>Kavita Chaudhari</td>
														<td>HR</td>
														<td>Piaggio Vehicle</td>
														<td>4</td>
													</tr>
													<tr>
														<td>53</td>
														<td>Sameeksha Pagar</td>
														<td>HR</td>
														<td>WNS</td>
														<td>1.68</td>
													</tr>
													<tr>
														<td>54</td>
														<td>Pooja Navrange</td>
														<td>HR</td>
														<td>Enzene</td>
														<td>3.35</td>
													</tr>
													<tr>
														<td>55</td>
														<td>Rohit Phalke</td>
														<td>Operation</td>
														<td>Schlumberger</td>
														<td>9.11</td>
													</tr>
													<tr>
														<td>56</td>
														<td>Aniket Sunil Tope</td>
														<td>Operation</td>
														<td>Baker Hughes</td>
														<td>5</td>
													</tr>
													<tr>
														<td>57</td>
														<td>Ankush Chape</td>
														<td>Operation</td>
														<td>Infiniti Retail Limited- A Tata Enterprise</td>
														<td>5.5</td>
													</tr>
													<tr>
														<td>58</td>
														<td>Sahil Kadam</td>
														<td>Operation</td>
														<td>Infiniti Retail Limited- A Tata Enterprise</td>
														<td>5.5</td>
													</tr>
												</tbody>
											</Table>
										</div>
									</div>
								</div>
							</Tab>

							<Tab eventKey="2017-19" title="2017-19 Batch" className="whiteBackground paddingBottom3em">
								<div className="container paddingclass">
									<div className="row">
										<div className="col-md-12">
											<h3 className="blockTitle">2017-19 Batch</h3>
											<Table class="table table-responsive table-bordered" responsive>
												<tbody>

													<tr>
														<td><b>No</b></td>
														<td><b>Name</b></td>
														<td><b>Specialization</b></td>
														<td><b>Company Name</b></td>
														<td><b>Packages</b></td>
													</tr>
													<tr>
														<td>1</td>
														<td>Arpit Paliwal</td>
														<td>Marketing</td>
														<td>OYO Rooms </td>
														<td>4.12</td>
													</tr>
													<tr>
														<td>2</td>
														<td>Sanket Deshpande</td>
														<td>Marketing</td>
														<td>Markets and Markets</td>
														<td>4</td>
													</tr>
													<tr>
														<td>3</td>
														<td>Swapnil More</td>
														<td>Marketing</td>
														<td>Aloha Technology </td>
														<td>4.2</td>
													</tr>
													<tr>
														<td>4</td>
														<td>Anushka Deore</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>6.67</td>
													</tr>
													<tr>
														<td>5</td>
														<td>Ajinkya Hazare</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>6.67</td>
													</tr>
													<tr>
														<td>6</td>
														<td>Kanchi Agarwal</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>6.67</td>
													</tr>
													<tr>
														<td>7</td>
														<td>Mrunalini Dhas</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>6.67</td>
													</tr>
													<tr>
														<td>8</td>
														<td>Apurwa Patil</td>
														<td>Marketing</td>
														<td>Tata Consultancy Services</td>
														<td>5.79</td>
													</tr>
													<tr>
														<td>9</td>
														<td>Sejal Kamble</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>6.67</td>
													</tr>
													<tr>
														<td>10</td>
														<td>Isha Govind</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>6.67</td>
													</tr>
													<tr>
														<td>11</td>
														<td>Rupesh Lingampalli</td>
														<td>Marketing</td>
														<td>HDFC Bank</td>
														<td>6.67</td>
													</tr>
													<tr>
														<td>12</td>
														<td>Kulbhushan Baviskar</td>
														<td>Marketing</td>
														<td>MRF Tyres </td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>13</td>
														<td>Pratik Deshmukh</td>
														<td>Marketing</td>
														<td>MRF Tyres </td>
														<td>4.38</td>
													</tr>
													<tr>
														<td>14</td>
														<td>Navin Dhule</td>
														<td>Marketing</td>
														<td>Glenmark</td>
														<td>4</td>
													</tr>
													<tr>
														<td>15</td>
														<td>Dinesh Yadav</td>
														<td>Marketing</td>
														<td>Glenmark</td>
														<td>4</td>
													</tr>
													<tr>
														<td>16</td>
														<td>Vaibhav Darkunde</td>
														<td>Marketing</td>
														<td>Om Logistics</td>
														<td>5</td>
													</tr>
													<tr>
														<td>17</td>
														<td>Ashwin Nair</td>
														<td>Marketing</td>
														<td>Bajaj Allianz General Insurance Company</td>
														<td>5.42</td>
													</tr>
													<tr>
														<td>18</td>
														<td>Shantanu Muthe</td>
														<td>Marketing</td>
														<td>Bajaj Allianz General Insurance Company</td>
														<td>5.42</td>
													</tr>
													<tr>
														<td>19</td>
														<td>Tushar Thube</td>
														<td>Marketing</td>
														<td>ICICI Securities</td>
														<td>4</td>
													</tr>
													<tr>
														<td>20</td>
														<td>Saurabh Mahajan</td>
														<td>Marketing</td>
														<td>Federal Bank</td>
														<td>6.67</td>
													</tr>
													<tr>
														<td>21</td>
														<td>Payal Raut</td>
														<td>Marketing</td>
														<td>ICICI Prudential Life Insurance</td>
														<td>4.25</td>
													</tr>
													<tr>
														<td>22</td>
														<td>Rushikesh Daware</td>
														<td>Marketing</td>
														<td>Godrej and Boyce Mfg. Co Ltd</td>
														<td>5.75</td>
													</tr>
													<tr>
														<td>23</td>
														<td>Vikram Shinde</td>
														<td>Marketing</td>
														<td>ICICI Bank</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>24</td>
														<td>Parag Gunjal</td>
														<td>Marketing</td>
														<td>ICICI Bank</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>25</td>
														<td>Gaurav Chaudhary</td>
														<td>Marketing</td>
														<td>ICICI Bank</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>26</td>
														<td>Kiran Tathe</td>
														<td>Marketing</td>
														<td>ICICI Bank</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>27</td>
														<td>Ishita Maratha</td>
														<td>Marketing</td>
														<td>ICICI Bank</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>28</td>
														<td>Ashlesh Padole</td>
														<td>Marketing</td>
														<td>ICICI Bank</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>29</td>
														<td>Vinay Potdar</td>
														<td>Marketing</td>
														<td>Home First Finance Company</td>
														<td>6.15</td>
													</tr>
													<tr>
														<td>30</td>
														<td>Aniket Sahane</td>
														<td>Marketing</td>
														<td>Coherent Market Insights </td>
														<td>4.1</td>
													</tr>
													<tr>
														<td>31</td>
														<td>Dhanashri Abhane </td>
														<td>Marketing</td>
														<td>Kalyani Technoforge</td>
														<td>3.5</td>
													</tr>
													<tr>
														<td>32</td>
														<td>Akshay Deokar</td>
														<td>Marketing</td>
														<td>JK Tyres</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>33</td>
														<td>Swapnil Chaudhari</td>
														<td>Marketing</td>
														<td>Apollo Munich</td>
														<td>3</td>
													</tr>
													<tr>
														<td>34</td>
														<td>Mrunali Babhulkar</td>
														<td>Finance</td>
														<td>Schlumberger India</td>
														<td>9.11</td>
													</tr>
													<tr>
														<td>35</td>
														<td>Rashmi Jaiswal</td>
														<td>Finance</td>
														<td>Deloitte(US Taxation)</td>
														<td>6</td>
													</tr>
													<tr>
														<td>36</td>
														<td>Katyayan kumar Mishra</td>
														<td>Finance</td>
														<td>Deloitte(US Taxation)</td>
														<td>6</td>
													</tr>
													<tr>
														<td>37</td>
														<td>Ajinkya Tuwar</td>
														<td>Finance</td>
														<td>HDFC Bank</td>
														<td>6.67</td>
													</tr>
													<tr>
														<td>38</td>
														<td>Prakash Rathod</td>
														<td>Finance</td>
														<td>AU Small Finance</td>
														<td>3.5</td>
													</tr>
													<tr>
														<td>39</td>
														<td>Utkarsh Choudhary</td>
														<td>Finance</td>
														<td>Huron Consulting Group</td>
														<td>4.4</td>
													</tr>
													<tr>
														<td>40</td>
														<td>Mitali Rodi</td>
														<td>Finance</td>
														<td>Purnartha</td>
														<td>6.15</td>
													</tr>
													<tr>
														<td>41</td>
														<td>Harshita Vijay</td>
														<td>Finance</td>
														<td>KPMG</td>
														<td>5.97</td>
													</tr>
													<tr>
														<td>42</td>
														<td>Akash Gajgate</td>
														<td>Finance</td>
														<td>ICICI Securities</td>
														<td>4</td>
													</tr>
													<tr>
														<td>43</td>
														<td>Shubham Kondalkar</td>
														<td>Finance</td>
														<td>Talent Corner HR Services</td>
														<td>5</td>
													</tr>
													<tr>
														<td>44</td>
														<td>Hasim Ali Siddique</td>
														<td>Finance</td>
														<td>ICICI Prudential Life Insurance</td>
														<td>4.25</td>
													</tr>
													<tr>
														<td>45</td>
														<td>Akshata Deshpande</td>
														<td>Finance</td>
														<td>ICICI Bank</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>46</td>
														<td>Rishinath Shrivastava</td>
														<td>Finance</td>
														<td>ICICI Bank</td>
														<td>4.5</td>
													</tr>
													<tr>
														<td>47</td>
														<td>Shubham Doshi</td>
														<td>Finance</td>
														<td>Bajaj Allianz Life Insurance Company </td>
														<td>8</td>
													</tr>
													<tr>
														<td>48</td>
														<td>Akash Nagar</td>
														<td>Finance</td>
														<td>Pin Click</td>
														<td>5.3</td>
													</tr>
													<tr>
														<td>49</td>
														<td>Prathmesh Chougule </td>
														<td>Finance</td>
														<td>Oracle India</td>
														<td>3.3</td>
													</tr>
													<tr>
														<td>50</td>
														<td>Siddhesh Mahajan</td>
														<td>Finance</td>
														<td>Dassault Systems</td>
														<td>3.5</td>
													</tr>
													<tr>
														<td>51</td>
														<td>Mallikarjun Emme</td>
														<td>HR</td>
														<td>Tech Mahindra</td>
														<td>2</td>
													</tr>
													<tr>
														<td>52</td>
														<td>Prachi Kalekar</td>
														<td>HR</td>
														<td>CATENON</td>
														<td>6</td>
													</tr>
													<tr>
														<td>53</td>
														<td>Aniket Patil</td>
														<td>HR</td>
														<td>CATENON</td>
														<td>6</td>
													</tr>
													<tr>
														<td>54</td>
														<td>Bhagayashree Kathepuri </td>
														<td>HR</td>
														<td>Talent Corner HR Services</td>
														<td>5</td>
													</tr>
													<tr>
														<td>55</td>
														<td>Pranjali Khade</td>
														<td>HR</td>
														<td>Reliance Jio</td>
														<td>3.5</td>
													</tr>
													<tr>
														<td>56</td>
														<td>Aishik Kumar Roy</td>
														<td>HR</td>
														<td>Union Bank of India</td>
														<td>5</td>
													</tr>
													<tr>
														<td>57</td>
														<td>Rishikesh Jadhav</td>
														<td>Operations</td>
														<td>Reliance Retail Ltd.</td>
														<td>5</td>
													</tr>
													<tr>
														<td>58</td>
														<td>Sumeet Kalyankar</td>
														<td>Operations</td>
														<td>Future Supply Chains</td>
														<td>3.5</td>
													</tr>
													<tr>
														<td>59</td>
														<td>Rohan Kharade</td>
														<td>Operations</td>
														<td>Market Research future report</td>
														<td>3.6</td>
													</tr>
												</tbody>
											</Table>

										</div>
									</div>
								</div>
							</Tab>
						</Tabs>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default PlacementReport