import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header";
import BannerImage from "../../assets/images/about/scesbanner.jpg"
import Banner2Image from "../../assets/images/about/aboutiscs.jpg"
import SaritawakalkarImage from "../../assets/images/about/saritawakalkar.jpg"
import ChetansirImage from "../../assets/images/about/chetansir.jpg"
import TaritamamImage from "../../assets/images/about/taritamam.png"
import ShaanImg from "../../assets/images/ShaanImg.png"
import PilajiImage from "../../assets/images/about/pilaji.jpg"
import MhaskeImg from "../../assets/images/MhaskeImg.png"
import SandeepGaikwad from "../../assets/images/SandeepGaikwad.jpg"
import SahilMehendale from "../../assets/images/Sahil-Mehendale.jpg"
import "./style.css"
const ShreeChanakyaEducationSociety = () => {
    const [about, setAbout] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={BannerImage} alt="" className="imgRes" />
                <h2 className="bannerTitleAbout">Shree Chanakya Education Society</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">About Us &#62; <span> Shree Chanakya Education Society</span></h3>
                    </div>
                    <div className="col-md-12">
                        <div className="paddingclass borderimg1">
                            <p className="mobHide">The Shree Chanakya Education Society (SCES) was established in February 1994, under the visionary leadership of Dr. Tarita
                                Shankar, with the aim of providing top quality post-graduate education in the fields of Business Management, International Business
                                and Information Technology. By consistently providing quality education over the past few decades, institutes at Indira Group is now
                                considered as one of the best institutes in Pune. At a time when India was struggling to put its economy back on its feet, after the
                                nation having pawned the “family jewels” just to keep afloat, Dr. Tarita Shankar sensed that education too would have to become
                                more broad based and more vocational in nature if India was to stand up to the world competition in quality and price for its products,
                                The then Finance Minister had prescribed for the economy and so, in 1994, began a saga of growth and quality in education; a story
                                that is just reaching its zenith with 14 full fledged Institutes registering a strong presence on Pune’s educational horizon. Since
                                inception, the Institutes managed by SCES, have maintained high academic standards and have successfully provided trained
                                manpower to the industrial and services sector of the country. These institutes are now listed amongst the top colleges not just in
                                Pune, but also in Maharashtra and India. With a modest strength of 60 students pursuing a single course, SCES has grown steadily
                                and today boasts of 14 Institutes, having more than 8000 students from all over India pursuing multi-disciplinary, graduate & postgraduate programs. The objective of the institute is providing ‘Management education in a corporate environment’, has been possible
                                due to the sincere and dedicated efforts of the members of SCES, who have invaluable experience in varied areas like academics,
                                industry, service and social-world.</p>
                            <div className="mobShow">
                                <p>The Shree Chanakya Education Society (SCES) was established in February 1994, under the visionary leadership of Dr. Tarita
                                    Shankar, with the aim of providing top quality post-graduate education in the fields of Business Management, International Business
                                    and Information Technology. By consistently providing quality education over the past few decades, institutes at Indira Group is now
                                    considered as one of the best institutes in Pune.</p>
                                    
                                {about &&
                                    <p>At a time when India was struggling to put its economy back on its feet, after the
                                        nation having pawned the “family jewels” just to keep afloat, Dr. Tarita Shankar sensed that education too would have to become
                                        more broad based and more vocational in nature if India was to stand up to the world competition in quality and price for its products,
                                        The then Finance Minister had prescribed for the economy and so, in 1994, began a saga of growth and quality in education; a story
                                        that is just reaching its zenith with 14 full fledged Institutes registering a strong presence on Pune’s educational horizon. Since
                                        inception, the Institutes managed by SCES, have maintained high academic standards and have successfully provided trained
                                        manpower to the industrial and services sector of the country. These institutes are now listed amongst the top colleges not just in
                                        Pune, but also in Maharashtra and India. With a modest strength of 60 students pursuing a single course, SCES has grown steadily
                                        and today boasts of 14 Institutes, having more than 8000 students from all over India pursuing multi-disciplinary, graduate & postgraduate programs. The objective of the institute is providing ‘Management education in a corporate environment’, has been possible
                                        due to the sincere and dedicated efforts of the members of SCES, who have invaluable experience in varied areas like academics,
                                        industry, service and social-world.</p>
                                }
                                <span className="readButton"onClick={()=>setAbout(!about)}>{about?"Read Less":"Read More"}</span>
                            </div>
                            <img src={Banner2Image} alt="" className="imgRes" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="blockTitle">Managing Committee <span> Members/Trustees</span></h3>
                    </div>
                    <div className="col-md-3 marginBottom15px col-6">
                        <div className="col-md-12 borderimg2">
                            <div className="row">
                                <div className="col-md-5">
                                    <img src={SaritawakalkarImage} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-7">
                                    <h4 className="semiTitle">Smt. Sarita<br />Shankar<br />Wakalkar</h4>
                                    <p className="semiTitleInner">President</p>
                                </div>
                            </div>
                        </div>
                    </div>

                   
                    <div className="col-md-3 marginBottom15px col-6">
                        <div className="col-md-12 borderimg2">
                            <div className="row">
                                <div className="col-md-5">
                                    <img src={SandeepGaikwad} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-7">
                                    <h4 className="semiTitle">Mr. Sandeep Prabhakar Gaikwad</h4>
                                    <p className="semiTitleInner">Vice - President</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 marginBottom15px col-6">
                        <div className="col-md-12 borderimg2">
                            <div className="row">
                                <div className="col-md-5">
                                    <img src={TaritamamImage} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-7">
                                    <h4 className="semiTitle">Dr. Tarita Shankar</h4>
                         <p className="semiTitleInner">Founder Secretary, Chief Managing Trustee, Chairperson & Chief Mentor</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 marginBottom15px col-6">
                        <div className="col-md-12 borderimg2">
                            <div className="row">
                                <div className="col-md-5">
                                    <img src={ShaanImg} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-7">
                                    <h4 className="semiTitle">Mr. Shaan Tarita Shankar Aditya Mehendale. <br />
                                        </h4>
                                    <p className="semiTitleInner">Trustee Member</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="container marginBottom3em">
                <div className="row">
                <div className="col-md-3 marginLeft12 marginBottom15px col-6">
                        <div className="col-md-12 borderimg2">
                            <div className="row">
                                <div className="col-md-5">
                                    <img src={PilajiImage} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-7">
                                    <h4 className="semiTitle">Mr. Pilaji<br />
                                        Sursingh<br />
                                        Jadhavrao</h4>
                                    <p className="semiTitleInner">Treasurer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 marginBottom15px col-6">
                        <div className="col-md-12 borderimg2">
                            <div className="row">
                                <div className="col-md-5">
                                    <img src={MhaskeImg} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-7">
                                    <h4 className="semiTitle">Mr. Vasant  Maruti Mhaske<br />
                                    
                                </h4>
                                    <p className="semiTitleInner">Trustee Member</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 marginBottom15px col-6">
                        <div className="col-md-12 borderimg2">
                            <div className="row">
                                <div className="col-md-5">
                                    <img src={SahilMehendale} alt="" className="imgRes" />
                                </div>
                                <div className="col-md-7">
                                    <h4 className="semiTitle">Mr. Sahil Tarita Shankar Aditya Mehendale. </h4>
                                    <p className="semiTitleInner">Joint Secretary</p>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </Fragment>
    )
}
export default ShreeChanakyaEducationSociety