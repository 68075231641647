import React, { Fragment, useState,useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from './../assets/images/inner-logo.jpg'
import MenuIcon from './../assets/images/humberger-menu.jpg'
import NIRF from './../assets/pdf/Filled survey - NIRF.pdf'
import NBA from '../assets/pdf/NBA Certificate 2022_20220924_0002.pdf'
import phdnotice from '../assets/pdf/phd-Notice-Website.pdf'
import phdnotice1 from '../assets/pdf/PhD-Interview-Round-II_Notice-2023.pdf'
import GoogleTagManager from './googleManager'; // Adjust the path as necessary
import ACSPApplications from "../assets/pdf/Advt-for-IL-ACAP-applications.pdf"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
const Header = () => {
    const [fullMenu, setFullMenu] = useState(false)
    const [aboutSubMenu, setAboutSubMenu] = useState(false)
    const [coursesSubMenu, setCoursesSubMenu] = useState(false)
    const [coursesSubMbaMenu, setCoursesSubMbaMenu] = useState(false)
    const [coursesSubPhdMenu, setCoursesSubPhdMenu] = useState(false)
    const [placementSubMenu, setPlacementSubMenu] = useState(false)
    const [studentLifeSubMenu, setStudentLifeSubMenu] = useState(false)
    const [alumniSubMenu, setAlumniSubMenu] = useState(false)
    const [researchSubMenu, setResearchSubMenu] = useState(false)
    const [mdpSubMenu, setMdpSubMenu] = useState(false)
    const [PublicationsSubMenu, setPublicationsSubMenu] = useState(false)


    const AboutHandler = () => {
        setAboutSubMenu(true)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const CoursesHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(true)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const PlacementMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(true)
        setStudentLifeSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const StudentLifeMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(true)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const AlumniMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setAlumniSubMenu(true)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const ResearchMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(true)
        setMdpSubMenu(false)
        setPublicationsSubMenu(false)
    }
    const MdpMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(true)
        setPublicationsSubMenu(false)
    }
    const PublicationsMenuHandler = () => {
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setPublicationsSubMenu(true)
    }
    const closeFullMenuHandler = () => {
        setFullMenu(false);
        setAboutSubMenu(false)
        setCoursesSubMenu(false)
        setCoursesSubMbaMenu(false)
        setCoursesSubPhdMenu(false)
        setPlacementSubMenu(false)
        setStudentLifeSubMenu(false)
        setAlumniSubMenu(false)
        setResearchSubMenu(false)
        setMdpSubMenu(false)
        setPublicationsSubMenu(false)
    }


    const openFullMenuHandler = () => {
        setFullMenu(true);
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [popupShow, setPopupShow] = useState(false);
    const handleClosePopup = () => setPopupShow(false);
    const handleShowPopup = () => setPopupShow(true);

    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://widgets.in5.nopaperforms.com/emwgts.js";
        script.async = true;
      
        document.body.appendChild(script)

        
      
      }, []);


    return (
        <Fragment>
        
  
 <GoogleTagManager />

<div className="enquireBtn" onClick={handleShowPopup}>Enquire Now</div>

{/* <div>
<Helmet>
<script async src="https://www.googletagmanager.com/gtag/js?id=G-PZ49LSF5BD"></script>     
<script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments)};
     gtag('js', new Date()); gtag('config', 'G-PZ49LSF5BD'); </script>  
            </Helmet>
</div> */}



            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Business Leader of the Year 2022</Modal.Title>
                </Modal.Header>
                <Modal.Body>Congratulations to our Chairperson Dr. Tarita Ma'am and Group Director Prof. Chetan Sir for the awards at the Business Leader of the Year 2022 Ceremony.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className={popupShow?"enquireNowFormCont enableEnquire ":"enquireNowFormCont"}>
                <div className="closeBtn" onClick={handleClosePopup}>X</div>
                <div className="formtitle">
                    <h4 className="titleTxt">Enquire Now</h4>
                </div>

                


                <div>
                <div class="npf_wgts" data-height="450px" data-w="403f0ad3d2ff7973ec36e1f5ab6702d0"></div>
                <script type="text/javascript"> var s=document.createElement("script"); s.type="text/javascript"; s.async=true; s.src="https://widgets.in5.nopaperforms.com/emwgts.js"; document.body.appendChild(s); </script>



                

            </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <NavLink to="/" exact><img src={Logo} alt="" className="innerPageLogo" /> </NavLink>
                    </div>
                    <div className="col-4 text-right mobHide">
                    <p class="blinkfont blinkanimation">DTE Code: 0646010110</p> 
                    </div>
                    <div className="col-2 text-right mobHide">
                        <img src={MenuIcon} alt="" className="innerPageMnuIcon" onClick={openFullMenuHandler} />
                    </div>
                    <div className="col-6 text-right mobShow">
                        <img src={MenuIcon} alt="" className="innerPageMnuIcon" onClick={openFullMenuHandler} />
                    </div>
                    <div className="col-12 text-center mobShow">
                    <p class="blinkfont blinkanimation">DTE Code: 0646010110</p> 
                    </div>
                </div>
            </div>
            {fullMenu &&
                <div className="fulMenuMain">
                    <ul>
                        <li className={aboutSubMenu ? "activeMnuInnerPage" : ""} onClick={AboutHandler}>
                            About Us <i className="fa fa-angle-double-right"></i>
                        </li>
                        <li className={coursesSubMenu ? "activeMnuInnerPage" : ""} onClick={CoursesHandler}>
                            Courses <i className="fa fa-angle-double-right"></i>
                        </li>
                        <li className={placementSubMenu ? "activeMnuInnerPage" : ""} onClick={PlacementMenuHandler}>
                            Placement <i className="fa fa-angle-double-right"></i>
                        </li>
                        <li className={studentLifeSubMenu ? "activeMnuInnerPage" : ""} onClick={StudentLifeMenuHandler}>
                            Student Life <i className="fa fa-angle-double-right"></i>
                        </li>
                        <li className={alumniSubMenu ? "activeMnuInnerPage" : ""} onClick={AlumniMenuHandler}>
                            Alumni <i className="fa fa-angle-double-right"></i>
                        </li>
                        <li className={researchSubMenu ? "activeMnuInnerPage" : ""} onClick={ResearchMenuHandler}>
                            Research <i className="fa fa-angle-double-right"></i>
                        </li>
                        <li className={mdpSubMenu ? "activeMnuInnerPage" : ""} onClick={MdpMenuHandler}>
                            MDP <i className="fa fa-angle-double-right"></i>
                        </li>
                        <a href="https://blog.indiraedu.com/" target="_blank" rel="noreferrer"><li>Blog</li></a>
                        <li className={PublicationsSubMenu ? "activeMnuInnerPage" : ""} onClick={PublicationsMenuHandler}>
                            Publications <i className="fa fa-angle-double-right"></i>
                        </li>
                        <a href="https://indiraisbs.ac.in/ISBS-360-degree-virtual-tour/" target="_blank" rel="noreferrer"><li>Virtual Tour</li></a>
                        <NavLink to="contact" onClick={closeFullMenuHandler}><li>
                            Contact Us
                        </li></NavLink>
                    </ul>
                    <div className="closeIconMnu" onClick={closeFullMenuHandler}>Close Menu <i className="fa fa-long-arrow-left"></i></div>
                </div>
            }
            <div className={aboutSubMenu ? "HomeSubmenuContainerInnerPage activeSubMenuInnerPage" : "HomeSubmenuContainerInnerPage"}>
                <div className="menuListSubInnerPage">
                    <div className="closeIconMnuInner" onClick={closeFullMenuHandler}>Close Menu <i className="fa fa-long-arrow-left"></i></div>
                    <ul>
                        <NavLink to="/shree-chanakya-education-society"><li>Shree Chanakya Education Society</li></NavLink>
                        <NavLink to="/indira-group-of-institutes"><li>Indira Group of Institutes</li></NavLink>
                        <NavLink to="/indira-school-of-business-studies"><li>Indira School of Business Studies</li></NavLink>
                        <NavLink to="/recognition"><li>Recognition</li></NavLink>
                       {/* <a href={NIRF} target="_blank" rel="noreferrer"><li>NIRF 2022</li></a>*/}
                       <a href={NBA} target="_blank" rel="noreferrer"><li>NBA</li></a>
                        <h6 style={{fontWeight:'900', marginTop:'25px'}}>Hostel</h6>
                        {/*<a href="https://indiraisbsmba.edu.in/Hostel" target="_blank" rel="noreferrer"><li>Tribe Hostel Wakad</li></a>*/}
                        <NavLink to="/hostel"><li>Tribe Hostel Wakad</li></NavLink>
                        <NavLink to="/ivy-league-house"><li>Ivy League House</li></NavLink>
                        {/*<a href="https://indiraisbsmba.edu.in/Ivy_League_house" target="_blank" rel="noreferrer"><li>Ivy League House</li></a>*/}
                    </ul>
                </div>
            </div>
            <div className={coursesSubMenu ? "HomeSubmenuContainerInnerPage activeSubMenuInnerPage" : "HomeSubmenuContainerInnerPage"}>
                <div className="menuListSubInnerPage menuListSubInnerPagelist">
                    <div className="closeIconMnuInner" onClick={closeFullMenuHandler}>Close Menu <i className="fa fa-long-arrow-left"></i></div>
                    <ul>
                        <NavLink to="Mba" onClick={closeFullMenuHandler}><li>MBA</li></NavLink>
                        <NavLink to="phd" onClick={closeFullMenuHandler}><li>Ph.D</li></NavLink>
                        <NavLink to="mba-for-working-professionals" onClick={closeFullMenuHandler}><li>Supernumerary Mba(For Working Professionals )</li></NavLink>
                    </ul>
                </div>
            </div>
            <div className={placementSubMenu ? "HomeSubmenuContainerInnerPage activeSubMenuInnerPage" : "HomeSubmenuContainerInnerPage"}>
                <div className="menuListSubInnerPage menuListSubInnerPagelist">
                    <div className="closeIconMnuInner" onClick={closeFullMenuHandler}>Close Menu <i className="fa fa-long-arrow-left"></i></div>
                    <ul>
                        <NavLink to="/placement-cell" onClick={closeFullMenuHandler}><li>Placement Cell</li></NavLink>
                        <NavLink to="/placement-at-isbs" onClick={closeFullMenuHandler}><li>Placement at ISBS</li></NavLink>
                        <NavLink to="/recruiters" onClick={closeFullMenuHandler}><li>Our Recruiters</li></NavLink>
                        <NavLink to="/placement-report" onClick={closeFullMenuHandler}><li>Previous Years Placement Report</li></NavLink>
                    </ul>
                </div>
            </div>
            <div className={studentLifeSubMenu ? "HomeSubmenuContainerInnerPage activeSubMenuInnerPage" : "HomeSubmenuContainerInnerPage"}>
                <div className="menuListSubInnerPage menuListSubInnerPagelist">
                    <div className="closeIconMnuInner" onClick={closeFullMenuHandler}>Close Menu <i className="fa fa-long-arrow-left"></i></div>
                    <ul>
                        <NavLink to="/life-at-isbs"><li>Life At ISBS</li></NavLink>
                        <NavLink to="/student-committee"><li>Student Committees</li></NavLink>
                        <NavLink to="/student-engagement"><li onClick={closeFullMenuHandler}>Student Engagement</li></NavLink>
                        <NavLink to="/compliance-committee" onClick={closeFullMenuHandler}><li>Compliance Committee</li></NavLink>
                        <NavLink to="/student-club" onClick={closeFullMenuHandler}><li>Student Clubs</li></NavLink>
                        <NavLink to="/sports-activity" onClick={closeFullMenuHandler}><li>Sports Activity</li></NavLink>
                        <NavLink to="/video-gallery" onClick={closeFullMenuHandler}><li>Video Gallery</li></NavLink>
                    </ul>
                </div>
            </div>
            <div className={alumniSubMenu ? "HomeSubmenuContainerInnerPage activeSubMenuInnerPage" : "HomeSubmenuContainerInnerPage"}>
                <div className="menuListSubInnerPage menuListSubInnerPagelist">
                    <div className="closeIconMnuInner" onClick={closeFullMenuHandler}>Close Menu <i className="fa fa-long-arrow-left"></i></div>
                    <ul>
                        <NavLink to="alumni-association" onClick={closeFullMenuHandler}><li>Alumni Association</li></NavLink>
                        <NavLink to="alumni-committee" onClick={closeFullMenuHandler}><li>Alumni Committee</li></NavLink>
                        <NavLink to="alumni-network" onClick={closeFullMenuHandler}><li>Indira Alumni Network</li></NavLink>
                    </ul>
                </div>
            </div>
            <div className={researchSubMenu ? "HomeSubmenuContainerInnerPage activeSubMenuInnerPage" : "HomeSubmenuContainerInnerPage"}>
                <div className="menuListSubInnerPage menuListSubInnerPagelist">
                    <div className="closeIconMnuInner" onClick={closeFullMenuHandler}>Close Menu <i className="fa fa-long-arrow-left"></i></div>
                    <ul>
                        <NavLink to="/conference" onClick={closeFullMenuHandler}><li>Conference</li></NavLink>
                       {/*} <NavLink to="/indira-management-review" onClick={closeFullMenuHandler}><li>Indira Management Review</li></NavLink>*/}
                        <NavLink to="/research-faculty-development" onClick={closeFullMenuHandler}><li>Research & Faculty Development Program</li></NavLink>
                    </ul>
                </div>
            </div>
            <div className={mdpSubMenu ? "HomeSubmenuContainerInnerPage activeSubMenuInnerPage" : "HomeSubmenuContainerInnerPage"}>
                <div className="menuListSubInnerPage menuListSubInnerPagelist">
                    <div className="closeIconMnuInner" onClick={closeFullMenuHandler}>Close Menu <i className="fa fa-long-arrow-left"></i></div>
                    <ul>
                        <NavLink to="/mdp-about" onClick={closeFullMenuHandler}><li>About Us</li></NavLink>
                        <NavLink to="/mdp-programs" onClick={closeFullMenuHandler}><li>Our Programs</li></NavLink>
                        <NavLink to="/mdp-clients" onClick={closeFullMenuHandler}><li>Major Clients</li></NavLink>
                        <NavLink to="/mdp-trainers" onClick={closeFullMenuHandler}><li>Our Trainers</li></NavLink>
                        <NavLink to="/mdp-gallery" onClick={closeFullMenuHandler}><li>Gallery</li></NavLink>
                        <NavLink to="/mdp-contacts" onClick={closeFullMenuHandler}><li>Contact Us</li></NavLink>
                    </ul>
                </div>
            </div>
            <div className={PublicationsSubMenu ? "HomeSubmenuContainerInnerPage activeSubMenuInnerPage" : "HomeSubmenuContainerInnerPage"}>
                <div className="menuListSubInnerPage menuListSubInnerPagelist">
                    <div className="closeIconMnuInner" onClick={closeFullMenuHandler}>Close Menu <i className="fa fa-long-arrow-left"></i></div>
                    <ul>
                        <NavLink to="/editorial" onClick={closeFullMenuHandler}><li>Editorial</li></NavLink>
                        <NavLink to="/conference-proceedings" onClick={closeFullMenuHandler}><li>Conference Proceedings</li></NavLink>
                        <NavLink to="/research-journal" onClick={closeFullMenuHandler}><li>Research Journal</li></NavLink>
                    </ul>
                </div>
            </div>
            <marquee className="">
               <p><b>MBA Admission Alert : DTE Code for Indira School of Business Studies is 
                <span class="blinkanimation">*0646010110*</span> </b>| <b> An Autonomous Institute</b>  |  <b> Approved by AICTE | Accredited by NBA  | Permanently Affiliated to Savitribai Phule Pune University 
                {/*|   <a href={phdnotice1} target="_blank" style={{Color:"#000"}}>Interview Schedule for PhD Admission 2023</a>8*/}
               </b></p>
            </marquee>
        </Fragment>
    )
}
export default Header