import React, { Fragment, useState, useEffect } from "react";
import Header from "../../components/Header";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import BannerImage from "../../assets/images/mba/mba.png"
import Banner2Image from "../../assets/images/mba/banner2.png"
import Banner3Image from "../../assets/images/mba/banner3.png"
import Banner4Image from "../../assets/images/mba/banner4.png"
import Banner5Image from "../../assets/images/mba/banner5.png"
import AboutIconImage from "../../assets/images/phd/abIcon.png"
import Icon1Image from "../../assets/images/mba/icon1.png"
import Icon2Image from "../../assets/images/mba/icon2.png"
import Icon3Image from "../../assets/images/mba/icon3.png"
import Icon4Image from "../../assets/images/mba/icon4.png"
import Icon5Image from "../../assets/images/mba/icon5.png"
import Icon6Image from "../../assets/images/mba/icon6.png"
import Icon7Image from "../../assets/images/mba/icon7.png"
import Icon8Image from "../../assets/images/mba/icon8.png"
import Icon9Image from "../../assets/images/mba/icon9.png"
import Icon10Image from "../../assets/images/mba/icon10.png"
import ApplyImage from "../../assets/images/mba/apply-now-btn.png"
import DownloadImage from "../../assets/images/mba/download-btn.png"
import ImgNbaImage from "../../assets/images/mdp/nba.png"
import Img1Image from "../../assets/images/mba/Prof/Dr. Komal Singh.png"
import Img2Image from "../../assets/images/mba/Prof/Prof. Manmohan Vyas.png"
import Img3Image from "../../assets/images/mba/Prof/Dr. Tausif Mistry.png"
import Img4Image from "../../assets/images/mba/Prof/Dr. Kalpana K. Deshmukh.png"
import Img5Image from "../../assets/images/mba/Prof/Dr. Abhijit P Bobde.png"
import Img6Image from "../../assets/images/mba/Prof/Dr. Abhijit P Bobde-1.png"
import Img7Image from "../../assets/images/mba/Prof/Prof. Tanay Kurode.png"
import Img8Image from "../../assets/images/mba/Prof/Prof. Amrita Karnawat.png"
import Img9Image from "../../assets/images/mba/Prof/Prof. Purva Agarwal.png"
import Img10Image from "../../assets/images/mba/Prof/Prof. Arvind Laxman Burande.png"
import Img11Image from "../../assets/images/mba/Prof/Prof. Laxman Misal.png"
import Img12Image from "../../assets/images/mba/Prof/Prof. Mangesh Sanap.png"
// import Img13Image from "../../assets/images/mba/Prof/Prof. Anuja Limbad.png"
import Img14Image from "../../assets/images/mba/Prof/Prof. Trupti Joshi.png"
import Img15Image from "../../assets/images/mba/Prof/Prof. Umesh Gramopadhye.png"
import Img16Image from "../../assets/images/mba/Prof/Prof. Sumit.png"
import Img17Image from "../../assets/images/mba/Prof/Dr.Manisha Maddel.png"
import Img18Image from "../../assets/images/mba/Prof/Dr.Hetal Bhinde.jpg"
import Img19Image from "../../assets/images/mba/Prof/simar.png"
import Img20Image from "../../assets/images/mba/Prof/Dr.Sushil-Shende.png"
import Img22Image from "../../assets/images/mba/Prof/Mrs.RuchikaGarhwal.png"
import Img21Image from "../../assets/images/mba/Prof/Sanjay.png"
// import ShrirangChaudhari from "../../assets/images/mba/Prof/Shrirang-Choudhary.png"
import Anita from "../../assets/images/Anita_Mathapati.jpg"
import Asmita from "../../assets/images/Asmita_Gaikwad.jpg"
import Mrunalinee from "../../assets/images/Mrunalini_Khandare.jpg"
import Vaibhavi from "../../assets/images/Vaibhavi_Shahane.jpg"
import Rupesh from "../../assets/images/Roopesh_Sir.jpg"
import Shalaka from "../../assets/images/Dr. Shalaka Ayarekar.png"



import Facility1 from "../../assets/images/mba/Facilities/Auditorium.jpeg"
import Facility2 from "../../assets/images/mba/Facilities/basketballcourt.jpg"
import Facility3 from "../../assets/images/mba/Facilities/canteen out.jpg"
import Facility4 from "../../assets/images/mba/Facilities/computerlab.jpg"
import Facility5 from "../../assets/images/mba/Facilities/gym 4.jpg"
import Facility6 from "../../assets/images/mba/Facilities/hostel.jpg"
import Facility7 from "../../assets/images/mba/Facilities/incubationnew.jpeg"
import Facility8 from "../../assets/images/mba/Facilities/library.jpg"
import Facility9 from "../../assets/images/mba/Facilities/parking.jpg"
import Facility10 from "../../assets/images/mba/Facilities/relaxation paont.jpg"
import Facility11 from "../../assets/images/mba/Facilities/sport room.jpg"
import faci1 from "../../assets/images/mba/Facilities/1.jpg"
import faci2 from "../../assets/images/mba/Facilities/2.jpg"
import faci4 from "../../assets/images/mba/Facilities/4.jpg"
import faci5 from "../../assets/images/mba/Facilities/5.jpg"
import faci6 from "../../assets/images/mba/Facilities/6.jpg"
import faci7 from "../../assets/images/mba/Facilities/7.jpg"
import faci8 from "../../assets/images/mba/Facilities/8.jpg"
import faci9 from "../../assets/images/mba/Facilities/9.jpg"
import faci10 from "../../assets/images/mba/Facilities/10.jpg"
import faci11 from "../../assets/images/mba/Facilities/11.jpg"
import faci13 from "../../assets/images/mba/Facilities/13.jpg"
import faci14 from "../../assets/images/mba/Facilities/14.jpg"
import faci16 from "../../assets/images/mba/Facilities/16.jpg"
import faci17 from "../../assets/images/mba/Facilities/17.jpg"
import faci19 from "../../assets/images/mba/Facilities/19.jpg"
import Brochure from "../../assets/pdf/ISBS-Prospectus-2023.pdf"
import MeritList from "../../assets/pdf/Merit-list-for-website.pdf"
import ListofDoc from "../../assets/pdf/DOCUMETNS_CHECK_LIST_Updated.pdf"
import AdvtNew from "../../assets/pdf/Advt-ISBS-Marathi-and-Eng-for-IL-ACAP-23-24.pdf"
import MeritListpdf from "../../assets/pdf/Merit-list-mail-to-CET-cell.pdf"
import MeritListpdf_24_25 from "../../assets/pdf/Merit-List-for-IL-ACAP-24-25.pdf"
import FeesProposal from '../../assets/pdf/FEES_MBA_Proposal_2024-25.pdf'
import AdvACAP from "../../assets/pdf/Schedule-new-ISBS.pdf"
import ScheduleWorkingProfessional from "../../assets/pdf/Schedule-working-professional.pdf"






const Mba = () => {
    const [Abstract, setAbstract] = useState(true);
    const [Admission, setAdmission] = useState(false);
    const [Course, setCourse] = useState(false);
    const [Fee, setFee] = useState(false);
    const [Staff, setStaff] = useState(false);
    const [Facilities, setFacilities] = useState(false);
    const [TopBanner, setTopBanner] = useState(BannerImage);
    const [breadcrumb, setBreadcrumb] = useState("Abstract");
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const AbstractHandler = () => {
        setAbstract(true);
        setTopBanner(BannerImage);
        setAdmission(false)
        setCourse(false)
        setFee(false)
        setStaff(false)
        setFacilities(false)
        setBreadcrumb("Abstract")
    }
    const AdmissionHandler = () => {
        setAbstract(false);
        setTopBanner(Banner2Image);
        setAdmission(true)
        setCourse(false)
        setFee(false)
        setStaff(false)
        setFacilities(false)
        setBreadcrumb("Admission")
    }
    const CourseHandler = () => {
        setAbstract(false);
        setTopBanner(Banner3Image);
        setAdmission(false)
        setCourse(true)
        setFee(false)
        setStaff(false)
        setFacilities(false)
        setBreadcrumb("Course")
    }
    const FeeHandler = () => {
        setAbstract(false);
        setTopBanner(Banner4Image);
        setAdmission(false)
        setCourse(false)
        setFee(true)
        setStaff(false)
        setFacilities(false)
        setBreadcrumb("Fee")
    }
    const FacilitiesHandler = () => {
        setAbstract(false);
        setTopBanner(Banner5Image);
        setAdmission(false)
        setCourse(false)
        setFee(false)
        setStaff(false)
        setFacilities(true)
        setBreadcrumb("Facilities")
    }

    const StaffHandler = () => {
        setAbstract(false);
        setTopBanner(Banner5Image);
        setAdmission(false)
        setCourse(false)
        setFee(false)
        setStaff(true)
        setFacilities(false)
        setBreadcrumb("Staff")
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [popupShow, setPopupShow] = useState(false);
    const handleClosePopup = () => setPopupShow(false);
    const handleShowPopup = () => setPopupShow(true);

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://widgets.in8.nopaperforms.com/emwgts.js";
        script.async = true;

        document.body.appendChild(script)

    }, []);


    const disableRightClick = (e) => {
        e.preventDefault();
    };


    return (
        <Fragment>
            <Header></Header>
            <div className="col bannerCont">
                <img src={TopBanner} alt="" className="imgRes" />
                <h2 class="bannerTitleAbout leftAlignBannerTxt whiteText">Master of Business <br />Administration (MBA)</h2>
                <div className="buttonContainerCourse">
                    <img src={ApplyImage} alt="" onClick={handleShowPopup} />
                    <a href={Brochure} target="_blank"><img src={DownloadImage} alt="" /></a>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Business Leader of the Year 2022</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Congratulations to our Chairperson Dr. Tarita Ma'am and Group Director Prof. Chetan Sir for the awards at the Business Leader of the Year 2022 Ceremony.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className={popupShow ? "enquireNowFormCont enableEnquire " : "enquireNowFormCont"}>
                        <div className="closeBtn" onClick={handleClosePopup}>X</div>
                        <div className="formtitle">
                            <h4 className="titleTxt">Enquire Now</h4>
                        </div>

                        <div class="npf_wgts" data-height="500px" data-w="ad6d9c5fe9fce98f41e97e97434181b3"></div>
                        {/* <div>
                    <div className="form-group">
                        <input type="text" className="form-control" id="username" name="username" placeholder="Name" required="" />
                    </div>
                    <div className="form-group">
                        <input type="Email" className="form-control" id="email" name="email" placeholder="Email Address" required="" />
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" id="contact" name="contact" placeholder="Phone Number" required="" />
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" name="city" id="city" placeholder="City" required="" />
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" id="state" name="state" placeholder="State" required="" />
                    </div>
                    <div className="checkbox">
                        <label><input name="checkbox" type="checkbox" id="checkbox" value="" required="" />I agree to receive information regarding my registration by signing up on Indira Group of Institutes, Pune *</label>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="registerBtn" id="register" onClick={handleClosePopup}>Register</button>
                    </div>
                </div>
                <div id="success_msg" className="alert alert-success" style={{ display: 'none' }}>
                    <strong>Thank you!</strong>
                </div>
                <div id="error_msg" className="alert alert-warning" style={{ display: 'none' }}>
                    <strong>Warning!</strong>
                </div> */}

                    </div>
                </div>
            </div>


            <div className="col-md-12 paddingclass bgOrange1 mpaddingTop0">
                <div className="row">
                    <h3 className="blockTitle whiteText">Courses &#62; MBA &#62; <span style={{ color: '#fff', fontWeight: '900' }}> {breadcrumb}</span></h3>
                    <div className="col-md-3">
                        <div className="bgWhiteCorner">
                            <p><img src={Icon1Image} className="mbaSmIcon" /> <b>Level:</b> Post Graduate</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="bgWhiteCorner">
                            <p><img src={Icon2Image} className="mbaSmIcon" /> <b>Intake:</b> 240 Seats</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="bgWhiteCorner">
                            <p><img src={Icon3Image} className="mbaSmIcon" /> <b>Starting:</b> August</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="bgWhiteCorner">
                            <p><img src={Icon4Image} className="mbaSmIcon" /> <b>Study Mode:</b> Full Time
                                (2 Years)</p>
                        </div>
                    </div>
                    {/* <div className="col">
                    <div className="bgWhiteCorner">
                        <p><img src={Icon1Image} className="mbaSmIcon" /> <b>Location:</b> Universe Campus,
                            Tathawade</p>
                    </div>
                </div> */}
                </div>
            </div>
            <div className="backgroundGreyPlain">
                <div className="container">
                    <div className="paddingBottom3em paddingTop3em">
                        <div className="col-md-12 somi">
                            <div className="row marginZero tabViewHead">
                                <div className={Abstract ? "col-md-2 wd50Mba active" : "col-md-2 wd50Mba"}>
                                    <h3 className="tabTitle" onClick={AbstractHandler}><img src={Icon6Image} className="mbaSmIcon" />Overview</h3>
                                </div>
                                <div className={Admission ? "col-md-2 wd50Mba active" : "col-md-2 wd50Mba"}>
                                    <h3 className="tabTitle" onClick={AdmissionHandler}><img src={Icon7Image} className="mbaSmIcon" /> Admission</h3>
                                </div>
                                <div className={Course ? "col-md-2 wd50Mba  active" : "col-md-2 wd50Mba"}>
                                    <h3 className="tabTitle" onClick={CourseHandler}><img src={Icon8Image} className="mbaSmIcon" /> Course Structure</h3>
                                </div>
                                <div className={Fee ? "col-md-2 wd50Mba active" : "col-md-2 wd50Mba"}>
                                    <h3 className="tabTitle" onClick={FeeHandler}><img src={Icon9Image} className="mbaSmIcon" /> Fee Structure</h3>
                                </div>
                                <div className={Staff ? "col-md-2 wd50Mba active" : "col-md-2 wd50Mba"}>
                                    <h3 className="tabTitle" onClick={StaffHandler}><img src={Icon10Image} className="mbaSmIcon" /> Staff</h3>
                                </div>
                                <div className={Facilities ? "col-md-2 wd50Mba active" : "col-md-2 wd50Mba"}>
                                    <h3 className="tabTitle" onClick={FacilitiesHandler}><img src={AboutIconImage} className="mbaSmIcon" /> Facilities </h3>
                                </div>
                            </div>
                        </div>

                        {Abstract &&
                            <div className="whiteBackground paddingBottom3em">
                                <div className="container">
                                    <div className="row">
                                        <h3 className="blockTitle">Overview</h3>
                                        <p>Businesses are evolving and so are the problems associated with it. Management of two P’s – Master of Business Administration (MBA) is important because every
                                            successful organization should manage both the product and the people in an efficient manner. Students who wish to join global corporations and manage a large set of
                                            teams should take up the MBA course to improve upon their managerial skills. At ISBS our MBA program is NBA accredited, affiliated to Savitribai Phule Pune University
                                            (SPPU) and AICTE approved. The industry-academia interaction at ISBS brings forth managers of business corporations to extend the knowledge of their challenges,
                                            with the future leaders of business. All such efforts are aimed to have an impact commensurate with every student's ambition. The curriculum is ever evolving and has a
                                            progressive outlook with an intellectual rigor, where a sound foundation of critical thinking and articulating is laid.</p>
                                        <p>During your two year MBA program at ISBS, you will be having immense opportunities to learn from business leaders and thought leaders across domain. The industry
                                            visits covered as part of experiential learning will further aid in the learning process and provide a first-hand information of the corporate culture. With a good mix of
                                            theoretical learning and industry exposure, our curriculum is designed through Outcome Based Education (OBE) guidelines laid down by SPPU. The assessment is based
                                            on Choice Based Credit System (CBCS)</p>

                                        <h3 className="blockTitle">Master of Business Administration (MBA) in Pune – Objectives</h3>
                                        {/*<p>ISBS students undergoing MBA program will be equipped with requisite knowledge, skills and competence to face the rigors of corporate world.
                                            The students will develop into competent management professional with strong ethical values and making them capable of assuming a pivotal role in global economy.
                                            The MBA program will facilitate learning in theory and practices of different functional areas of management with an integrated approach to various functions of
                                            management.</p>
                                        <p>ISBS students undergoing MBA program will be equipped with requisite knowledge, skills and competence to face the rigors of corporate world.
                                            The students will develop into competent management professional with strong ethical values and making them capable of assuming a pivotal role in global economy.
                                            The MBA program will facilitate learning in theory and practices of different functional areas of management with an integrated approach to various functions of
                                            management.</p>
                                        <p>After completion of the MBA course which eventually aims at imparting the contemporary domain knowledge and skill require to be a dynamic and successful business
                                            manager.</p>*/}
                                        <ul className="paddingLeft15px liGb MpaddingLeft1em">
                                            <li>ISBS students undergoing MBA program will be equipped with requisite knowledge, skills and competence to face the rigors of corporate world.
                                                The students will develop into competent management professional with strong ethical values and making them capable of assuming a pivotal role in global economy.
                                                The MBA program will facilitate learning in theory and practices of different functional areas of management with an integrated approach to various functions of
                                                management.</li>
                                            <li>ISBS students undergoing MBA program will be equipped with requisite knowledge, skills and competence to face the rigors of corporate world.
                                                The students will develop into competent management professional with strong ethical values and making them capable of assuming a pivotal role in global economy.
                                                The MBA program will facilitate learning in theory and practices of different functional areas of management with an integrated approach to various functions of
                                                management.</li>
                                            <li>After completion of the MBA course which eventually aims at imparting the contemporary domain knowledge and skill require to be a dynamic and successful business
                                                manager.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {Admission &&
                            <div className="whiteBackground paddingBottom3em">
                                <div className="container">
                                    <div className="row">
                                        {/*} <p className="paddingTop1em">The MBA program of Indira School of Business Studies (ISBS) is affiliated to Savitribai Phule Pune University. Admissions to the MBA course are done as per the rules &
                                            regulations framed by the Directorate of Technical Education, Maharashtra State (DTE). The details of various dates (schedules) are published by them in their
                                            information brochure as well as available on their website www.dte.org.in/mba. Interested candidates must regularly visit the DTE website:
                                            www.dtemaharashtra.gov.in for eligibility, procedure & participation in the Common Process also referred as CAP. Candidates are advised to do their registration for
                                            the Admission Process with DTE, submit preferences of Institutes, and get their documents verified at designated facilitation centre’s (FC) allotted by DTE. After the
                                            examination process and the students' preferences, DTE will prepare the merit list for admissions. Candidate will get admission to a suitable college as per his/ her
                                            merit and the preferences filled.</p>
                                        <p>Indira School of Business Studies is part of CAP process and candidates desirous to get admission at ISBS may give it as their preference. However, please note that
                                            the allotments (Round Wise) are done by DTE. Indira School of Business Studies DTE Code is 0646010110 & the choice code for admission is 646010110 for specific
                                          queries one should personally visit ISBS Admission Cell in the campus</p>*/}

                                        {/* <p className="paddingTop1em"><b>Important Notice:</b> <a href={MeritList} target="_blank" style={{color:"#000",marginBottom:"10px",fontSize:"16px",textDecoration:"underline",fontWeight:"bold"}}>  Merit list for IL / ACAP admissions for Academic Year 2022-23</a></p> */}
                                        {/* 29/07/2023
                                          <p >The MBA program of Indira School of Business Studies (ISBS) is affiliated to Savitribai Phule Pune University. Admissions to the MBA course are done as per the rules & 
                                            regulations framed by the Directorate of Technical Education, Maharashtra State (DTE). The details of various dates (schedules) are published by them in their information 
                                            brochure as well as available on their website www.dte.org.in/mba. Interested candidates must regularly visit the DTE website: www.dtemaharashtra.gov.in and State Common Entrance 
                                            Test Cell, Maharashtra state website: www.cetcell.mahacet.org  for eligibility, procedure & participation in the Common Process also referred as CAP. Candidates are advised to do their
                                             registration for the Admission Process with DTE, submit preferences of Institutes, and get their documents verified at designated facilitation centres (FC) allotted by DTE. 
                                             After the examination process and the student's preferences, DTE will prepare the merit list for admissions. 
                                             The candidate will get admission to a suitable college as per his/ her merit and the preferences filled.</p>
                                             <p>Indira School of Business Studies is part of CAP process and candidates desirous to get admission at ISBS
                                                 may give it as their preference. However, please note that the allotments (Round Wise) are done by DTE.
                                                  Indira School of Business Studies DTE Code is 0646010110 & the choice code for admission is 646010110 for 
                                                  specific queries one should personally visit ISBS Admission Cell in the campus</p>

                                        <h3 className="blockTitle">Eligibility Criteria:</h3>
                                        <p>Passed minimum Three-year Duration Bachelor’s Degree awarded by any of the Universities recognised by University Grants Commission or Association of Indian
                                            Universities in any discipline with at least 50% marks in aggregate or equivalent (at least 45% in case of candidates of backward class categories and Persons with
                                            Disability belonging to Maharashtra State only) or its equivalent;</p>
                                        <p>OR</p>
                                        <p>Appeared for the final year examination of any Bachelor’s degree to be awarded by any of the Universities recognised by University Grants Commission or Association
                                            of Indian Universities in any discipline.</p>
                                        <h3 className="blockTitle">Note:</h3>
                                        <ul className="paddingLeft15px">
                                            <li>Aggregate marks means the grand total of marks obtained by the candidate in subjects on which the class declaration is made in the particular University from
                                                which the candidate is passing the qualifying examination.</li>
                                            <li>In case the candidates are awarded grades/CGPA instead of marks, the conversion of grades/CGPA to percentage of marks would be based on the procedure
                                                certified by the University/institution from where they have obtained the bachelor's degree.</li>
                                            <li>The percentage of marks shall be calculated by rounding off to two places after decimal.</li>
                                            <li>The candidates belonging to SC, VJ/DT (NT (A)), NT (B), NT(C), NT (D), OBC SBC and SEBC categories should produce “Caste Validity Certificate” issued by Scrutiny
                                                Committee of Social Welfare Department and the Candidate belonging to ST category should submit “Tribe Validity Certificate” issued by Scrutiny Committee of
                                                Tribal Department and valid Non Creamy Layer certificate except SC, ST candidates at the time of verification of documents.</li>
                                        </ul> */}
                                        <br />
                                        {/* <p><a href={MeritListpdf} target="_blank">Merit list for Institute Level seats / Vacancy Against CAP admissions for MBA course for the Academic Year 2023-2024</a></p>
                                        <p><a href={AdvtNew} target="_blank">Institute invites applications from eligible candidates for Institute Level seats / vacancy Against CAP admissions for MBA course for the Academic Year 2023-2024</a></p> */}

                                        <p>The Indira School of Business Studies (ISBS) an autonomous institution affiliated to Savitribai Phule Pune University offers a MBA program.  Admissions to the MBA program are done as per the rules & regulations framed by the Directorate of Technical Education, (DTE), Maharashtra State.</p>

                                        <p>The details of various dates (schedules) are published by CET Cell, DTE in their information brochure and are available on their website. Interested candidates must regularly visit the website: <a href="https://cetcell.mahacet.org/" target="_blank">https://cetcell.mahacet.org</a>  for eligibility, procedure & participation in the Common Admission Process also referred to as CAP.</p>

                                        <p>Candidates are advised to do their registration for the Admission Process with CET Cell, get their documents verified at designated Facilitation Centres (FC) allotted by CET Cell. After the examination process and verification of documents, CET Cell will prepare the final merit list. Once the final merit list is published by CET Cell then the aspiring candidates need to fill in the preference form for admitting to their preferred institute for MBA program. The CET will publish the allotment list for admission considering the preferences filled by the candidate and the candidate's merit. On allocation of the institute to the candidate he/ she needs to report to the institute in the prescribed time frame and complete the procedure of admission at the institute.</p>

                                        <p>The Indira School of Business Studies is a part of the CAP process and candidates desirous to get admission at ISBS may give it as their preference. However, please note that the allotments (Round Wise) are done by CET Cell.</p>
                                        <p>The Indira School of Business Studies DTE Code is 0646010110 & the Choice Code for Admission is 0646010110. For specific queries one should personally visit ISBS Admission Cell on the campus.</p>

                                        <h4>Eligibility Criteria: </h4>
                                        <p>Passed a minimum Three-year Duration bachelor's degree awarded by any of the Universities recognized by the University Grants Commission or Association of Indian Universities in any discipline with at least 50% marks in aggregate or equivalent (at least 45% in case of candidates of backward class categories and Persons with Disability belonging to Maharashtra State only) or its equivalent. </p>
                                        <p><b>OR</b></p>
                                        <p>Appeared for the final year examination of any bachelor's degree to be awarded by any of the Universities recognized by the University Grants Commission or Association of Indian Universities in any discipline. </p>

                                        <p><b>Note: </b></p>
                                        <p>Aggregate marks mean the grand total of marks obtained by the candidate in subjects on which the class declaration is made in the particular University from which the candidate is passing the qualifying examination. </p>
                                        <p>In case the candidates are awarded grades/CGPA instead of marks, the conversion of grades/CGPA to the percentage of marks would be based on the procedure certified by the University/institution from where they have obtained the bachelor's degree. </p>
                                        <p>The percentage of marks shall be calculated by rounding off to two places after decimal.
                                            The candidates belonging to SC, VJ/DT (NT (A)), NT (B), NT(C), NT (D), OBC SBC and SEBC categories should produce “Caste Validity Certificate” issued by Scrutiny Committee of Social Welfare Department and the Candidate belonging to ST category should submit “Tribe Validity Certificate” issued by Scrutiny Committee of Tribal Department and valid Non Creamy Layer certificate except SC, ST candidates at the time of verification of documents.
                                        </p>

                                        <br/>
                                        <div onContextMenu={disableRightClick}>
                                            <p><a href={MeritListpdf_24_25} target="_blank">Merit List for Institute Level (IL) Seats and Against CAP Seats (ACAP) for MBA admissions for the AY 2024-25</a></p>
                                        </div>

                                        <br/>
                                        <div onContextMenu={disableRightClick}>
                                            <p><a href={ScheduleWorkingProfessional} target="_blank">Applications invited for probable seats remaining vacant/ against cancellation after CAP rounds & Institute Level seats for “WORKING PROFESSIONALS” MBA Admission 2024-25.</a></p>

                                        </div>

                                        <br />
                                        <div onContextMenu={disableRightClick}>
                                            <p><a href={AdvACAP} target="_blank">Applications invited for probable seats remaining vacant/ against cancellation after CAP rounds & Institute Level seats for MBA Admission 2024-25</a></p>

                                        </div>

                                        <p><a href={ListofDoc} target="_blank" >List of the Documents Required for Admission</a>
                                           
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }
                        {Course &&
                            <div className="whiteBackground paddingBottom3em">
                                <div className="container">
                                    <div className="row">
                                        <p className="paddingTop1em"><b>Masters of Business Administration (MBA)</b> : 2 - year full time program<br />
                                            <b>Semesters:</b>  4 semesters<br />
                                            <b>Choice Based Credit System(CBCS) <br />
                                                Classroom Teaching</b><br />
                                        </p>
                                        <p>The MBA program offered by Indira School of Business is affiliated with Savitribai Phule Pune University, recognized by the State Government of Maharashtra, and Approved by the All-India Council for Technical Education. The MBA program is an Outcome Based Education and Outcome Based Assessment program that represents an education approach and assessment methodology centered around achieving desired outcomes. Outcome-based education emphasizes the performance of students, with the curriculum of the MBA program introducing an elevated level of education. This program can be completed within four semesters and implements a Choice Based Credit System, enabling students to select courses aligned with their individual interests, aptitudes, and career aspirations. Students can successfully obtain their degree by accumulating the requisite number of credits as stipulated by the course. The program incorporates a comprehensive framework consisting of five well-defined Program Educational Objectives (PEOs), ten Program Outcomes (POs), and specialization-specific Program Specific Outcomes (PSOs) to guide and assess student learning in their chosen fields of study.</p>
                                        <p>The Choice-Based Credit System (CBCS) offers students an academically enriching and highly adaptable learning system that integrates extensive opportunities for skill development and practical application. In addition to regular courses, the curriculum includes a diverse range of course types designed to cater to students' varying intellectual abilities. Foundation courses are available for students seeking fundamental proficiency in subjects such as accounting and economics. Enrichment courses are tailored to students who aspire to delve deeper into their areas of interest beyond the prescribed syllabus. Moreover, Alternative study credit courses provide students with the autonomy to pursue independent study in management through innovative projects, live assignments, and online courses like MOOCs, allowing for personalized learning experiences.</p>
                                        <p>Within the realm of specialization, students are afforded the opportunity to select both a major and a minor specialization from a predefined array of choices. Additionally, those students who possess an inclination towards entrepreneurship may opt for AICTE's Start-up: Launching and Sustaining program, which enables them to forgo elective courses. Furthermore, the assessment process employed adheres to an outcome-based framework. Outcome-based assessment entails that instructors delineate the expected capabilities and competencies that students should acquire upon concluding a particular course.</p>
                                        <p><b>ISBS presents a comprehensive range of major specializations for students to choose from, including:</b></p>
                                        <ul className="ulpaddingleft">
                                            <li>Marketing Management</li>
                                            <li> Financial Management</li>
                                            <li> Human Resource Management</li>
                                            <li>Operations Management</li>

                                        </ul>
                                        <p>Furthermore, students who pursue a major specialization also have the flexibility to select a minor specialization from the following options:</p>
                                        <ul className="ulpaddingleft">
                                            <li>Marketing Management</li>
                                            <li>   Financial Management</li>
                                            <li> Human Resource Management</li>
                                            <li>  Operations Management</li>
                                            <li>  Business Analytics</li>

                                        </ul>
                                        <p>This expansive selection allows students to tailor their educational path to align with their specific interests and career goals.</p>
                                        <h4>Note:</h4>
                                        <p>Institutes may offer ONLY SELECT specializations based on industry needs, faculty strength & competencies, student demands, employability potential, etc.</p>
                                        <p>Institutes MAY NOT offer a specialization if a minimum of 20% of students are not registered for that specialization.</p>
                                        <p>The Institute MAY NOT offer an elective course if a minimum of 20% of students are not registered for that elective course.</p>
                                        <p>Eligibility Major Specialization + Minor Specialization Combination:</p>
                                        <p><b>For a Major + Minor Specialization combination, the learner shall complete.</b></p>
                                        <ul className="ulpaddingleft">
                                            <li>Major Specialization – Courses: Total 9 (4 Subject Core courses and 5 Subject Elective courses)</li>
                                            <li>Minor Specialization – Courses: Total 4 (2 Subject Core courses and 2 Subject Elective courses)</li>
                                        </ul>

                                        <p><b>For a Major + Minor Specialization combination, the learner shall earn.</b></p>
                                        <ul className="ulpaddingleft">
                                            <li>Major Specialization – Credits: Total 22 (12 Credits from Subject Core + Minimum 10 Credits from Subject Electives)</li>
                                            <li>Minor Specialization – Credits: Total 10 (6 Credits from Subject Core + Minimum 4 Credits from Subject Electives)</li>
                                        </ul>
                                        <p>The 10 credits of the MINOR specialization shall be from a single specialization, out of which 6 credits shall be mandatorily earned through the Subject Core Courses.</p>
                                        <p>The Major + Minor specialization combination is OPTIONAL.</p>
                                        <p>Students shall be permitted to opt for ANY Major + ANY Minor specialization combination, subject to institutional norms and guidelines, issued from time to time.</p>
                                        <p>A student opting for a Major + Minor specialization combination shall opt for Foundation Courses / Enrichment Courses / Alternative Study Credit Courses ONLY in lieu of Generic Elective (GE - IL) Courses.</p>
                                        <p>Institutes may stipulate additional criteria of minimum SGPA / CGPA, number of backlogs, and expectations about specific graduation disciplines for students who wish to take up a specific specialization / specific major-minor combination. Such criteria may also involve the potential employability criteria for a particular specialization / Major + Minor specialization combination.</p>
                                        <h4>Options & Guidelines for Choice of Specialization:</h4>
                                        <ul className="ulpaddingleft">
                                            <li>Students can opt for a single specialization (i.e. Major Only)</li>
                                            <li>Students can opt for two specializations (i.e. Major + Minor Combination)</li>
                                            <li>Specializations that are offered ONLY as MINOR offered in the SECOND YEAR ONLY. (3 choices)</li>
                                            <li>Courses for the Minor specialization will be taken up in the second year ONLY (in either Sem III or Sem IV or in a combination of Sem III and IV).</li>
                                            <li>The Major specialization of a student will be determined by the Subject Core (SC) courses and the Subject Elective (SE - IL) courses chosen in Sem II.</li>
                                            <li>All courses (Subject Core (SC) courses and the Subject Elective (SE - IL) courses) chosen in Sem II shall belong to the same specialization.</li>
                                            <li>The learners shall generally complete 6 Subject Core courses (2 each in semester II, III, IV) and 7 Subject Elective courses (2, 3, 2 each in semester II, III, IV respectively). In this case he /she will be awarded MBA (Functional Area Specialization) degree, e.g. MBA (Marketing), MBA (Finance), etc.</li>
                                            <li>The learners shall complete 4 Subject Core courses and 5 Subject Elective courses of Major specialization and 2 Subject Core courses and 2 Subject Elective courses of Minor specialization. In this case he /she shall be awarded MBA (Functional Area Specialization Major + Functional Area Specialization Minor) degree, e.g. MBA (Marketing + Finance), etc.</li>

                                        </ul>
                                        <h4>Specialization Options at ISBS</h4>
                                        <p>Indira School of Business Studies (ISBS) presents a diverse range of MBA specializations, encompassing major areas such as Marketing Management, Financial Management, Human Resource Management, and Operations and Supply Chain Management. Moreover, the institute offers minor specializations that can be combined with the chosen major specialization. Students can select one major specialization from the aforementioned options and complement it with one minor specialization chosen from Marketing Management, Financial Management, Human Resource Management, Operations, and Supply Chain Management, or Business Analytics.</p>
                                        <p>These specializations offered by ISBS are meticulously designed in accordance with the regulations stipulated by Savitribai Phule Pune University and the evolving demands of the industry. They augment the prestigious MBA degree, providing students with a competitive advantage in both academic and professional domains. The institute continually revises its specializations to align with the guidelines of Savitribai Phule Pune University and to cater to the evolving requirements of the industry.</p>

                                        {/* <p>The new MBA programme offered by SPPU from this year, is an Outcome Based Education and Outcome Based Assessment programme. The outcome based
                                            education implies there is a performer (student) and the focus is on performance of the students, not the activity or task to be performed. The new curriculum takes
                                            MBA programme to the next level. The programme can be completed in four semesters and the curriculum is based on Choice Based Credit System. The
                                            syllabus offers wide choice for students to opt for various courses based on their interest, aptitude and career goals. The programme enables a student to obtain a
                                            degree by accumulating the required number of credits prescribed for the course. The programme has well defined five Programme Educational Objectives (PEOs), ten
                                            Programme Outcomes (POs) and Programme Specific outcomes (PSOs) for the specialization courses, which are to be developed by respective institutes.</p>
                                        <p>The Choice Based Credit System provides the students with an academically rich, highly flexible, learning system, blended with abundant provision for skill
                                            development and a practical orientation. The curriculum, in addition to regular courses, is equipped with various types of courses to cater to the different intellectual
                                            level of students. Foundation courses are for those students who are seeking basic abilities of that subject like accounting, economics etc. Enrichment courses are
                                            courses for students who want to go beyond the syllabus and in areas of their interest. And Alternative study credit courses are courses for students who want to do
                                            independent study of their choice in the field of management through innovative/live projects, online courses like MOOCs etc</p>
                                        <p>In case of specialisation, students has the option of one major and one minor specialisation from the given list. Also Students who are interested in pursuing
                                            entrepreneurship can opt for Start-up: Launching and Sustaining program of AICTE, skipping elective courses. Also the assessment process is outcome based
                                            assessment. Outcome based assessment asks teachers to first identify what it is that we expect students to be able to do once they have completed a course.
                                        </p>
                                        <p>At ISBS, we offer following specialisation to students:</p>
                                        <ul className="paddingLeft15px">
                                            <li>Marketing Management</li>
                                            <li>Financial Management</li>
                                            <li>Human Resource Management</li>
                                            <li>Operations Management</li>
                                            <li>Business Analytics</li>
                                        </ul>
                                        <h3 className="blockTitle">Note:</h3>
                                        <p>Institutes may offer ONLY SELECT specializations based on industry needs, faculty strength & competencies, student demands, employability potential, etc.</p>
                                        <p>Institutes MAY NOT offer a specialization if a minimum of 20% of students are not registered for that specialization</p>
                                        <p>The Institute MAY NOT offer an elective course if a minimum of 20% of students are not registered for that elective course.</p>
                                        <h3 className="blockTitle">Eligibility Major Specialization + Minor Specialization Combination:</h3>
                                        <p>For a Major + Minor Specialization combination the learner shall complete</p>
                                        <ul className="paddingLeft15px">
                                            <li>Major Specialization – Courses: Total 9 (4 Subject Core courses and 5 Subject Elective courses)</li>
                                            <li>Minor Specialization – Courses: Total 4 (2 Subject Core courses and 2 Subject Elective courses)</li>
                                        </ul>
                                        <p>For a Major + Minor Specialization combination the learner shall earn</p>
                                        <ul className="paddingLeft15px">
                                            <li>Major Specialization – Credits: Total 22 (12 Credits from Subject Core + Minimum 10 Credits from Subject Electives)</li>
                                            <li>Minor Specialization – Credits: Total 10 (6 Credits from Subject Core + Minimum 4 Credits from Subject Electives)</li>
                                        </ul>
                                        <p>The 10 credits of the MINOR specialization shall be from a single specialization, out of which 6 credits shall be mandatorily earned through the Subject Core Courses.</p>
                                        <p>The Major + Minor specialization combination is OPTIONAL.</p>
                                        <p>Students shall be permitted to opt for ANY Major + ANY Minor specialization combination, subject to institutional norms and guidelines, issued from time to time.</p>
                                        <p>A student opting for Major + Minor specialization combination shall opt for Foundation Courses / Enrichment Courses / Alternative Study Credit Courses ONLY in lieu of
                                            Generic Elective (GE - IL) Courses.</p>
                                        <p>Institutes may stipulate additional criteria of minimum SGPA / CGPA, number of backlogs, expectations about specific graduation discipline for students who wish to
                                            take up a specific specialization / specific major minor combination. Such criteria may also involve the potential employability criteria for a particular specialization /
                                            Major + Minor specialization combination.</p>
                                        <h3 className="blockTitle">Options & Guidelines for Choice of Specialization:</h3>
                                        <ul className="paddingLeft15px">
                                            <li>Students can opt for a single specialization (i.e. Major Only – 5 choices)</li>
                                            <li>Students can opt for a two specializations (i.e. Major + Minor Combination – 1(Major) + 7(Minor) choices)</li>
                                            <li>Specializations which are offered ONLY as MINOR shall be offered in SECOND YEAR ONLY. (3 choices)</li>
                                            <li>Courses for the Minor specialization shall be taken up in the second year ONLY (in either Sem III or Sem IV or in a combination of Sem III and IV).</li>
                                            <li>The Major specialization of a student shall be determined by the Subject Core (SC) courses and the Subject</li>
                                            <li>Elective (SE - IL) courses chosen in Sem II.</li>
                                            <li>All courses (Subject Core (SC) courses and the Subject Elective (SE - IL) courses) chosen in Sem II shall belong to the same specialization.</li>
                                            <li>The learners shall generally complete 6 Subject Core courses (2 each in semester II, III, IV) and 7 Subject Elective courses (2, 3, 2 each in semester II, III, IV respectively). In this case he /she shall be awarded MBA (Functional Area Specialization) degree, e.g. MBA (Marketing), MBA (Finance), etc.</li>
                                            <li>The learners shall complete 4 Subject Core courses and 5 Subject Elective courses of Major specialization and 2 Subject Core courses and 2 Subject Elective courses of Minor specialization. In this case he /she shall be awarded MBA (Functional Area Specialization Major + Functional Area Specialization Minor) degree, e.g. MBA (Marketing + Finance), etc.</li>
                                        </ul>
                                        <h3 className="blockTitle">MBA Specialization:</h3>
                                        <p>Indira School Of Business Studies provide different MBA specializations like Marketing Management, Financial Management, Human Resource Management and Operations and Supply chain Management. This reflects the increasing role of interdisciplinary paradigm in industry and corporate sector. These MBA specializations add an edge to this elite degree often seen as a vehicle to success across domains of academia and industry.</p>

                                        */}
                                    </div>
                                </div>
                            </div>
                        }
                        {Fee &&
                            <div className="whiteBackground paddingBottom3em">
                                <div className="container">
                                    <div className="row">
                                        <h3 className="blockTitle text-center">Fee Structure:</h3>


                                        {/* <iframe src={FeesProposal} title="Fees Proposal submitted to FRA for the AY 2024-25"
                                        width="100%" height="700"></iframe> */}

                                        <p className="text-center"><b>  S.C.E.S. Indira School of Business Studies, Tathawade, Pune - 411033 (choice code 0646010110) <br />
                                            Fees Approved by the Fees Regulating Authority, Govt. of Maharashtra </b></p>
                                        <p className="text-center"><b>MBA 1st year fees for the academic year 2024-25 (MBA Batch 2024-26)</b></p>
                                        <div className="col-md-12">
                                            <table className="table table-responsive table-bordered" responsive>
                                                <tbody>
                                                    <tr>
                                                        <td >
                                                            <b>Category</b>
                                                        </td>
                                                        <td colspan="2" >
                                                            <b> Open / OMS</b>
                                                        </td>
                                                        <td colspan="2" >
                                                            <b>OBC/EBC/EWS/SEBC</b>
                                                        </td>
                                                        <td colspan="2" >
                                                            <b> VJ/DT/NT/SBC/TFWS</b>
                                                        </td>
                                                        <td colspan="2" >
                                                            <b>SC / ST</b>
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td >
                                                            <b> Gender</b>
                                                        </td>
                                                        <td  >
                                                            Male
                                                        </td>
                                                        <td  >
                                                            Female
                                                        </td>
                                                        <td  >
                                                            Male
                                                        </td>
                                                        <td >
                                                            Female
                                                        </td>
                                                        <td  >
                                                            Male
                                                        </td>
                                                        <td >
                                                            Female
                                                        </td>
                                                        <td  >
                                                            Male
                                                        </td>
                                                        <td>
                                                            Female
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td >
                                                            <b>Tuition Fees</b>
                                                        </td>
                                                        <td  >
                                                            233044
                                                        </td>
                                                        <td >
                                                            233044
                                                        </td>
                                                        <td  >
                                                            116522
                                                        </td>
                                                        <td  >
                                                            0
                                                        </td>
                                                        <td>
                                                            0
                                                        </td>
                                                        <td >
                                                            0
                                                        </td>
                                                        <td >
                                                            0
                                                        </td>
                                                        <td>
                                                            0
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td >
                                                            <b>Development Fees</b>
                                                        </td>
                                                        <td >
                                                            34956
                                                        </td>
                                                        <td >
                                                            34956
                                                        </td>
                                                        <td  >
                                                            34956
                                                        </td>
                                                        <td >
                                                            34956
                                                        </td>
                                                        <td  >
                                                            34956
                                                        </td>
                                                        <td  >
                                                            34956
                                                        </td>
                                                        <td  >
                                                            0
                                                        </td>
                                                        <td  >
                                                            0
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td >
                                                            <b>Total</b>
                                                        </td>
                                                        <td >
                                                            <b> 268000</b>
                                                        </td>
                                                        <td >
                                                            <b>  268000</b>
                                                        </td>
                                                        <td  >
                                                            <b>  151478</b>
                                                        </td>
                                                        <td >
                                                            <b>  34956</b>
                                                        </td>
                                                        <td >
                                                            <b> 34956</b>
                                                        </td>
                                                        <td  >
                                                            <b>  34956</b>
                                                        </td>
                                                        <td >
                                                            <b> 0</b>
                                                        </td>
                                                        <td  >
                                                            <b> 0</b>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <p className="text-center"><b>MBA 2nd year fees for the academic year 2024-25 (MBA Batch 2023-25)</b></p>
                                        <div className="col-md-12">
                                            <table className="table table-responsive  table-bordered" responsive>

                                                <tbody>

                                                    <tr >
                                                        <td>
                                                            <b>Category</b>
                                                        </td>
                                                        <td colspan="2">
                                                            <b>Open / OMS</b>
                                                        </td>
                                                        <td colspan="2" >
                                                            <b>OBC/EBC/EWS/SEBC</b>
                                                        </td>
                                                        <td colspan="2">
                                                            <b>VJ/DT/NT/SBC/TFWS</b>
                                                        </td>
                                                        <td colspan="2">
                                                            <b>SC / ST</b>
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td>
                                                            <b>Gender</b>
                                                        </td>
                                                        <td>
                                                            Male
                                                        </td>
                                                        <td >
                                                            Female
                                                        </td>
                                                        <td  >
                                                            Male
                                                        </td>
                                                        <td >
                                                            Female
                                                        </td>
                                                        <td >
                                                            Male
                                                        </td>
                                                        <td >
                                                            Female
                                                        </td>
                                                        <td >
                                                            Male
                                                        </td>
                                                        <td>
                                                            Female
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td>
                                                            <b>Tuition Fees</b>
                                                        </td>
                                                        <td  >
                                                            221739
                                                        </td>
                                                        <td  >
                                                            221739
                                                        </td>
                                                        <td >
                                                            110870
                                                        </td>
                                                        <td  >
                                                            0
                                                        </td>
                                                        <td >
                                                            0
                                                        </td>
                                                        <td >
                                                            0
                                                        </td>
                                                        <td >
                                                            0
                                                        </td>
                                                        <td >
                                                            0
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td >
                                                            <b>Development Fees</b>
                                                        </td>
                                                        <td  >
                                                            33261
                                                        </td>
                                                        <td >
                                                            33261
                                                        </td>
                                                        <td >
                                                            33261
                                                        </td>
                                                        <td >
                                                            33261
                                                        </td>
                                                        <td >
                                                            33261
                                                        </td>
                                                        <td>
                                                            33261
                                                        </td>
                                                        <td>
                                                            0
                                                        </td>
                                                        <td >
                                                            0
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td >
                                                            <b>Total</b>
                                                        </td>
                                                        <td >
                                                            <b>255000</b>
                                                        </td>
                                                        <td  >
                                                            <b>255000</b>
                                                        </td>
                                                        <td>
                                                            <b>144131</b>
                                                        </td>
                                                        <td>
                                                            <b>33261</b>
                                                        </td>
                                                        <td>
                                                            <b>33261</b>
                                                        </td>
                                                        <td>
                                                            <b>33261</b>
                                                        </td>
                                                        <td >
                                                            <b> 0</b>
                                                        </td>
                                                        <td >
                                                            <b> 0</b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="9">
                                                            <br />
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td colspan="9">
                                                            Note: Scholarship/Free-ship benefit is applicable only for students of Maharashtra State, admitted through CAP. Category-wise fees shall be charged based on fulfillment of respective Maharashtra Govt. authorities scholarship eligibility criteria. Full fees will be applicable if you are not eligible for scholarship.
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                            <p className="text-center"><a href={FeesProposal} target="_blank"
                                                style={{
                                                    color: "#000", marginBottom: "17px", display: "block", fontSize: "16px", textDecoration: "underline",
                                                    fontWeight: "bold"
                                                }}><span class="glyphicon glyphicon-hand-right">
                                                </span>Fees Proposal submitted to FRA for the AY 2024-25</a></p>

                                            <p><b>Note: Category-wise fees shall be charged based on fulfilment of respective Govt. authorities scholarship eligibility criteria. Full fees will be applicable if you are not eligible for scholarship</b></p>
                                            {/* <p>The above fee is finalized by the Fees Regulating Authority (FRA) of the Govt. of Maharashtra for AY 2022-23. This is an interim fee for AY 2023-24 based on last year’s FRA-approved fees and it would be finalized by the Fee Regulating Authority (FRA) in due course of time, which (difference) will be payable by the students. If the fees are reduced by the FRA, you will get a refund, or can be adjusted in your second-year fees.</p>
                                            <p><b>Note:</b> All the category-related benefits will be applicable subject to the fulfillment of eligibility criteria/availability of all the required documents specified by the respective competent authorities. </p> */}


                                            <p><b>Institute Fees will be accepted in the following Modes:  </b></p>
                                            <p>1. Cheque In Favour of Indira School of Business Studies MBA  </p>
                                            <p><b>Or </b> </p>
                                            <p>2.D.D. in Favour of Indira School of Business Studies MBA Payable at Pune </p>
                                            <p><b>Or </b> </p>
                                            <p>3.Online NEFT/RTGS Payment (Below are details of online payment)  </p>
                                            <p><b>Account Name:</b> -Indira School of Business Studies MBA Escrow Account  </p>

                                            <p><b>Bank Name :</b> - IndusInd Bank  </p>

                                            <p> <b>Account No:</b> - 201023570776  </p>

                                            <p><b>Account Type:</b> - Escrow Account </p>

                                            <p> <b>IFSC Code:</b> INDB0000999  </p>





                                            {/* <p><b>Name:</b> -Indira School of Business Studies MBA  </p>

                                          <p> <b>Account No:</b> - 50200024799483  </p>

                                          <p> <b>IFSC Code:</b> HDFC0004887  </p>

                                          <p><b>Bank Name:</b> -HDFC Bank Ltd </p> 

                                          <p> <b>Branch:</b> - Wakad Pune 411057. </p> 

                                          <p><b> MICR Code:</b> 411240061  </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }



                        {Facilities &&
                            <div className="whiteBackground paddingBottom3em ">
                                <div className="container">
                                    <div className="row staffMba">
                                        <h3 className="blockTitle">Facilities</h3>
                                        {/*} <p>Indira School of Business Studies is located in the hub of the path towards the Pune - Mumbai Expressway. ISBS believes in providing an environment that fosters continuous improvement and innovation with related technical support and facilities to enhance student learning and faculty effectiveness.</p>
                                        <p>Some distinctive features of ISBS inside a 7000 sq. ft. built-up campus are: Modern classrooms, Seminar Halls, Syndicate Rooms and Tutorial Centre’s with complete Wi-Fi connectivity make teaching and learning a memorable experience for the faculty and students. Each class room is fitted with projection television, overhead projectors and white boards.</p>
                                    
                                    <h3>IT Infrastructure And Computer Center:</h3>
                                    <p>ISBS has state-of-the-art IT infrastructure. The institute is equipped with the latest workstations and interactive smart classrooms with audio-visual units. ISBS has one to one/many video conferencing facilities. Wireless connectivity for students is provided throughout the campus including the hostels. The 50 mbps dedicated internet connectivity caters to the internet requirements within the campus 24x7.

                                      <br/>The students experience, enhanced communication, coordination and collaboration with their faculty members, coordinators and team mates via personalized mail box on Microsoft office365 and 25GB free space on sky drive. The Library is digitally available to students for their reference books within the campus. The IT Infrastructure also covers the latest licensed software for designing and simulation of projects, statistical analysis and project presentation. Security (physical & data access) within the campus is administered via surveillance cameras and data access policies implemented via various servers using Microsoft Technology.</p>
                                    <h3>Multimedia Library:</h3>
                                    <p>The library has a separate place exclusively devoted for a multimedia digital library section. It has 50 in-house PCs with all the A/V facilities. The library has one of the finest collections of academic reference videos and CDs comprising of national and international titles along with text book CDs at its video resource centre. The library procures latest videos from sources such as Video Education and Harvard Business Resources. The library Video Resource Centre helps enhance the inputs given to our students & makes learning a more enriching experience. Also users can surf the internet, listen and watch academic related audios & videos, access e-resources like AICTE approved subscribed Pro-Quest Management ABI/inform global-online databases which includes all area of Business & Management selectively chosen for reading & references. Subscriptions available are PRO-QUEST J-Gate, National Digital Library online resources, e- journals, e-books, previous question papers, syllabus booklets (through library website) etc. in the multimedia digital library section. Apart from this, users can also browse through the library catalogue through web OPAC (Online Public Access Catalogue). The digital library is designed and developed keeping in view the shifting IT environment and the day to day needs of modern students.</p>
                                    <h3>English Lab :</h3>
                                    <p>English Language Lab: A language lab is a place where the students at ISBS learn English Grammar. The way we have tools, equipment, chemicals, and specimens in our science labs, similarly in English language Lab, ISBS has number of tools which teach and guide the students to learn and practice language. Language lab is the software which enhances the skills of a student, which are necessary for a corporate career. Language lab teaches English and enhances ability to Listen, Speak, Read and Write. Listening and Speaking are the skills which require massive practice. In India, English is second language. It is a foreign language. We at ISBS believe the students need to learn and understand the language. Not many around us and in our society speak English. So the students do not get ample opportunities to listen and speak and practice. To improve skill in English language one must listen and speak in an order and follow the system. English Language Lab at ISBS helps in achieving 
                        the objective.</p>*/}

                                        <div className="col-md-6">
                                            <img src={Facility1} className="imgRes" />
                                        </div>
                                        <div className="col-md-6 facitextmargin">
                                            <h3>Auditorium</h3>
                                            <p>The institution houses a fully air-conditioned & well equipped Dhruv auditorium. It is an acoustically beautiful, air-conditioned concert hall used both as a theatre space and a multi- purpose facility. Dhruv Auditorium is the largest indoor performance facility at Indira Group of Institutes. It can accommodate an audience of approximately 500 seated. Dhruv has a stage, (22′ deep and 27′ wide) that is three feet higher than the house floor. The stage is equipped with a theatrical lighting inventory and a quality sound system for student address and other miscellaneous applications. Lightroom, Audio room and two changing rooms are available. Moderate space in the Lobby of Dhruv Auditorium is available for receptions.</p>
                                        </div>


                                        <div className="col-md-6 mobShow">
                                            <img src={Facility2} className="imgRes" />
                                        </div>
                                        <div className="col-md-6 facitextmargin">
                                            <h3>Basketball court</h3>
                                            <p>Catering to the needs of the sports of students the college has a basketball court as per the standards. Students are given ample support & time for practice. The college basketball team has represented the college in various intercollege, zonal, national levels.</p>
                                        </div>

                                        <div className="col-md-6 mobHide">
                                            <img src={Facility2} className="imgRes" />
                                        </div>

                                        <div className="col-md-6">
                                            <img src={Facility4} className="imgRes" />
                                        </div>
                                        <div className="col-md-6 facitextmargin">
                                            <h3>Computer Labs.</h3>
                                            <p>Computer labs are a critical resource that nearly everyone in the student body may utilize. Computer labs often include software that many students may not have on their personal computers due to cost or accessibility. The labs provide computing equipment, software and printers to support academic endeavours. The College has 7 excellent Labs for Computer Science, Mathematics and Electronics oriented study. A computer lab is spacious, fully air- conditioned and well planned consisting of more than 369 systems of which the major share is contributed by advanced machines. The lab has the latest hi-tech configuration of hardware and software. Multimedia Kits have also been set-up for students to develop their knowledge with creativity. Labs help students to brush up their computer skills and translate their logic and imagination into innovative applications. An additional Programming Lab has been provided exclusively for developing programming skills.</p>
                                        </div>



                                        <div className="col-md-6 mobShow">
                                            <img src={Facility3} className="imgRes" />
                                        </div>
                                        <div className="col-md-6 facitextmargin">
                                            <h3>Canteen</h3>
                                            <p>A large and comfortable canteen is available and opens early morning to late evening at 10 pm. Equipped with all the necessary kitchen facilities, it serves breakfast, lunch, dinner, snacks and soft drinks to students and staff. Also, the canteen is used by students for entertaining activities during their free time. It is a friendly place for students to get acquainted and meet with other students and faculty between and after classes. The canteen is also used by students for many of their parties and other social events.</p>
                                        </div>

                                        <div className="col-md-6 mobHide">
                                            <img src={Facility3} className="imgRes" />
                                        </div>

                                        <div className="col-md-6">
                                            <img src={Facility5} className="imgRes" />
                                        </div>
                                        <div className="col-md-6 facitextmargin">
                                            <h3>Gymnasium</h3>
                                            <p>Our gymnasium consists of quite sophisticated machines and weights. Like leg extension, leg curl, leg adductor, chest press, lat machine, peck deck, free weights, sitting bike, incline bench, decline bench, bicep press, horizontal row bar and treadmill. Adding to it we have a team of best trainers who train the students and faculties and are always ready to help or support them in any kind of exercise. Gym opens in early morning and facilities are available till 8 p.m.</p>
                                        </div>


                                        <div className="col-md-6 mobShow">
                                            <img src={Facility6} className="imgRes" />
                                        </div>
                                        <div className="col-md-6 facitextmargin">
                                            <h3>Hostel</h3>
                                            <p>The Hostels are managed centrally under Shree Chanakya Education Society. There are separate hostels for Boys and Girls with common canteen and Gymnasium. Each hostel is supervised by a warden who resides in the hostel. Boy’s hostel has a capacity of 150 students at Akshar hostel, 75 students at Autopoint Hostel. Girl’s hostel has a capacity of more than 300 students together at Star and Galaxy Hostel. Total hostel accommodation is available to 525 students. Medical insurance, Washing Machines, Recreation facilities like TV, Indoor Games. WiFi facility, Generator Backup etc are the facilities provided to the hostel students.</p>
                                        </div>

                                        <div className="col-md-6 mobHide">
                                            <img src={Facility6} className="imgRes" />
                                        </div>

                                        <div className="col-md-6">
                                            <img src={Facility7} className="imgRes" />
                                        </div>
                                        <div className="col-md-6 facitextmargin">
                                            <h3>Incubation Cell</h3>
                                            <p>The Incubation cell addresses the development of product/services using upcoming technologies in the Institute which is incubated by budding developers who are the students of ISBS. The Head of Incubation Cell of the Institute appraises each incubation proposal and the business plan based on pre-defined criteria. All the funding for the projects in incubation centre is taken care of by the management.</p>
                                            <p>Permitted activities in the IC include product development, product innovations, software testing, simulation and prototyping, pilot experimentation, training and similar other technology-related work.</p>
                                            <p>One of our major ongoing projects in Virtual Reality. In social sciences and psychology, virtual reality offers a cost-effective tool to study and replicate interactions in a controlled environment. Such an environment can be utilized in CBSE Schools.</p>
                                            <p>VR simulates real spaces for workplace occupational safety and health purposes, educational purposes, and training purposes. It can be used to provide learners with a virtual environment where they can develop their skills without the real-world consequences of failing.</p>

                                        </div>


                                        <div className="col-md-6 mobShow">
                                            <img src={Facility8} className="imgRes" />
                                        </div>
                                        <div className="col-md-6 facitextmargin">
                                            <h3>Library</h3>
                                            <p>Library of ISBS is equipped to meet the essential academic & intellectual needs of the students in various diverse fields. Online access system, e-journals, updated books, novels, a spacious reading room provides an atmosphere conducive to study. The library stock consists of more than 21527 books which are regularly updated with changes in curriculum. The library also subscription of all leading newspapers, journals- national & international.</p>
                                        </div>

                                        <div className="col-md-6  mobHide">
                                            <img src={Facility8} className="imgRes" />
                                        </div>

                                        <div className="col-md-6">
                                            <img src={Facility9} className="imgRes" />
                                        </div>
                                        <div className="col-md-6 facitextmargin">
                                            <h3>Parking</h3>
                                            <p>ISBS has separate Car and Bike parking space for the teachers and students. The entire ground floor is reversed for the parking.</p>
                                        </div>


                                        <div className="col-md-6  mobShow">
                                            <img src={Facility10} className="imgRes" />
                                        </div>
                                        <div className="col-md-6 facitextmargin">
                                            <h3>Wi-Fi Enabled Campus</h3>
                                            <p>Internet connectivity is made available to students’ laptops via WIFI throughout the campus including the hostels within the campus. The student’s laptop needs to be equipped with licensed Operating System & Antivirus software’s to avail this facility at no cost. Also, the students have to register their laptop with the institute’s Server Room and sign the IT access policy of INDIRA to confirm that they follow the rules and regulations stated in the policy while accessing the data.</p>
                                        </div>

                                        <div className="col-md-6  mobHide">
                                            <img src={Facility10} className="imgRes" />
                                        </div>

                                        <div className="col-md-6">
                                            <img src={Facility11} className="imgRes" />
                                        </div>
                                        <div className="col-md-6 facitextmargin">
                                            <h3>Multi-Purpose Sports Hall</h3>
                                            <p>The college sports room has facilities like table tennis, chess, carom etc & area for recreational activities. Students are motivated to participate in these indoor sports activities & also represent the college in various intercollegiate sports events.</p>
                                        </div>


                                        <hr />
                                        <div className="col-md-12" style={{ marginTop: "2em" }}>
                                            <div className="row">
                                                <div className="col-md-3"><img src={faci1} className="imgRes facilityBM20" /></div>
                                                <div className="col-md-3"><img src={faci2} className="imgRes facilityBM20" /></div>
                                                <div className="col-md-3"><img src={faci4} className="imgRes facilityBM20" /></div>
                                                <div className="col-md-3"><img src={faci10} className="imgRes facilityBM20" /></div>


                                                <div className="col-md-3"><img src={faci6} className="imgRes facilityBM20" /></div>
                                                <div className="col-md-3"><img src={faci7} className="imgRes facilityBM20" /></div>
                                                <div className="col-md-3"><img src={faci8} className="imgRes facilityBM20" /></div>
                                                <div className="col-md-3"><img src={faci9} className="imgRes facilityBM20" /></div>


                                                <div className="col-md-3"><img src={faci5} className="imgRes facilityBM20" /></div>
                                                <div className="col-md-3"><img src={faci11} className="imgRes facilityBM20" /></div>
                                                <div className="col-md-3"><img src={faci13} className="imgRes facilityBM20" /></div>
                                                <div className="col-md-3"><img src={faci14} className="imgRes facilityBM20" /></div>

                                                <div className="col-md-3"><img src={faci16} className="imgRes facilityBM20" /></div>
                                                <div className="col-md-3"><img src={faci17} className="imgRes facilityBM20" /></div>
                                                <div className="col-md-3"><img src={faci19} className="imgRes facilityBM20" /></div>
                                                <div className="col-md-3"><img src={faci8} className="imgRes facilityBM20" /></div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        }
                        {Staff &&
                            <div className="whiteBackground paddingBottom3em ">
                                <div className="container">
                                    <div className="row staffMba">
                                        <p className="paddingTop1em">At ISBS we have built a bank of high caliber Practicing Industry managers, Subject Matter Experts across key disciplines and
                                            emerging areas. Cutting across disciplines and different Industry sectors, these practicing managers, academicians and
                                            consultants together, provide with depth and breadth of expertise into our workshops.</p>
                                        <div className="col-md-6 text-center">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img1Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor text-center"><b>Dr. Komal Singh</b></p>
                                                <p><b>Director</b><br />
                                                    <b>Qualification:Ph.D, NET, M.Com</b><br />
                                                    {/*<b>Industrial Exp: 0 years</b><br />*/}
                                                    <b>Academic Exp: 20 years</b><br />
                                                    <b>Research Papers: 11</b><br />
                                                    <b>Books Written:1</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img2Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr. Manmohan Vyas</b></p>
                                                <p><b>Deputy Director- ISBS // Financial Analyst // Consultant</b><br />
                                                    <b>Qualification:PhD. MBA- Finance, MBA - HRM,B.Com.</b><br />
                                                    <b>Industrial Exp: 1 years</b><br />
                                                    <b>Academic Exp: 11 years</b><br />
                                                    <b>Research Papers: 8</b><br />
                                                    {/*<b>Books Written:0</b>*/}
                                                    <p><b>Awards:Academic Jewel 2010, Dewang Mehta B-School Best
                                                        Faculty in FM,2017</b></p>
                                                </p>
                                            </div>
                                        </div>
                                        {/*<div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img3Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr. Tausif Mistry</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification:Ph.D, MBA, B.Pharm, NET</b><br />
                                                    <b>Industrial Exp: 4 years</b><br />
                                                    <b>Academic Exp: 5.0</b><br />
                                                    <b>Research Papers: 12</b><br />
                                                    <b>Books Written:0</b>
                                                </p>
                                            </div>
                                        </div>*/}
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img4Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr. Kalpana K. Deshmukh</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification:Ph.D, MBA, BSc</b><br />
                                                    <b>Industrial Exp: 1 years</b><br />
                                                    <b>Academic Exp: 10 years</b><br />
                                                    <b>Research Papers: 14</b><br />
                                                    <b>Books Written:1</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img5Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr. Abhijit P Bobde </b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: PhD. MBA (Marketing), B. Pharm.</b><br />
                                                    <b>Industrial Exp: 17 years</b><br />
                                                    <b>Academic Exp: 4 years</b><br />
                                                    <b>Research Papers: 8 Papers (2 Scopus, 4 UGC)</b><br />

                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img6Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr. Mrityunjay Kumar</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: PhD, M.Phil, MBA, LLB, B.Sc</b><br />
                                                    <b>Industrial Exp: 2 years</b><br />
                                                    <b>Academic Exp: 8 years</b><br />
                                                    <b>Research Papers: 5</b><br />
                                                    <b>Books Written: 1</b>
                                                </p>
                                            </div>
                                        </div>
                                        {/*<div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img7Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Prof. Tanay Kurode</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification:C.F.P, M.B.A, B.B.A</b><br />
                                                    <b>Industrial Exp: 4 years</b><br />
                                                    <b>Academic Exp: 4.0</b><br />
                                                    <b>Research Papers: 6</b><br />
                                                    <b>Books Written: 0</b>
                                                </p>
                                            </div>
                                        </div>*/}
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img8Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b> Dr. Amrita Karnawat</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification:PhD, MBA, BBS</b><br />
                                                    {/*<b>Industrial Exp: 0 years</b><br />*/}
                                                    <b>Academic Exp: 13 years</b><br />
                                                    <b>Research Papers: 10</b><br />
                                                    {/*<b>Books Written: 0</b>*/}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img9Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Prof. Purva Agarwal</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: Pursuing PhD in Marketing Management , Post gradute Diploma in Clinical Research ,MBA(Marketing), B.Pharm</b><br />
                                                    <b>Industrial Exp: 2 years of marketing manager in textile
                                                        industry</b><br />
                                                    <b>Academic Exp: 2 years</b><br />
                                                    <b>Research Papers: 3 </b><br />
                                                    {/*<b>Books Written: 0</b>*/}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img10Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr. Arvind Laxman Burande</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: MBA,PhD in Marketing</b><br />
                                                    {/*<b>Industrial Exp: 0</b><br />*/}
                                                    <b>Academic Exp: 12 years</b><br />
                                                    <b>Research Papers: 5</b><br />
                                                    {/*<b>Books Written: 0</b>*/}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img11Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Prof. Laxman Misal</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification : MBS, PGDBM, M.Com, B. Com</b><br />
                                                    <b>Industrial Exp: 13 years</b><br />
                                                    <b>Academic Exp: 7 years</b><br />
                                                    {/*<b>Research Papers: 0</b><br />
                                                    <b>Books Written: 0</b>*/}
                                                </p>
                                            </div>
                                        </div>
                                        {/*<div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img12Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Prof. Mangesh Sanap</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: B.E (Computer), MBA, DMEIM, Ph.D (Pursuing)</b><br />
                                                    <b>Industrial Exp:  04 years and 06 months</b><br />
                                                    <b>Academic Exp: 17 years</b><br />
                                                    <b>Research Papers: 09</b><br />
                                                    <b>Books Written: 02</b>
                                                </p>
                                            </div>
                                                </div>*/}

                                      

                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img14Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr. Trupti Joshi</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification:  PhD in Business Policy & Administration , MBA(Finance), M.Com (Accountancy), B.Com. (Accountancy)</b><br />
                                                    <b>Industrial Exp: 4 years</b><br />
                                                    <b>Academic Exp: 12 years</b><br />
                                                    <b>Research Papers: 14 Paper</b><br />
                                                    <b>Books Written: 01</b>
                                                    {/* <b>Award: Completed 2 RUSA (Rashtriya Uchchattar Shikshan Abhiyan) Funded Project </b> */}
                                                </p>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img15Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Prof. Umesh Gramopadhye</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification:BA, LLB (Gold), LLM, MPM, MBA, MPhil(Pursuing)</b><br />
                                                    {/*<b>Industrial Exp: 0 years</b><br />
                                                    <b>Academic Exp: 12 years</b><br />
                                                    <b>Research Papers: 6</b><br />
                                                    <b>Books Written: 02</b>
                                                </p>
                                            </div>
                                        </div> */}

                                        {/* <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img16Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr. Sumit Roy</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: PhD,MPhIL, PGDMM, MA (Psychology) MA( Sociology) MA (Philosophy), Dip ( T & D).</b><br />
                                                    <b>Industrial Exp: 15 years</b><br />
                                                    <b>Academic Exp: 14 years</b><br />
                                                    <b>Research Papers: 8 Conference  papers, 1 Scopus, 1  UGC</b><br />
                                                    <b>Books Written: 01</b><br />
                                                    <b>Award : Entrepreneurship Educator from IIM - B and Stanford Technology Venture USA</b>
                                                </p>
                                            </div>
                                        </div> */}
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img17Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr.Manisha Maddel</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: Ph.D (Computer Management), MBA(Operations), MCS(Computer Science),MCA(ComputerApplication), Diploma in Cyber Law</b><br />
                                                    {/*<b>Industrial Exp: 0 years</b><br />*/}
                                                    <b>Academic Exp: 18 years</b><br />
                                                    <b>Research Papers: 4 Conference papers , 2 UGC </b><br />
                                                    <b>Books Written: 02</b>
                                                </p>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img18Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr.Hetal Bhinde</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: Ph.D, MBA (P&MM and IBM), NET, Bsc. Biotechnology, NET</b><br />
                                                    <b>Industrial Exp:  07 years</b><br />
                                                    <b>Academic Exp: 12 years</b><br />
                                                    <b>Research Papers: 8 UGC, 1 Scopus, 22 Conference Proceedings and Peer Reviewed Journals. </b><br />
                                                    <b>Books Written: 05</b>
                                                </p>
                                            </div>
                                        </div>

                                        {/*  new added      */}
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img19Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr.Simarpreet Kaur	</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: Ph.D, MBA(HR)	</b><br />

                                                    <b>Academic Exp: 5 years</b><br />
                                                    <b>Research Papers: 2 UGC, 1 Scopus,1 peer reviewed </b><br />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img20Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr.Sushil Shende</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: Ph.D., MBA, BE	</b><br />
                                                    <b>Industrial Exp:  13 Years	</b><br />
                                                    <b>Academic Exp: 9 Years	</b><br />
                                                    <b>Research Papers: 3 </b><br />

                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img21Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr.Sanjay Asati	</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: Ph.D., MBA(Operations), MTech(Production), B.E.(Production)	</b><br />
                                                    <b>Industrial Exp:  01 year	</b><br />
                                                    <b>Academic Exp: 27 years</b><br />
                                                    <b>Research Papers: Int. Journal- 6, Conferences -11 </b><br />

                                                </p>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Img22Image} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Mrs. Ruchika Garhwal	</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: Ph.D. (Pursuing) , MMS (Marketing) , B.E.(Comp. Science)	</b><br />
                                                    <b>Industrial Exp: 2 years	</b><br />
                                                    <b>Academic Exp: 4 years</b><br />
                                                    <b>Research Papers: 3</b><br />

                                                </p>
                                            </div>
                                        </div> */}
{/* 
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={ShrirangChaudhari} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Mr. Shrirang R. Chaudhari		</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: Ph.D. (Pursuing), M.Phil (OB), MBA, B.E.(Mechanical Eng.)		</b><br />
                                                    <b>Industrial Exp: 5 Years		</b><br />
                                                    <b>Academic Exp: 1 Year	</b><br />
                                                    <b>Research Papers: 2 UGC-NHRD, 4 Conference Paper	</b><br />
                                                    <b>Books Written : Chapter Published in Encyclopedia of Social Innovation of Elgar Encyclopedias in Business and Management Series.</b>

                                                </p>
                                            </div>
                                        </div> */}



                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Anita} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Mrs. Anita Mathapati	</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: B.Com., M.C.M., MBA( Finance and IB), NET, Pursuing PhD in Financial Management	</b><br />
                                                    <b>Academic Exp: 25 years	</b><br />
                                                    <b>Research Papers: 1 UGC , conference-3	</b><br />
                                                    <b>Books Written : 2 books  (1. Principles an Practices of Management 2. Basics of Cost Accounting)</b>

                                                </p>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Asmita} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Mrs. Asmita Gaikwad	</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: Ph.D pursuing,NET Qualified,MBA(IT & Marketing,Bsc	</b><br />
                                                    <b>Industrial Exp: 01 year	</b><br />
                                                    <b>Academic Exp: 11 years 7 months 	</b><br />
                                                    <b>Research Papers: 7	</b><br />
                                                    <b>Books Written : 2 books </b>
                                                    <b>Awards : 6 th national conference Best research Paper Award at MIT</b>

                                                </p>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Mrunalinee} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Ms. Mrunalinee Khandare	</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: SET Qualifies, PGDM(Human Resource Management) & PGP(Project Engineering Management)</b><br />
                                                    <b>Industrial Exp: 3.5 years	</b><br />
                                                   
                                                </p>
                                            </div>
                                        </div>

                                        
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Vaibhavi} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Ms. Vaibhavi Shahane</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: PET Qualifies , B.COM Banking and Finance, MBA (Finance )</b><br />
                                                    <b>Industrial Exp: 1 year	</b><br />
                                                    <b>Academic Exp: 1 year	</b><br />
                                                   
                                                </p>
                                            </div>
                                        </div>
  
                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Rupesh} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Mr. Roopesh Gupta</b></p>
                                                <p><b>Assistant Professor</b><br />
                                                    <b>Qualification: BE (Electronics & Communications), MBA (Marketing & Operations)</b><br />
                                                    <b>Industrial Exp: 20 years	</b><br />
                                                    <b>Academic Exp: 0.5 year	</b><br />

                                                
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="colClickBox1 minHeight190">
                                                <div className="clickIconBOx1">
                                                    <img src={Shalaka} alt="" className="clickBoxIcon1" />
                                                </div>
                                                <p className="textOrangeColor"><b>Dr. Shalaka Ayarekar</b></p>
                                                <p><br />
                                                    <b>Qualification:  Ph.D, MCM, MBA</b><br />
                                                   
                                                    <b>Academic Exp: 16.5 year	</b><br />
                                                    <b>Research Papers: 7</b><br/>

                                                
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="minHeight335px blueBoxPatch text-center">
                                                <img src={ImgNbaImage} alt="" className="nbaCourse" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Mba;